{
    "bidTable_allow_partial_label": "Allow Partial BidTable",
    "bidTable_allow_partial_label_tooltip": "This allows Partial Bidtable",
    "bidTable_disable_partial_tooltip": "Partial Bidtables are not allowed when a project uses the Browser submission type.",
    "bidTable_label_vendormapping_add_edit_modal": "Vendor Mapping",
    "bidTable_label_vendormapping_add_edit_modal_already_mapped": "Already Mapped Vendors",
    "bidTable_label_vendormapping_add_edit_modal_search_results": "Search Results",
    "bidTable_label_vendors": "Vendors",
    "bidTable_label_project_meta_invalid_vendor_warning": "Invalid Vendor Mapping",
    "bidTable_phrase_project_meta_invalid_vendor_warning": "You have mapped the following %{x} vendor(s) to bidTable items whom have not been invited to the project yet. Please invite them before opening project.",
    "js_notice_complete": "Complete",
    "js_notice_file_size": "File size",
    "js_notice_loading": "Loading",
    "js_notice_pending": "Pending",
    "js_notice_time_estimate": "Time Estimate",
    "js_notice_uploading": "Uploading",
    "js_keyword_show": "Show",
    "js_keyword_hide": "Hide",
    "js_phrase_expires": "Expires",
    "js_phrase_expired": "Expired",
    "js_emptyTable": "No data available in table",
    "js_info": "Showing _START_ to _END_ of _TOTAL_ entries",
    "js_infoEmpty": "No entries to show",
    "js_infoFiltered": "- Filtering from _MAX_ records",
    "js_infoPostFix": "",
    "js_thousands": ",",
    "js_lengthMenu": "Display _MENU_ records",
    "js_loadingRecords": "Still loading, please wait...",
    "js_processing": "Processing",
    "js_search": "",
    "js_searchPlaceholder": "Search",
    "js_zeroRecords": "No records to display",
    "js_first": "First Page",
    "js_last": "Last Page",
    "js_next": "Next Page",
    "js_previous": "Previous Page",
    "js_sortAscending": "- Click/return to sort ascending",
    "js_sortDescending": "- Click/return to sort descending",
    "js_keyword_all": "All",
    "js_label_show_hide_columns": "Show/Hide Columns Panel",
    "js_keyword_criteria_cap": "Criteria",
    "js_label_sets": "Sets",
    "js_label_note": "Note",
    "js_label_notes": "Notes",
    "js_phrase_no_questions_to_display": "No questions to display",
    "js_phrase_showing_x_questions": "Displaying _TOTAL_ questions",
    "js_phrase_filtering_from_max_questions": "- Filtering from _MAX_ questions",
    "js_phrase_no_questions_match_search_criteria": "No questions match the search criteria",
    "js_error_you_have_attempted_to_queue_too_many_files": "You have attempted to queue too many files",
    "js_error_you_may_select": "You may select",
    "js_error_files": "Files",
    "js_error_file_is_larger_than_maximum_size": "File is larger than maximum size",
    "js_error_you_have_selected_too_many_files": "You have selected too many files",
    "js_error_more_files": "more files",
    "js_error_unhandled_error": "Unhandled Error",
    "js_min_high_spd_to": "min (high spd) to",
    "js_min_low_spd": "min (low spd)",
    "js_error_upload_error": "Upload Error",
    "js_error_configuration_error": "Configuration Error",
    "js_error_upload_failed": "Upload Failed",
    "js_error_server_io_error": "Server (IO) Error",
    "js_error_security_error": "Security Error",
    "js_error_upload_limit_exceeded": "Upload limit exceeded",
    "js_error_file_not_found": "ERROR: File not found",
    "js_error_failed_validation_upload_skipped": "Failed Validation. Upload skipped",
    "js_error_cancelled": "Cancelled",
    "js_error_stopped": "Stopped",
    "js_error_cannot_upload_a_zero_byte_files": "Cannot upload a zero byte files",
    "js_error_invalid_file_type": "Invalid File Type",
    "js_error_one_file": "one file",
    "js_error_there_was_a_problem_saving_your_document": "An email has been sent to the support team (Support@GoBonfire.com) to notify them of this issue, they will be in contact with you shortly",
    "js_error_up_to": "up to",
    "js_error_you_cannot_add_any_more_files": "You cannot add any more files",
    "js_error_you_have_reached_the_upload_limit": "You have reached the upload limit",
    "js_error_you_may_only_add": "You may only add",
    "js_alt_document_page": "Document page",
    "js_keyword_actions": "Actions",
    "js_action_read_more": "Read more",
    "js_label_done": "DONE",
    "js_label_open": "Open",
    "js_label_open_another_document": "Open another document",
    "js_label_completed": "Completed",
    "js_label_evaluating": "Evaluating",
    "js_label_pending": "Pending",
    "js_label_active": "Active",
    "js_label_expired": "Expired",
    "js_label_project_opportunity": "Project",
    "js_label_project_template": "Template",
    "js_label_project_draft": "Draft",
    "js_label_terminated": "Terminated",
    "js_label_success": "Success",
    "js_label_failed": "Failed",
    "js_label_reopened": "Reopened",
    "js_cal_apr": "Apr",
    "js_cal_april": "April",
    "js_cal_aug": "Aug",
    "js_cal_august": "August",
    "js_cal_day": "Day",
    "js_cal_dec": "Dec",
    "js_cal_december": "December",
    "js_cal_feb": "Feb",
    "js_cal_february": "February",
    "js_cal_fr": "Fr",
    "js_cal_fri": "Fri",
    "js_cal_friday": "Friday",
    "js_cal_jan": "Jan",
    "js_cal_january": "January",
    "js_cal_jul": "Jul",
    "js_cal_july": "July",
    "js_cal_jun": "Jun",
    "js_cal_june": "June",
    "js_cal_mar": "Mar",
    "js_cal_march": "March",
    "js_cal_may": "May",
    "js_cal_mayl": "May",
    "js_cal_mo": "Mo",
    "js_cal_mon": "Mon",
    "js_cal_monday": "Monday",
    "js_cal_month": "Month",
    "js_cal_nov": "Nov",
    "js_cal_november": "November",
    "js_cal_oct": "Oct",
    "js_cal_october": "October",
    "js_cal_sa": "Sa",
    "js_cal_sat": "Sat",
    "js_cal_saturday": "Saturday",
    "js_cal_sep": "Sep",
    "js_cal_september": "September",
    "js_cal_su": "Su",
    "js_cal_sun": "Sun",
    "js_cal_sunday": "Sunday",
    "js_cal_th": "Th",
    "js_cal_thu": "Thu",
    "js_cal_thursday": "Thursday",
    "js_cal_today": "Today",
    "js_cal_tu": "Tu",
    "js_cal_tue": "Tue",
    "js_cal_tuesday": "Tuesday",
    "js_cal_we": "We",
    "js_cal_wed": "Wed",
    "js_cal_wednesday": "Wednesday",
    "js_cal_week": "Week",
    "js_date_day": "Day",
    "js_date_days": "Days",
    "js_date_hour": "Hour",
    "js_date_hours": "Hours",
    "js_date_minute": "Minute",
    "js_date_minutes": "Minutes",
    "js_date_month": "Month",
    "js_date_months": "Months",
    "js_date_second": "Second",
    "js_date_seconds": "Seconds",
    "js_date_week": "Week",
    "js_date_weeks": "Weeks",
    "js_date_year": "Year",
    "js_date_years": "Years",
    "action_accept_the_invitation": "Accept the invitation",
    "action_activate": "Activate",
    "action_add_a_document": "Add a Document",
    "action_add_advisor": "Add Advisor",
    "action_add_data": "Add Data",
    "action_add_document": "Add Document",
    "action_add_domain": "Add Domain",
    "action_add_editor": "Add Editor",
    "action_add_editors": "Add Editors",
    "action_add_note": "Add Note",
    "action_add_observer": "Add Observer",
    "action_add_observers": "Add Observers",
    "action_add_questionnaire": "Add Questionnaire",
    "action_add_reviewers": "Add Reviewers",
    "action_add_x": "Add %{x}",
    "action_add_person": "Add Person",
    "action_add_declaration": "Add Declaration",
    "action_add_change_order": "Add Change Order",
    "action_add_term": "Add Term",
    "action_add_commodity_code": "Add Commodity Code",
    "action_edit_x": "Edit %{x}",
    "action_archive": "Archive",
    "action_award": "Award",
    "action_allow_domain_match": "Allow Domain Match",
    "action_back": "Back",
    "action_back_to_contract": "Back to Contract",
    "action_back_to_project": "Back to Project",
    "action_back_to_projects": "Back to Projects",
    "action_cancel": "Cancel",
    "action_change": "Change",
    "action_click_here_to_unsubmit_your_submission": "Click here to un-submit your submission",
    "action_close": "Close",
    "action_complete_project": "Complete Project",
    "action_compliant": "Compliant",
    "action_confirm": "Confirm",
    "action_confirm_changes_and_email_suppliers": "Confirm Changes & Email Suppliers",
    "action_continue": "Continue",
    "action_continue_submission": "Continue Submission",
    "action_create": "Create",
    "action_create_a_submission": "Upload Submission",
    "action_create_account": "Create Account",
    "action_create_another": "Create another",
    "action_create_category": "Create Category",
    "action_create_category_item": "Create %s Category Item",
    "action_create_decision": "Create Decision",
    "action_create_department": "Create Department",
    "action_create_project": "Create Project",
    "action_create_requested_data_item": "Create Requested Data Item",
    "action_create_requested_document": "Create Requested Document",
    "action_create_requested_questionnaire": "Create Requested Questionnaire",
    "action_create_user": "Create User",
    "action_create_x_from_y": "Create %{x} from %{y}",
    "action_created_the_project": "%s created the project %s",
    "action_criteria": "Criteria",
    "action_delete_project": "Delete Project",
    "action_delete_this_criterion": "Delete this criterion? This can not be cancelled later or undone.",
    "action_delete_this_score": "Delete this score? This action can not be cancelled later or undone.",
    "action_deselect": "Deselect",
    "action_download_notes": "Download Notes",
    "action_download_submission_instructions": "Download Submission Instructions",
    "action_download_signed": "Download Signed",
    "action_download_unsigned": "Download Unsigned",
    "action_duplicate_x": "Duplicate %{x}",
    "action_edit": "Edit",
    "action_edit_category": "Edit Category",
    "action_edit_category_item": "Edit %s Category Item",
    "action_edit_contact_info": "Edit Contact Info",
    "action_edit_decision": "Edit Decision",
    "action_edit_project": "Edit Project",
    "action_edit_requested_data_item": "Edit Requested Data Item",
    "action_edit_requested_document": "Edit Requested Document",
    "action_edit_requested_questionnaire": "Edit Requested Questionnaire",
    "action_edit_schedule": "Edit Schedule",
    "action_eliminate": "Eliminate",
    "action_email_advisors": "Email Advisors",
    "action_email_reviewers": "Email Reviewers",
    "action_export_excel": "Export Excel",
    "action_export_pdf": "Export PDF",
    "action_export_to_pdf": "Export to PDF",
    "action_export_to_word": "Export to Word",
    "action_filter_questions": "Filter Questions",
    "action_finalize_my_submission": "SUBMIT & FINALIZE MY SUBMISSION",
    "action_finish": "Finish!",
    "action_get_private_link": "Get Private Link",
    "action_get_support": "Get Support",
    "action_go_to_primary_submission": "Go to Primary Submission",
    "action_grant_access": "Grant Access",
    "action_import_excel": "Import Excel",
    "action_invite_via_email": "Invite via Email",
    "action_it_appears_you_have_unsaved_changes": "It appears you have unsaved changes. Discard them?",
    "action_jump_to_section": "Jump to Section",
    "action_login": "Log in",
    "action_manage_advisors": "Manage Advisors",
    "action_manage_criteria": "Manage Criteria",
    "action_manage_reviewers": "Manage Reviewers",
    "action_manage_the_project": "Manage the project",
    "action_merge": "Merge",
    "action_modify_your_submission": "Modify your Submission",
    "action_move_to_acitve": "Move to Active",
    "action_next": "Next",
    "action_no": "No",
    "action_notes": "Notes",
    "action_notify_suppliers": "Notify Suppliers",
    "action_notify_suppliers_via_public_notice": "Notify Suppliers via Public Notices",
    "action_open": "Open",
    "action_open_document": "Open Document",
    "action_prepare_a_new_vendor": "Prepare a New Vendor",
    "action_prepare_an_alternate_option": "Prepare an Alternate Option",
    "action_prev": "Prev",
    "action_preview_on_portal": "Preview on Portal",
    "action_reactivate": "Reactivate",
    "action_read_more": "Read more",
    "action_refresh": "Refresh",
    "action_release": "Release",
    "action_release_to_reviewers": "Release to Reviewers",
    "action_remove": "Remove",
    "action_remove_this_advisor_from_the_project": "Remove this advisor from the project?",
    "action_remove_this_editor_from_the_project": "Remove this editor from the project?",
    "action_remove_this_observer_from_the_project": "Remove this observer from the project?",
    "action_renew": "Renew",
    "action_request_support": "Request Support",
    "action_requested_info": "Requested Information",
    "action_resend": "Resend",
    "action_resend_invite": "Resend Invite",
    "action_return_to_portal_listing": "Return to Portal Listing",
    "action_return_to_project": "Return to Project",
    "action_save": "Save",
    "action_save_and_close": "Save & Close",
    "action_save_score": "Save Score",
    "action_score": "Score",
    "action_score_order_list": "Score Order List",
    "action_score_questions": "Score Questions",
    "action_scorecard": "Scorecard",
    "action_scored": "%s scored %s",
    "action_scored_on": "%s scored %s on %s",
    "action_select": "Select",
    "action_send_email": "Send Email",
    "action_show": "Show",
    "action_show_all_details": "Show All Details",
    "action_submission_instructions": "Submission Instructions",
    "action_submission_withdrawn": "Submission Withdrawn",
    "action_submitted_for": "%s submitted for %s",
    "action_terminate": "Terminate",
    "action_thumbnails": "Thumbnails",
    "action_unarchive": "Unarchive",
    "action_unaward": "Un-Award",
    "action_update_project": "Update Project",
    "action_upload": "Upload",
    "action_upload_file": "Upload File",
    "action_view": "View",
    "action_view_active": "View Active",
    "action_view_contract": "View Contract",
    "action_view_contracts": "View Contracts",
    "action_view_eliminated": "View Eliminated",
    "action_view_notes": "View Notes",
    "action_view_opportunity": "View Opportunity",
    "action_view_signed": "View Signed Document",
    "action_view_projects": "View Projects",
    "action_view_receipt": "View Receipt",
    "action_view_survey": "View the survey",
    "action_view_the_project": "View the project",
    "action_view_the_submissions": "View the submissions",
    "action_withhold": "Withhold",
    "action_wrote_a_note_on": "%s wrote a note on %s",
    "action_wrote_a_note_on_for": "%s wrote a note on %s for %s",
    "action_see_new_view": "See new view",
    "action_yes": "Yes",
    "actions_next": "Next",
    "alert_please_match_all_columns_in_your_imported_data": "Please match all columns in your imported data",
    "alert_resource_added": "%{resource} added!",
    "alert_resource_name_added": "%{resource} %{name} added!",
    "alt_awarded": "Awarded",
    "alt_checkmark": "Checkmark",
    "alt_delete": "Delete",
    "alt_download": "Download",
    "alt_drag_handle": "Drag handle",
    "alt_dropdown_arrow": "Dropdown menu arrow",
    "alt_organization_logo": "Organization logo",
    "alt_powered_by": "Powered by Bonfire",
    "alt_profile_pic": "Profile Picture",
    "alt_refresh": "Refresh",
    "alt_reviewers": "Reviewers",
    "alt_schedule": "Schedule",
    "alt_submissions": "Submissions",
    "alt_upload": "Upload",
    "alt_warning": "Warning!",
    "aoda_s_image": "%s image",
    "cal_apr": "Apr",
    "cal_april": "April",
    "cal_aug": "Aug",
    "cal_august": "August",
    "cal_day": "Day",
    "cal_dec": "Dec",
    "cal_december": "December",
    "cal_feb": "Feb",
    "cal_february": "February",
    "cal_fr": "Fr",
    "cal_fri": "Fri",
    "cal_friday": "Friday",
    "cal_jan": "Jan",
    "cal_january": "January",
    "cal_jul": "Jul",
    "cal_july": "July",
    "cal_jun": "Jun",
    "cal_june": "June",
    "cal_mar": "Mar",
    "cal_march": "March",
    "cal_may": "May",
    "cal_mayl": "May",
    "cal_mo": "Mo",
    "cal_mon": "Mon",
    "cal_monday": "Monday",
    "cal_month": "Month",
    "cal_nov": "Nov",
    "cal_november": "November",
    "cal_oct": "Oct",
    "cal_october": "October",
    "cal_sa": "Sa",
    "cal_sat": "Sat",
    "cal_saturday": "Saturday",
    "cal_sep": "Sep",
    "cal_september": "September",
    "cal_su": "Su",
    "cal_sun": "Sun",
    "cal_sunday": "Sunday",
    "cal_th": "Th",
    "cal_thu": "Thu",
    "cal_thursday": "Thursday",
    "cal_today": "Today",
    "cal_tu": "Tu",
    "cal_tue": "Tue",
    "cal_tuesday": "Tuesday",
    "cal_we": "We",
    "cal_wed": "Wed",
    "cal_wednesday": "Wednesday",
    "cal_week": "Week",
    "confirm_are_you_sure": "Are you sure?",
    "confirm_are_you_sure_you_want_to_delete_the_collection": "Are you sure you want to delete the %{collection}?",
    "confirm_are_you_sure_you_want_to_delete_this_resource": "Are you sure you want to delete this %{resource}?",
    "confirm_delete_opportunity_nda": "This will delete the Opportunity NDA including all signatures, are you sure you want to do this?",
    "confirm_delete_project_coi": "This will delete the Project COI including all signatures and overrides, are you sure you want to do this?",
    "confirm_delete_project_nda": "This will delete the Project NDA including all signatures, are you sure you want to do this?",
    "date_day": "Day",
    "date_days": "Days",
    "date_hour": "Hour",
    "date_hours": "Hours",
    "date_minute": "Minute",
    "date_minutes": "Minutes",
    "date_month": "Month",
    "date_months": "Months",
    "date_second": "Second",
    "date_seconds": "Seconds",
    "date_week": "Week",
    "date_weeks": "Weeks",
    "date_year": "Year",
    "date_years": "Years",
    "db_string_key_contact_type_contract": "Contract",
    "db_string_key_contract_document_type_amendment": "Amendment",
    "db_string_key_contract_document_type_contract": "Contract",
    "db_string_key_contract_document_type_other": "Other",
    "db_string_key_contract_document_type_supporting_documentation": "Supporting Documentation",
    "db_string_key_contract_status_active": "Active",
    "db_string_key_contract_status_expired": "Expired",
    "db_string_key_contract_status_pending": "Pending",
    "db_string_key_contract_status_terminated": "Terminated",
    "db_string_key_project_categories_changed": "Project Categories changed",
    "db_string_key_project_category_items_changed": "Project Category Items changed",
    "db_string_key_project_close_date_changed": "Project Close Date changed",
    "db_string_key_project_decisions_changed": "Project Decisions changed",
    "db_string_key_project_has_changed": "Project has changed",
    "db_string_key_project_open_date_changed": "Project Open Date changed",
    "db_string_key_project_public_files_changed": "Project Public Files changed",
    "db_string_key_project_requested_information_changed": "Project Requested Information changed",
    "db_string_key_project_intent_to_bid_due_date_changed": "Project Intent to Bid Due Date Changed",
    "db_string_key_project_questions_due_date_changed": "Project Questions Due Date Changed",
    "db_string_key_project_visibilty_invite_only": "Invite Only",
    "db_string_key_project_visibilty_private": "Private",
    "db_string_key_project_visibilty_public": "Public",
    "db_string_key_public_project_document_type_addenda": "Addenda",
    "db_string_key_public_project_document_type_documentation": "Documentation",
    "db_string_key_public_project_document_type_other": "Other",
    "db_string_key_reminder_type_contract": "Contract",
    "error_404_page_not_found": "404: Page Not Found",
    "error_additional_for_no_bid_primary_basket_s_item_s": "You created an additional response for a primary response with a No Bid decision (Basket #%s, Item #%s).",
    "error_additional_for_nonexistent_primary_basket_s_item_s": "You created an additional response for a primary response that was not filled out (Basket #%s, Item #%s).",
    "error_already_creating_new_project": "We're sorry. It appears you are already creating a new project. You may only create one project at a time. Please contact Support@GoBonfire.com for assistance.",
    "error_an_extension_prevented_the_file_upload_to_the_server": "An extension prevented the file upload to the server.",
    "error_at_least_one_department_must_be_specified": "At least one department must be specified.",
    "error_basket_s_doesnt_exist": "You created an additional response for a primary response with Basket #%s which does not exist.",
    "error_basket_s_item_s_doesnt_exist": "You created an additional response for a primary response with Basket #%s, Item #%s which does not exist.",
    "error_cannot_merge_external_vendors": "Cannot merge external vendors.",
    "error_cannot_remove_requested_info_because_there_are_submission_associated_with_it": "You cannot remove this item of Requested Information as there are submissions associated with it.",
    "error_column_s_cannot_be_empty": "Column '%s' can not be empty (%s).",
    "error_column_s_cannot_be_empty_additional": "Column '%s' can not be empty (additional sheet - %s).",
    "error_contact_technical_support_here": "Contact technical support here",
    "error_criteria_import_failed": "There was an error importing the criteria. Please contact Support@GoBonfire.com.",
    "error_criterion_at_row_contains_error": "The criterion or criteria group at row %s contains an error. %s",
    "error_deletion_failed": "Deletion failed",
    "error_department_does_not_exists_in_your_organization": "Department does not exist in your Organization.",
    "error_email_address_changes_are_not_allowed_because_this_organization_uses_single_sign_on": "Email address changes are not allowed because this organization uses Single Sign-On",
    "error_emails_dont_match": "Emails do not match.",
    "error_email_not_permitted_to_be_requester": "The email address used is not permitted to be a requester. Please reach out to your procurement team",
    "error_field_can_only_contain_letters_numbers_spaces": "%{field} can only contain letters, numbers and spaces.",
    "error_field_cannot_be_empty": "%{field} cannot be empty.",
    "error_field_must_be_a_number": "%{field} must be a number",
    "error_field_must_be_a_properly_formatted_date": "%{field} must be a properly formatted date",
    "error_field_must_be_an_integer_gte_zero": "%{field} must be an integer >= 0",
    "error_field_must_be_numeric": "%{field} must be numeric",
    "error_file_does_not_exist": "Error. File does not exist",
    "error_file_not_found": "ERROR: File not found",
    "error_forbidden": "Forbidden",
    "error_if_no_bid_is_selected_then_all_other_fields_must_be_empty_s": "If No Bid is selected, then all other fields must be empty (%s).",
    "error_if_you_believe_this_message_is_in_error_contact_us_at": "If you believe this message is in error, contact us at <a href='mailto:support@gobonfire.com'>Support@GoBonfire.com</a>.",
    "error_insertion_failed": "Insertion failed",
    "error_internal_server_error": "Internal Server Error. Please try again or contact Support@GoBonfire.com.",
    "error_invalid_data": "Invalid Data",
    "error_invalid_evaluation_group_ordering": "Invalid evaluation group ordering",
    "error_invalid_field": "Invalid %{field}",
    "error_invalid_file_type": "File type is not allowed, please click <a href='%{url}'> here </a> for a list of compatible file types",
    "error_invalid_formula": "Invalid formula",
    "error_invalid_input": "Error - Invalid Input",
    "error_invalid_option": "Invalid option",
    "error_invalid_organizationcontracttypeid_for_contract_name": "Invalid OrganizationContractTypeID for contract %{name}. No contracts created.",
    "error_invalid_project": "Invalid Project",
    "error_invalid_template": "The uploaded file does not match the template required for this %s. Please use the proper template and try again.",
    "error_invalid_value_for_organization_key": "Invalid value for Organization key %{key}",
    "error_invitation_does_not_exists_in_your": "Invitation does not exists in your",
    "error_invitation_does_not_exists_in_your_organization": "Invitation does not exists in your Organization",
    "error_keywordBidTable_import_failed": "There was an error importing the %{keywordBidTable}. Please contact support@GoBonfire.com.",
    "error_must_be_less_than_num_characters": "Must be less than %{number} characters",
    "error_need_help": "Need help?",
    "error_no_file_was_specified_for_upload": "No file was specified for upload.",
    "error_only_letters_numbers_spaces_are_permitted": "Only letters, numbers and spaces are permitted.",
    "error_oops_enter_an_email_address_and_password": "Oops - Enter an email address and password.",
    "error_oops_invalid_password_email": "Invalid Password or Email",
    "error_password_must_be_72_characters_or_less": "Password must be 72 characters or less.",
    "error_please_add_some_data": "Please add some data",
    "error_please_choose_a_evaluation_group_for_each_criteria": "Please choose a evaluation group for each criteria",
    "error_please_enter_a_valid_email_address": "Please enter a valid email address.",
    "error_please_enter_an_organization_name": "Please enter an Organization Name.",
    "error_please_enter_at_least_one_new_date_to_change_the_schedule": "Please enter at least one new date to change the schedule.",
    "error_please_enter_valid_number": "Please enter a valid number, with only digits and decimals.",
    "error_please_enter_value": "Please enter a value.",
    "error_please_fill_in_both_your_names": "Please fill in both your names.",
    "error_please_provide_a_name_for_each_evaluation_group": "Please provide a name for each evaluation group.",
    "error_please_provide_a_project_type_for_the_project": "Please provide a project type for the project.",
    "error_please_provide_a_reason_for_this_amendment": "Please provide a reason for this amendment.",
    "error_please_provide_a_reference_number_for_the_project": "Please provide a reference number for the project.",
    "error_please_provide_a_title_for_the_project": "Please provide a title for the project.",
    "error_please_provide_a_name_for_the_project": "Please provide a name for the project.",
    "error_please_select_at_least_1_decision": "Please select at least 1 decision.",
    "error_please_select_true_or_false": "Please select either True or False.",
    "error_please_select_yes_or_no": "Please select either Yes or No.",
    "error_powered_by": "Powered by",
    "error_questionnaire_import_failed": "There was an error importing the questionnaire. Please contact support@GoBonfire.com.",
    "error_reading_excel_file": "Error reading Excel file.",
    "error_response_for_question_x_contains_an_error_and_extra_info": "The response for Question %s contains an error. %s",
    "error_response_s_has_invalid_id": "Additional response on row %s has an incorrectly formatted #.",
    "error_response_s_is_missing_id": "Additional response on row %s is missing its #.",
    "error_return_to_the_main_page": "Return to the main page",
    "error_s_must_be_an_integer_ge_zero": "%s must be an integer >= 0",
    "error_s_must_be_ge_zero": "%s must be >= 0",
    "error_s_must_be_great_than_zero": "%s must be greater than zero.",
    "error_s_must_be_less_than_s": "%s must be less than %s.",
    "error_s_must_be_numeric": "%s must be numeric.",
    "error_server_error": "Server Error",
    "error_something_went_wrong": "Error - Something went wrong",
    "error_something_went_wrong_file_not_deleted": "Something went wrong - file not deleted",
    "error_something_went_wrong_please_relogin_and_try_again_if_the_problem_persists_contact_us_at": "Something went wrong - please re-login and try again. If the problem persists, contact us at support@gobonfire.com",
    "error_sorry_bonfire_does_not_recognize_that_email": "Sorry, Bonfire does not recognize that email",
    "error_sorry_the_documents_are_not_ready_to_be_viewed_for_this_project": "Sorry, the documents are not ready to be viewed for this project. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_export_excel_scoring_summary": "Sorry, you don't have permission to export the scoring summary in Excel format.",
    "error_sorry_you_dont_have_permission_to_export_executive_summary": "Sorry, you don't have permission to export the executive summary for this project. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_perform_this_action": "Sorry, you don't have permission to perform this action. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_view_notes_for_this_project_at_this_time": "Sorry, you don't have permission to view notes for this project at this time. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_view_proposals_from_this_project": "Sorry, you don't have permission to view proposals from this project. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_view_proposals_from_this_project_at_this_time": "Sorry, you don't have permission to view proposals from this project at this time. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_view_scores_for_this_project": "Sorry, you don't have permission to view scores for this project. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_view_scores_for_this_project_at_this_time": "Sorry, you don't have permission to view scores for this project at this time. You've been logged out as a security measure.",
    "error_sorry_you_dont_have_permission_to_view_this_area_youve_been_logged_out_as_a_security_measure": "Sorry, you dont have permission to view this area. You've been logged out as a security measure",
    "error_sorry_you_dont_have_permission_to_view_this_project": "Sorry, you don't have permission to view this project. You've been logged out as a security measure.",
    "error_submission_already_finalized": "The submission has already been finalized. Please refresh the page.",
    "error_success_your_password_has_been_changed": "Success! Your password has been changed.",
    "error_that_field_name_already_exists": "That field name already exists",
    "error_that_invite_has_been_filled_please_login": "That invite has been filled, please log in.",
    "error_the_auto_select_action_cannot_be_performed": "Warning. The auto-select action cannot be performed on the checked items. The following vendors have equivalent values for item %{itemNumber}: %{vendors}. Please resolve this issue and try again.",
    "error_the_auto_select_action_cannot_be_performed_optimal_combination": "Warning. The auto-select action cannot be performed on the checked items. The following sets of vendors all have a total of %{optimalTotal}: %{vendors}. Please resolve this issue and try again.",
    "error_the_data_passed_for_the_documents_youre_trying_to_manage_is_corrupt_youve_been_logged_out_as_a_security_measure": "The data passed for the documents you're trying to manage is corrupt. You've been logged out as a security measure",
    "error_the_field_name_cannot_be_blank": "The field name cannot be blank",
    "error_the_file_couldnt_be_uploaded_due_to_a_missing_temporary_folder": "The file couldn't be uploaded due to a missing temporary folder.",
    "error_the_file_couldnt_be_written_to_the_server": "The file couldn't be written to the server.",
    "error_the_file_only_partially_uploaded": "The file only partially uploaded.",
    "error_the_files_file_size_exceeds_the_maximum_file_size_set_on_the_server": "The file's file size exceeds the maximum file size set on the server.",
    "error_the_files_filesize_exceeds_the_maximum_file_size_set_for_the_upload_form": "The file's filesize exceeds the maximum file size set for the upload form.",
    "error_the_invitation_has_expired_weve_sent_an_email": "The invitation has expired. We've sent an email to the project owner requesting they renew it.",
    "error_the_invitation_link_appears_to_be_malformed": "The Invitation Link appears to be malformed. Please try copying and pasting the link from the email. Contact us at <a href='mailto:Support@GoBonfire.com'>Support@GoBonfire.com</a> if the problem persists.",
    "error_the_project_is_no_longer_open_for_submissions": "Submissions for this project are now closed. Your submission must be uploaded, submitted, and finalized prior to the Closing Time.",
    "error_the_property_cannot_be_edited_at_this_time": "ERROR: The property cannot be edited at this time",
    "error_the_s_must_be_unique_the_s_is_already_in_use": "The %s must be unique. This %s is already in use.",
    "error_the_supplied_emails_do_not_match": "The supplied emails do not match.",
    "error_the_supplied_passwords_do_not_match": "The supplied passwords do not match.",
    "error_the_supplied_passwords_dont_match": "The supplied passwords do not match - please try again.",
    "error_the_uploaded_file_type_is_not_supported_error_code_p202": "The uploaded file type is not supported. Error code P202.",
    "error_the_user_is_posting_data_that_doesnt_match_our_database_records": "The user is posting data that doesn't match our database records.",
    "error_there_are_still_resources_associated_with_this_resource": "There are still resources associated with this %{resource}.",
    "error_there_is_no_optimal_combination_of_vendors": "There is no optimal combination of vendors for the items you have selected. Please update your selection and try again.",
    "error_there_must_be_at_least_two_valid_options": "There must be at least two valid options.",
    "error_there_was_a_problem_with_your_dates_please_refresh_the_page_and_try_again": "There was a problem with your dates - please refresh the page and try again",
    "error_there_was_an_error_adding_this_reviewer": "There was an error adding this reviewer, please contact support.",
    "error_there_was_an_error_connecting_to_the_database_when_submitting_your_comment_please_contact_bonfire_support_at": "There was an error connecting to the database when submitting your comment, please contact Bonfire Support at Support@GoBonfire.com.",
    "error_there_was_an_error_connecting_to_the_database_when_submitting_your_comment_please_contact_your_system_administrator": "There was an error connecting to the database when submitting your comment, please contact your system administrator",
    "error_there_was_an_error_on_the_page_you_requested": "There was an error on the page you requested",
    "error_there_was_an_error_processing_your_password": "There was an error processing your password. Contact Bonfire Support immediately at Support@GoBonfire.com",
    "error_there_was_an_error_processing_your_password_contact_bonfire_support": "There was an error processing your password contact bonfire support at support@gobonfire.com",
    "error_there_was_an_error_the_data_you_would_like_to_change": "There was an error with the data you would like to change. Please refresh the page and try again. If the problem persists, contact support@gobonfire.com.",
    "error_there_was_an_error_trying_to_complete_this_action": "There was an error trying to complete this action",
    "error_there_was_an_error_with_the_data": "There was an error with the data. Please refresh the page and try again. If the problem persists, contact Support@GoBonfire.com.",
    "error_this_account_has_already_been_confirmed": "This account has already been confirmed.",
    "error_this_account_has_been_disabled": "This account has been disabled. Check out our support article on <a href='https://support.gobonfire.com/hc/en-us/articles/360009183194'>'What do I do if my account is disabled?'</a>",
    "error_this_criteria_cannot_have_a_unit_type_of": "This criteria cannot have a unit type of %s",
    "error_this_field_is_required": "This field is required",
    "error_this_field_must_be_a_number_gte_zero": "This field must be a number >= 0.",
    "error_this_field_must_be_an_integer_gte_zero": "This field must be an integer >= 0",
    "error_this_project_cannot_be_edited_at_this_time": "ERROR: This project cannot be edited at this time",
    "error_too_many_combinations_to_calculate": "Too many combinations to calculate...",
    "error_too_many_criteria": "Error. Too many criteria. You may only create up to %s criteria for a project.",
    "error_trying_to_save_vendor_details": "Error trying to save vendor details",
    "error_unauthorized": "Unauthorized",
    "error_uploading_file_refresh_and_try_again": "There was an error uploading your file. Please refresh the page and try again.",
    "error_value_is_not_a_valid_field_name": "%{value} is not a valid field name",
    "error_with_data_please_refresh_and_try_again": "There was an error with the data provided. Please refresh the page and try again.",
    "error_x_at_row_y_in_set_z_contains_error": "The %s at Row %s in Question Set %s contains an error. %s",
    "error_x_must_be_less_than_x_characters": "%s must be less than %s characters",
    "error_you_are_about_to_overwrite_a_file_are_you_sure_you_want_to_do_this": "You are about to overwrite a file, are you sure you want to do this?",
    "error_you_attempted_to_upload_a_prohibited_file_type": "ERROR: You attempted to upload a prohibited file type",
    "error_you_can_only_use_emails_from_these_domains": "You can only use emails from these domains",
    "error_you_cannot_add_criteria_once_a_project_has_been_released": "You cannot add Criteria once a Project has been released. Please contact support@gobonfire.com for assistance.",
    "error_you_cannot_create_more_than_x_x": "You cannot create more than %s %s",
    "error_you_cannot_delete_criteria_after_a_project_has_been_release": "You cannot delete Criteria once a Project has been released. Please contact support@gobonfire.com for assistance.",
    "error_you_cannot_manage_this_project": "ERROR: You cannot manage this project",
    "error_you_data_must_contain_exactly_numColumns_columns": "Your data must contain exactly %{numColumns} columns",
    "error_you_do_not_have_permissions_to_complete_this_action": "You do not have permissions to complete this action",
    "error_you_dont_have_permission_to_comment_on_this_project": "ERROR: You don't have permission to comment on this project",
    "error_you_dont_have_permission_to_create_other_users": "You don't have permission to create other users",
    "error_you_dont_have_permission_to_edit_the_schedule_of_this_project": "You don't have permission to edit the schedule of this project",
    "error_you_dont_have_permission_to_edit_this_project": "You don't have permission to edit this project",
    "error_you_dont_have_permission_to_export_scores": "You don't have permission to export scores.",
    "error_you_dont_have_permission_to_manage_the_criteria_of_this_project": "You don't have permission to manage the criteria of this project",
    "error_you_dont_have_permission_to_manage_the_reviewers_of_this_project": "You don't have permission to manage the reviewers of this project",
    "error_you_dont_have_permission_to_score_this_project_or_are_not_a_reviewer_on_the_project": "ERROR: You don't have permission to score this project (or are not a reviewer on the project)",
    "error_you_dont_have_permission_to_view_pending_projects_on_the_portal_youve_been_logged_out_as_a_security_measure": "You don't have permission to view pending projects on the portal. You've been logged out as a security measure.",
    "error_you_dont_have_permission_to_view_that_image": "You don't have permission to view that image",
    "error_you_dont_have_permission_to_view_that_pdf": "You don't have permission to view that pdf",
    "error_you_have_already_filled_in_data_field": "You have already filled in this data field. Please refresh the page.",
    "error_you_have_filled_in_more_than_one_value_for_the_x_data_field": "You have filled in more than 1 value for the %s data field. Please refresh the page.",
    "error_you_have_uploaded_more_than_one_file_to_an_upload_slot_that_allows_only_one_file": "You have uploaded more than 1 file to an upload slot that only allows 1 file. Please refresh the page and delete some files.",
    "error_you_must_be_logged_in_to_view_that_page": "You must be logged in to view that page.",
    "error_please_login_to_continue": "Please login to continue.",
    "error_you_must_complete_an_intent_to_bid_before_you_can_create_a_submission": "You must sign an Intent to Bid before you can create a submission",
    "error_you_must_confirm_you_want_to_alter_these_resources": "You must confirm that you want to alter these resources.",
    "error_you_must_provide_a_descriptive_scoring_comment": "You must provide a descriptive scoring comment",
    "error_your_file_must_have_an_extension": "ERROR: Your file must have an extension",
    "error_your_password_must_be_at_least_8_characters": "Your password must be at least 8 characters.",
    "orderlist_export_failed": "Bid Table export failed.",
    "footnote_weight_scaling": "* - Denotes the group weight has been overridden.",
    "heading_1_upload_a_file": "1. Upload a file",
    "heading_2_add_details": "2. Add Details",
    "heading_a_requested_files": "A) REQUESTED FILES",
    "heading_a_working_with_a_project": "A. Working with a Project",
    "heading_account_info": "Account Info",
    "heading_account_settings": "Account Settings",
    "heading_active_projects": "Active Projects",
    "heading_active_submission_data": "Active Submission Data",
    "heading_active_submissions": "Active Submissions",
    "heading_add_reviewer": "Add Reviewer",
    "heading_add_user_as_reviewer": "Add User as Reviewer",
    "heading_advisors": "Advisors",
    "heading_already_started_submission": "Already Started a Submission?",
    "heading_amended_submissions": "Amended Submissions",
    "heading_archived_projects": "Archived Projects",
    "heading_ask_a_support_question": "Ask a Support Question",
    "heading_awarded_suppliers": "Awarded Suppliers",
    "heading_b_important_notes": "B) IMPORTANT NOTES",
    "heading_b_scoring_submissions": "B. Scoring Submissions",
    "heading_back_to_home": "Back to home",
    "heading_back_to_list": "Back to list",
    "heading_belongs_to_evaluation_group": "Belongs to Evaluation Group",
    "heading_budgets": "Budgets",
    "heading_c_technical_support": "C) TECHNICAL SUPPORT",
    "heading_cant_upload_your_proposal": "Can't Upload Your Proposal",
    "heading_update_your_email": "Update Your Email",
    "heading_change_your_name": "Change Your Name",
    "heading_update_your_password": "Update Your Password",
    "heading_changes_to_requested_info": "Changes to Requested Information",
    "heading_closed_projects": "Closed Projects",
    "heading_closing_date": "Close Date",
    "heading_completed_projects": "Completed Projects",
    "heading_completed_submission": "Completed Submission",
    "heading_completed_submissions": "Completed Submissions",
    "heading_confirm_your_bonfire_account": "Confirm Your Bonfire Account",
    "heading_contact_tech_support": "Contact tech support",
    "heading_contract_id": "Contract ID",
    "heading_contract": "Contract",
    "heading_contract_details": "Contract Details",
    "heading_contract_template_details": "Contract Template Details",
    "heading_contract_documents": "Contract Documents",
    "heading_contracts": "Contracts",
    "heading_create_a_new_submission": "Create a new Submission",
    "heading_create_account": "Create Account",
    "heading_create_an_account": "Create a free account",
    "heading_create_s": "Create %s",
    "heading_create_your_account": "Create Your Account",
    "heading_current_reviewers": "Current Reviewers",
    "heading_dashboard": "Dashboard",
    "heading_data_submitted_by_x": "Data Submitted by %s:",
    "heading_date_submitted": "Date Submitted",
    "heading_days_left": "Days Left",
    "heading_departments": "Departments",
    "heading_detailed_individual_proposal_scores": "Proposal Score Comments",
    "heading_document_notes": "Document Notes",
    "heading_documents_submitted_by_x": "Documents Submitted by %s:",
    "heading_dont_forget": "Don't forget!",
    "heading_dont_have_an_account": "Don't Have an Account Yet?",
    "heading_download_instructions": "Download Instructions",
    "heading_due_date": "Due Date",
    "heading_edit_contract": "Edit Contract",
    "heading_edit_contract_template": "Edit Contract Template",
    "heading_edit_s": "Edit %s",
    "heading_eliminated_submissions": "Eliminated Submissions",
    "heading_evaluating_a_project_in_bonfire": "Evaluating a Project in Bonfire",
    "heading_advising_a_project_in_bonfire": "Advising a Project in Bonfire",
    "heading_editing_a_project_in_bonfire": "Editing a Project in Bonfire",
    "heading_observing_a_project_in_bonfire": "Observing a Project in Bonfire",
    "heading_evaluating_projects": "Evaluating Projects",
    "heading_evaluation_groups": "Manage Evaluation Groups",
    "heading_executive_summary": "Executive Summary",
    "heading_executive_summary_generated_on": "EXECUTIVE SUMMARY Generated on",
    "heading_get_help": "Get Help",
    "heading_get_help_in_the_following_ways": "Get Help in the Following Ways",
    "heading_group_name": "Group Name",
    "heading_group_number": "Group Number",
    "heading_highest_scoring_supplier": "Highest Scoring Supplier",
    "heading_individual_proposal_scores": "Proposal Scores",
    "heading_invite_new_reviewer_by_email": "Invite New Reviewer by Email",
    "heading_invite_vendors": "Invite Vendors",
    "search_vendors": "Search for Vendors",
    "invite_via_email_or_vendor_list": "Invite via Email or Vendor List",
    "invite_vendors_options": "Invite Vendor Options",
    "heading_recommended_vendors": "Recommended Vendors",
    "heading_invited_reviewers": "Invited Reviewers",
    "heading_issuer": "Issuer",
    "heading_linked_projects": "Linked Projects",
    "heading_log_into_your_account": "Log into your account",
    "heading_manage_requested_info": "Manage Requested Information",
    "heading_manage_your_organization": "Manage Your Organization",
    "heading_managers_and_agents": "Managers and Agents",
    "heading_mandatory_criteria": "Mandatory Criteria",
    "heading_message_from_project_manager": "Message from Project Manager:",
    "heading_min_sys_reqs": "Minimum System Requirements",
    "heading_min_sys_requirements": "Minimum System Requirements",
    "heading_missed": "Missed",
    "heading_need_to_revise_your_submission": "Need to Revise Your Submission?",
    "heading_new_requested_data": "New Requested Data:",
    "heading_new_requested_documents": "New Requested Documents:",
    "heading_new_requested_questionnaires": "New Requested Questionnaires:",
    "heading_new_vendor_registration": "New Vendor Registration",
    "heading_notes": "Notes",
    "heading_number_provide_a_reason_for_amendment": "%s Provide a Reason for Amendment",
    "heading_number_select_a_proposal_document": "%s Select a Proposal Document",
    "heading_number_upload_a_file": "%s Upload a File",
    "heading_open_date": "Open Date",
    "heading_open_projects": "Open Projects",
    "heading_org_name": "Organization Name",
    "heading_original_requested_data": "Original Requested Data:",
    "heading_original_requested_documents": "Original Requested Documents:",
    "heading_original_requested_questionnaires": "Original Requested Questionnaires:",
    "heading_other_submissions": "Other Submissions",
    "heading_overall": "Overall",
    "heading_pending_projects": "Pending Projects",
    "heading_pending_user_invitations": "Pending User Invitations ",
    "heading_portal_security": "Portal Security",
    "heading_portal_sys_reqs": "In order to submit your proposal using this public portal you must",
    "heading_portal_tech_support": "Public Portal Technical Support",
    "heading_powered_by": "Powered by",
    "heading_preferred_language": "Preferred Language",
    "heading_prepare_submission": "Prepare Submission",
    "heading_private_project_link": "Private Project Link",
    "heading_procurement_portal": "Procurement Portal",
    "heading_project_caps": "PROJECT",
    "heading_project_closed_to_new_submissions_caps": "Project Closed to New Submissions",
    "heading_project_criteria": "Project Criteria",
    "heading_project_description": "Project Description",
    "heading_project_details": "Project Details",
    "heading_project_files": "Project Files",
    "heading_project_name": "Project Name",
    "heading_project_notes": "Project Notes",
    "heading_project_overview": "Project Overview",
    "heading_project_proposals_and_scores": "Scoring Summary",
    "heading_project_summary": "Project Summary",
    "heading_projects": "Projects",
    "heading_projects_to_eval": "Projects To Evaluate",
    "heading_proposal_data": "Proposal Data",
    "heading_proposal_notes": "Proposal Notes",
    "heading_proposal_scores": "Proposal Scores",
    "heading_provide_feedback": "Provide Feedback",
    "heading_public_portal": "Public Purchasing Portal",
    "heading_public_portal_security": "Public Portal Security",
    "heading_questionnaires_submitted_by_x": "Questionnaires submitted by %s:",
    "heading_reference_num": "Reference #",
    "heading_register_as_as_vendor": "Register as a Vendor",
    "heading_reminders": "Reminders",
    "heading_report_generated_by": "Report generated by / for %s",
    "heading_reports": "Reports",
    "heading_request_help": "Request Help",
    "heading_request_support": "Request Support",
    "heading_requested_data": "Requested Data",
    "heading_requested_docs": "Requested Documents",
    "heading_requested_info": "Requested Information",
    "heading_requested_questionnaires": "Requested Questionnaires",
    "heading_reset_your_password": "Reset Your Password",
    "heading_reviewer_roles": "Reviewer Role(s)",
    "heading_reviewers": "Reviewers",
    "heading_score_and_comments": "Scores and Comments",
    "heading_scored_by_evaluation_group": "Scored by Evaluation Group",
    "heading_scoring_comments": "Scoring Comments",
    "heading_scoring_summary": "Scoring Summary",
    "heading_signatures": "Signatures",
    "heading_special_instructions": "Special Instructions",
    "heading_staff_login": "Staff Login",
    "heading_start_submission": "Start Submission",
    "heading_step_1_create_account": "Step 1: Create an Account to Start Your Submission",
    "heading_step_1_login": "Step 1: Log Into Your Account",
    "heading_step_2_upload_docs": "Step 2: Upload Documents",
    "heading_submission_instructions": "Submission Instructions",
    "heading_submission_instructions_for_suppliers": "Submission Instructions for Suppliers",
    "heading_submission_scores": "Submission Scores",
    "heading_submissions": "Submissions",
    "heading_submissions_portal": "Submissions Portal",
    "heading_submissions_recieved": "Submissions Received",
    "heading_submit_proposal": "Submit Proposal",
    "heading_summary_table": "Summary Table",
    "heading_supplier_login": "Supplier Login",
    "heading_supplier_proposals": "SUPPLIER PROPOSALS",
    "heading_support_ticket": "SUPPORT TICKET:",
    "heading_supported_chrome_version": "Google Chrome",
    "heading_supported_firefox_version": "Mozilla Firefox",
    "heading_supported_windows": "(Windows) ",
    "heading_supported_windows_and_mac": "(Windows &amp; Mac)",
    "heading_technical_support": "Technical Support",
    "heading_tell_us_about_your_issue": "Tells us about your issue",
    "heading_terminate_contract": "Terminate Contract",
    "heading_terms_of_service": "Terms of Service",
    "heading_privacy_policy": "Privacy Policy",
    "heading_sitemap": "Sitemap",
    "heading_total_s": "Total %s",
    "heading_upload_new_profile_picture": "Upload New Profile Picture",
    "heading_upload_s": "Upload %s",
    "heading_use_a_supported_browser": "Use a Supported Browser",
    "heading_users": "Users",
    "heading_using_data": "using Data",
    "heading_vendor_contact": "Vendor Contact",
    "heading_vendor_contact_information": "Vendor Contact Information",
    "heading_vendor_contacts": "Vendor Contacts",
    "heading_vendor_profile": "Vendor Profile",
    "heading_visible_to_evaluation_group": "Visible To Evaluation Group",
    "heading_weighted_criteria": "Weighted Criteria",
    "heading_your_scores": "Your Scores",
    "heading_your_settings": "Your Settings",
    "heading_your_submissions": "Your Submissions",
    "heading_requests_ready_for_draft": "Requests Ready for Draft",
    "import_questionnaire_instructions": "In order to add a Questionnaire to this project, you will need to download the template below, fill it out, and upload the file here when you are finished (.xlsx format only). If you make a mistake, you can always delete the Questionnaire and import a new one.",
    "instruction_click_here_to_dismiss": "Click here to dismiss this message.",
    "instructions_any_additional_information_outside_of_the_given_structure": "Any additional information outside of the given structure of the worksheets will not be visible to the purchaser.",
    "instructions_bulk_import_step_1": "This tool allows you to bulk create new %{keywordBidTable} items. Copy and Paste your data from Excel (tab delimited) into the text box below.",
    "instructions_bulk_import_step_1_columns": "Your data must contain exactly %{numColumns} columns (%{columnNames}). ",
    "instructions_fill_in_your_contact_information_below": "Fill in your contact information below.",
    "instructions_for_each_question_set_enter": "For each question set, select a response from the dropdown (if applicable) and enter a response comment for each question in the table.",
    "instructions_if_you_decide_not_to_bid_on_an_item": "If you decide not to bid on an item, then you must select `No Bid` in the `Bid/No Bid Decision` column and all of the other editable cells for the item must be blank.",
    "instructions_if_you_decide_to_bid_on_an_item": "If you decide to bid on an item, then you must select `Bid` in the `Bid/No Bid Decision` column and all of the other editable cells for the item must contain a valid value.",
    "instruction_by_default_bid_is_selected_for_every_item": "By default, every item has `No Bid` selected for the `Bid/No Bid Decision` column.",
    "instruction_to_submit_additional_bids_for_an_item": "To submit additional bids for an item, you must go to the 'Additional Responses' tab.",
    "instruction_if_you_decide_to_submit_an_additional_response_for_an_item": "If you decide to submit an additional response for an item, then you must also complete a primary response bid for that item.",
    "instruction_every_item_has_a_unique_item_number_found_in_column_D": "Every item has a unique item number found in column D. To submit an additional bid for an item, enter the item number from the Primary Responses sheet into the blank item number cell on the Additional Responses sheet (column D).",
    "instruction_entering_the_item_number_on_the_additional_responses_sheet_will": "Entering the item number on the Additional responses sheet will cause the line to populate with item-specific info. An additional response can now be completed by filling in the blank cells with the bid information you wish to submit.",
    "instruction_you_can_submit_as_many_additional_bids_for_each_item": "You can submit as many additional bids for each item as you would like",
    "instructions_if_you_have_any_questions_regarding": "If you have any questions regarding the content of this file, please contact the appropriate purchaser.",
    "instructions_if_you_have_any_technical_problems": "If you have any technical problems, please contact Bonfire at Support@GoBonfire.com.",
    "instructions_please_do_not_change_the_structure": "Please do not change the structure of any of the worksheets. Changing the structure will invalidate your submission.",
    "instructions_please_do_not_save_this_file": "Please do not save this file in a different format. Saving this file in a different format will invalidate your submission.",
    "instructions_please_follow_the_instructions_provided_along": "Please follow the instructions provided along with this file to submit it back to Bonfire.",
    "instructions_please_use_paste_special": "When pasting content, please use Paste Special as Text without any formatting.",
    "instructions_the_summary_worksheet_displays_your": "The Summary worksheet displays your overall progress for the questionnaire.",
    "instructions_the_worksheets_numbered_from_1_to": "The worksheets numbered from 1 to N represent question sets.",
    "instructions_when_submitting_s_you_will_need_to_provide_s_for_s_that_you_are_bidding_on": "When submitting '%s' you will need to provide a %s for: %s that you are bidding on.",
    "keyword_action": "Action",
    "keyword_actions": "Actions",
    "keyword_activated": "Activated",
    "keyword_active": "Active",
    "keyword_add": "Add",
    "keyword_advisor": "Advisor",
    "keyword_advisors": "Advisors",
    "keyword_agent": "Agent",
    "keyword_all": "All",
    "keyword_alternate": "Alternate",
    "keyword_alternate_option": "Alternate Option",
    "keyword_amend": "Amend",
    "keyword_amendment": "Amendment",
    "keyword_archived": "Archived",
    "keyword_average": "Average",
    "keyword_basket": "Basket",
    "keyword_beta": "Beta",
    "keyword_bidtable": "BidTable",
    "keyword_bidtables": "BidTables",
    "keyword_budget": "Budget",
    "keyword_budget_range": "Budget Range",
    "keyword_calculated": "Calculated",
    "keyword_cancel": "Cancel",
    "keyword_categories": "Categories",
    "keyword_category": "Category",
    "keyword_category_item": "Category Item",
    "keyword_category_items": "Category Items",
    "keyword_change": "Change",
    "keyword_close_date": "Close Date",
    "keyword_closed": "Closed",
    "keyword_closing": "Closing",
    "keyword_closing_time": "Closing Time",
    "keyword_column": "Column",
    "keyword_comments": "Comments",
    "keyword_complete": "Complete",
    "keyword_error?": "Error?",
    "keyword_complete_no_errors": "Complete: no errors",
    "keyword_no_errors": "No errors",
    "keyword_errors_present": "Errors present",
    "keyword_completed": "Completed",
    "keyword_compliant": "Compliant",
    "keyword_consensus_score": "Consensus Score",
    "keyword_contract": "Contract",
    "keyword_contract_for_add": "Add Contract",
    "keyword_unassigned": "Unassigned",
    "keyword_contract_template_for_add": "Add Contract Template",
    "keyword_contract_document": "Contract Document",
    "keyword_conversations": "Conversations",
    "keyword_created": "Created",
    "keyword_criteria": "criteria",
    "keyword_criteria_cap": "Criteria",
    "keyword_criteria_group": "Criteria Group",
    "keyword_criterion": "Criterion",
    "keyword_currency": "Currency",
    "keyword_data": "Data",
    "keyword_date": "Date",
    "keyword_days": "days",
    "keyword_days_left": "Days Left",
    "keyword_decision": "Decision",
    "keyword_declarations": "Declarations",
    "keyword_delete": "Delete",
    "keyword_department": "Department",
    "keyword_description": "Description",
    "keyword_details": "Details",
    "keyword_document": "Document",
    "keyword_documents": "Documents",
    "keyword_done_uppercase": "DONE",
    "keyword_download": "Download",
    "keyword_draft": "Draft",
    "keyword_drafts": "Drafts",
    "keyword_duplicate": "Duplicate",
    "keyword_each": "Each",
    "keyword_edit": "Edit",
    "keyword_editors": "Editors",
    "keyword_eliminated": "Eliminated",
    "keyword_end": "End",
    "keyword_error": "Error",
    "keyword_evaluated_by": "Evaluated By",
    "keyword_evaluating": "Evaluating",
    "keyword_evaluation_group": "Evaluation Group",
    "keyword_evaluationgroups": "Evaluation Groups",
    "keyword_evaluator": "Evaluator",
    "keyword_example": "Example",
    "keyword_expired": "Expired",
    "keyword_expires": "Expires",
    "keyword_external": "External",
    "keyword_fail": "Fail",
    "keyword_failure": "Failure",
    "keyword_false": "False",
    "keyword_file": "File",
    "keyword_file_type": "File Type",
    "keyword_filename": "Filename",
    "keyword_files": "Files",
    "keyword_filetype": "Filetype",
    "keyword_format": "Format",
    "keyword_formula": "Formula",
    "keyword_generic": "Generic",
    "keyword_google_chrome": "Google Chrome",
    "keyword_hello": "Hello",
    "keyword_help": "Help",
    "keyword_here": "here",
    "keyword_hide": "Hide",
    "keyword_high": "High",
    "keyword_import": "Import",
    "keyword_importing_criteria": "Importing Criteria",
    "keyword_in": "in",
    "keyword_incomplete": "Incomplete",
    "keyword_input": "Input",
    "keyword_instructions": "Instructions",
    "keyword_internal": "Internal",
    "keyword_internal_submission": "Internal Submission",
    "keyword_internal_submissions": "Internal Submissions",
    "keyword_invitation": "Invitation",
    "keyword_invite": "Invite",
    "keyword_invites": "Invites",
    "keyword_item": "Item",
    "keyword_label": "Label",
    "keyword_length": "Length",
    "keyword_less": "Less",
    "keyword_loading": "Loading",
    "keyword_locked": "Locked",
    "keyword_unlocked": "Unlocked",
    "label_log_in": "Log In",
    "label_log_out": "Log Out",
    "keyword_low": "Low",
    "keyword_manage": "Manage",
    "keyword_manager": "Manager",
    "keyword_mandatory": "Mandatory",
    "keyword_mandatory_abbr": "Mand.",
    "keyword_merged": "Merged",
    "keyword_messages": "Messages",
    "keyword_min": "min",
    "keyword_mixed": "Mixed",
    "keyword_months": "Months",
    "keyword_more": "More",
    "keyword_mozilla_firefox": "Mozilla Firefox",
    "keyword_safari": "Safari",
    "keyword_multiple": "Multiple",
    "keyword_multiple_files": "Multiple Files",
    "keyword_name": "Name",
    "keyword_navigation": "Navigation",
    "keyword_new": "New",
    "keyword_non_disclosure": "Non-Disclosure",
    "keyword_non_disclosure_agreement": "Non-Disclosure Agreement",
    "keyword_note": "Note",
    "keyword_notes": "Notes",
    "keyword_now": "Now",
    "keyword_number": "Number",
    "keyword_observers": "Observers",
    "keyword_of": "of",
    "keyword_official_time": "Official Time",
    "keyword_on": "on",
    "keyword_open": "Open",
    "keyword_open_date": "Open Date",
    "keyword_open_uppercase": "OPEN",
    "keyword_opening": "Opening",
    "keyword_opportunities": "Opportunities",
    "keyword_opportunity": "Opportunity",
    "keyword_option": "Option",
    "keyword_optional": "Optional",
    "keyword_or": "Or",
    "keyword_order": "Order",
    "keyword_organization": "Organization",
    "keyword_organization_admin": "Organization Admin",
    "keyword_original": "Original",
    "keyword_overdue": "Late",
    "keyword_overridden": "Overridden",
    "keyword_override": "Override",
    "keyword_owner": "Owner",
    "keyword_page": "Page",
    "keyword_page_number": "Page #",
    "keyword_pass": "Pass",
    "keyword_pass_or_fail": "Pass/Fail",
    "keyword_pending": "Pending",
    "keyword_people": "People",
    "keyword_permissions": "Permissions",
    "keyword_person": "Person",
    "keyword_points": "Points",
    "keyword_portal": "Portal",
    "keyword_prepare": "Prepare",
    "keyword_pricing": "Pricing",
    "keyword_primary_reason": "Primary Reason",
    "keyword_processing": "Processing",
    "keyword_page_count_processing": "Page Count Processing",
    "keyword_progress": "Progress",
    "keyword_project": "Project",
    "keyword_project_created": "Project Created",
    "keyword_project_description": "Project Description",
    "keyword_project_file": "Project File",
    "keyword_project_lowercase": "project",
    "keyword_project_name": "Project Name",
    "keyword_project_owner": "Project Owner",
    "keyword_project_type": "Project Type",
    "keyword_projects": "Projects",
    "keyword_proposal": "Proposal",
    "keyword_proposals": "Proposals",
    "keyword_protected": "Protected",
    "keyword_public": "Public",
    "keyword_private": "Private",
    "keyword_question": "Question",
    "keyword_questionnaire": "Questionnaire",
    "keyword_questionnaires": "Questionnaires",
    "keyword_questions": "Questions",
    "keyword_rated": "Rated",
    "keyword_raw": "Raw",
    "keyword_reason": "Reason",
    "keyword_reason_set": "Reason Set",
    "keyword_received": "Received",
    "keyword_ref": "Ref.",
    "keyword_reference": "Reference",
    "keyword_reference_abbr": "Ref.",
    "keyword_reference_num": "Reference #",
    "keyword_reference_num_abbr": "Ref. #",
    "keyword_related_criteria": "Related Criteria",
    "keyword_remaining": "Remaining",
    "keyword_reminder": "Reminder",
    "keyword_remove": "Remove",
    "keyword_required": "Required",
    "keyword_required_question_mark": "Required?",
    "keyword_requirement": "Requirement",
    "keyword_response": "Response",
    "keyword_result": "Result",
    "keyword_reviewer": "Reviewer",
    "keyword_reviewer_scores": "Reviewer Scores",
    "keyword_reviewers": "Reviewers",
    "keyword_row": "Row",
    "keyword_said": "said",
    "keyword_scale": "Scale",
    "keyword_schedule": "Schedule",
    "keyword_score": "Score",
    "keyword_scorecard": "Scorecard",
    "keyword_scores": "Scores",
    "keyword_sealed": "Sealed",
    "keyword_search": "Search",
    "keyword_select": "Select",
    "keyword_send": "Send",
    "keyword_settings": "SETTINGS",
    "keyword_settings_lowercase": "Settings",
    "keyword_show": "Show",
    "keyword_similar": "Similar",
    "keyword_stage": "Stage",
    "keyword_start": "Start",
    "keyword_status": "Status",
    "keyword_submission": "Submission",
    "keyword_submission_lowercase": "submission",
    "keyword_submissions": "Submissions",
    "keyword_submit": "Submit",
    "keyword_subset": "Subset",
    "keyword_subsets": "Subsets",
    "keyword_subtype": "Subtype",
    "keyword_success": "Success",
    "keyword_summary": "Summary",
    "keyword_supplier": "Supplier",
    "keyword_support": "Support",
    "keyword_support_page": "support page",
    "keyword_term": "Term",
    "keyword_text": "Text",
    "keyword_time": "Time",
    "keyword_title": "Title",
    "keyword_to": "to",
    "keyword_toggle": "Toggle",
    "keyword_total": "Total",
    "keyword_total_caps": "TOTAL",
    "keyword_total_score": "Total Score",
    "keyword_tour": "Tour",
    "keyword_true": "True",
    "keyword_type": "Type",
    "keyword_unavailable": "Unavailable",
    "keyword_unique_per_alternate": "Unique per Alternate",
    "keyword_uploading": "Uploading",
    "keyword_user": "User",
    "keyword_user_roles": "User Roles",
    "keyword_user_role": "User Role",
    "keyword_value": "Value",
    "keyword_value_lower": "value",
    "keyword_vendor": "Vendor",
    "keyword_vendors": "Vendors",
    "keyword_view": "View",
    "keyword_visible": "Visible",
    "keyword_warning": "WARNING",
    "keyword_week": "week",
    "keyword_weight": "Weight",
    "keyword_weights": "Weights",
    "keyword_you": "You",
    "label_abbreviation": "Abbreviation",
    "label_account_access": "Account Access",
    "label_actions": "Actions",
    "label_active": "Active",
    "label_active_column": "Active Column",
    "label_add_a_vendor_field": "Add a Vendor Field",
    "label_add_advisors": "Add Advisors",
    "label_add_another_submission": "Upload Another Submission",
    "label_add_basket": "Add Basket",
    "label_add_column": "Add Column",
    "label_add_criteria": "Add Criteria",
    "label_add_criteria_to_group": "Add Criteria to Group",
    "label_add_evaluation_group": "Add Evaluation Group",
    "label_add_item": "Add Item",
    "label_add_recommended_vendors": "Add Recommended Vendors",
    "label_add_reviewers": "Add Reviewers",
    "label_add_user_as_advisor": "Add User as Advisor",
    "label_add_vendor_to_list": "Add Vendor to %{list}",
    "label_add_vendors_from_list": "Add Vendors from List",
    "label_added_by": "Added By",
    "label_additional_reference_documents": "The following files are attached to this project",
    "label_additional_responses": "Additional Responses",
    "label_address_line_number": "Address Line %{number}",
    "label_adjustable": "Adjustable",
    "label_advanced_algorithms": "Advanced Algorithms",
    "label_advising": "Advising",
    "label_ago": "ago",
    "label_all": "All",
    "label_all_reviewers": "All Reviewers",
    "label_all_scorable_questions": "All Scorable Questions",
    "label_all_unscored_questions": "All Unscored Questions",
    "label_alt_upload": "Alternative Upload Site",
    "label_alternate_options": "Alternate Options",
    "label_amended_by": "Amended By",
    "label_anonymous_submissions": "Anonymous Submissions",
    "label_answer": "Answer",
    "label_any_file_type": "Any file type",
    "label_approved_on": "Approved On",
    "label_archived": "Archived",
    "label_archive_project": "Archive Project",
    "label_assigned_to": "Assigned To",
    "label_associations": "Associations",
    "label_auto_reminder": "Auto-Reminder",
    "label_auto_renewable": "Renewable",
    "label_auto_select": "Auto-Select",
    "label_automatic": "(Automatic)",
    "label_automatic_increment": "Automatic Increment",
    "label_automatic_increment_placeholder": "Value will be generated on contract creation",
    "label_average_number_of_users": "Average Number of Users",
    "label_award_notices": "Award Notices",
    "label_awarded_to": "Awarded To",
    "label_awarded_to_s": "Awarded to %s",
    "label_back_to_evaluation": "Back to Evaluation",
    "label_back_to_login": "Back to Login",
    "label_back_to_step_1": "Back to Step 1",
    "label_basket_name": "Basket Name",
    "label_best_value": "Best Value",
    "label_bid": "Bid",
    "label_bid_no_bid_decision": "Bid/No Bid Decision",
    "label_bidding_on_s_of_s_items": "Bidding on %{numItemsIsBidding} / %{numItems} items",
    "label_bonfire_help_center": "Bonfire Help Centre",
    "label_budget": "Budget",
    "label_budget_range_optional": "Budget Range (optional)",
    "label_bulk_actions": "Bulk Actions",
    "label_bulk_import": "Bulk Import",
    "label_calculated_column": "Calculated Column",
    "label_categories_and_items": "Categories & Items",
    "label_category_name": "Category Name",
    "label_change_order": "Change Order",
    "label_change_orders": "Change Orders",
    "label_total_value_with_change_orders": "<strong>Total Value</strong> (with change orders)",
    "label_total_value_without_change_orders": "<strong>Total Value</strong> (without change orders)",
    "label_checkbox": "Checkbox",
    "label_checked_keywordBidTable_items": "checked %{keywordBidTable} items",
    "label_choose_file": "Choose File",
    "label_city": "City",
    "label_clean_up": "Clean Up",
    "label_clear_selection": "Clear Selection",
    "label_click_to_rename": "Click to rename",
    "label_close_date": "Close Date",
    "label_close_to_release": "Close to Release",
    "label_code": "Code",
    "label_code_set": "Code Set",
    "label_cost_management": "Cost Management",
    "label_column_name": "Column Name",
    "label_commodity_code": "Commodity Code",
    "label_commodity_codes": "Commodity Codes",
    "label_commodity_set": "Commodity Set",
    "label_completed": "Completed",
    "label_complete_registration": "Complete Registration",
    "label_competitive_sealed_proposal": "CSP (Competitive Sealed Proposal)",
    "label_confirm_account": "Confirm Account",
    "label_confirm_email": "Confirm Email",
    "label_confirm_new_password": "Confirm New Password",
    "label_confirmation_code": "Confirmation Code",
    "label_confirmation_details": "Confirmation Details",
    "label_conflict": "Conflict",
    "label_consensus_scorecard": "Consensus Scorecard",
    "label_consensus_scoring": "Consensus Scoring",
    "label_contact_bonfire_support_here": "Contact Bonfire Support here",
    "label_contact_email": "Contact Email",
    "label_contact_first_name": "Contact First Name",
    "label_contact_information": "Contact Information",
    "label_contact_last_name": "Contact Last Name",
    "label_contact_name": "Contact Name",
    "label_content_slash_title": "Content/Title",
    "label_context": "Context",
    "label_contract": "Contract",
    "label_contracts": "Contracts",
    "label_contract_field": "Contract Field",
    "label_contract_fields": "Contract Fields",
    "label_contract_name": "Contract Name",
    "label_contract_observer": "Contract Observer",
    "label_contract_owner": "Contract Owner",
    "label_contract_type": "Contract Type",
    "label_contract_types": "Contract Types",
    "label_contract_template": "Contract Template",
    "label_count": "Count",
    "label_country": "Country",
    "label_create_account": "Create account",
    "label_create_similar_project": "Create Similar Project",
    "label_create_similar_x": "Create Similar %{x}",
    "label_create_thing": "Create %{thing}",
    "label_create_vendor_list": "Create Vendor List",
    "label_create_your_free_bonfire_account": "Create your free Bonfire account",
    "label_criteria": "Criteria",
    "label_current_advisors": "Current Advisors",
    "label_current_editors": "Current Editors",
    "label_current_observers": "Current Observers",
    "label_current_reviewers": "Current Reviewers",
    "label_current_score": "Current Score",
    "label_custom_attributes": "Custom Attributes",
    "label_custom_vendor_fields": "Custom Vendor Fields",
    "label_data_type": "Data Type",
    "label_date_added": "Date Added",
    "label_date_created": "Date Created",
    "label_date_joined": "Date Joined",
    "label_date_signed": "Date Signed",
    "label_date_declared": "Date Declared",
    "label_dates": "Dates",
    "label_deanonymize": "De-Anonymize",
    "label_decision_name": "Decision Name",
    "label_decisions": "Decisions",
    "label_department": "Department",
    "label_department_abbreviation": "Abbreviation",
    "label_department_name": "Department Name",
    "label_describe_problem": "Please describe your technical problem:",
    "label_description": "Description",
    "label_details": "Details",
    "label_disabled": "Disabled",
    "label_do_you_have_a_conflict_of_interest": "Do you have a Conflict of Interest?",
    "label_document_slot_file_type_any": "Any (.*)",
    "label_document_taker": "Document Taker",
    "label_document_takers": "Document Takers",
    "label_documents": "Documents",
    "label_documents_slash_data": "Documents/Data",
    "label_domain_name": "Domain Name",
    "label_download_all_files": "Download All Files",
    "label_download_amendment": "Download Amendment",
    "label_download_original": "Download Original",
    "label_download_vendor_template": "Download Vendor Template",
    "label_duration": "Duration",
    "label_editing": "Editing",
    "label_edit_baskets": "Edit Baskets",
    "label_edit_roles": "Edit Roles",
    "label_edit_vendor_list": "Edit Vendor List",
    "label_eg": "e.g.",
    "label_eliminate_items": "Eliminate Items",
    "label_eliminated_by": "Eliminated By",
    "label_email": "Email",
    "label_email_address_or_domain_name": "Email Address or Domain Name",
    "label_email_again": "Email (Again)",
    "label_email_notifications": "Email Notifications",
    "label_email_us_at": "Email us at",
    "label_enabled": "Enabled",
    "label_end_date": "End Date",
    "label_end_department_owner": "End Department Owner",
    "label_end_month": "End Month",
    "label_end_year": "End Year",
    "label_enter_their_email": "Enter their email",
    "label_enter_your_email": "Enter your email address",
    "label_enter_your_new_password": "Enter your new password",
    "label_enter_your_new_password_again": "Enter your new password again",
    "label_eoi_expression_of_interest": "EOI (Expression of Interest)",
    "label_estimated_upload_time": "Estimated upload time",
    "label_evaluate_by_date": "Evaluate By Date",
    "label_evaluating": "Evaluating",
    "label_evaluations": "Your Evaluations",
    "label_evaluators_advisors_and_observers": "Evaluators, Advisors, and Observers",
    "label_excel_file": "Excel File",
    "label_expired": "Expired",
    "label_expires": "Expires",
    "label_extendable": "Extendable",
    "label_failure_reason": "Failure Reason",
    "label_field": "Field",
    "label_field_name": "Field Name",
    "label_file_name": "File Name",
    "label_file_size": "File size",
    "label_file_to_amend": "File to Amend",
    "label_file_type": "File Type",
    "label_fill_in_data_slots": "Fill in the Data Fields",
    "label_filter": "Filter",
    "label_first_name": "First Name",
    "label_first_score_to_last_score": "First Score to Last Score",
    "label_formula_builder": "Formula Builder",
    "label_full_control": "Full Control",
    "label_limited_control": "Limited Control",
    "label_full_name": "Full Name",
    "label_go": "Go",
    "label_has_conflict_of_interest": "Has a Conflict of Interest?",
    "label_high": "High",
    "label_high_score_reasons": "High Score Reasons",
    "label_highest": "Highest",
    "label_hr": "hr",
    "label_x_hr_ago": "%{x} hr ago",
    "label_i_want_to_create_a_public_award_notice": "I want to create a public award notice",
    "label_i_want_to_make_this_file_public": "I want to make this file public",
    "label_if_no_please_provide_a_reason": "If no, please provide a reason",
    "label_include": "Include",
    "label_include_items": "Include Items",
    "label_apply_vendor_values": "Apply Vendor Values",
    "label_select_vendor_column": "Select Vendor Column",
    "label_note_locked_items_will_not_be_affected": "Locked items will not be affected",
    "alert_you_will_overwrite_existing_vendor_values": "You will over-write existing Vendor Values in %{vendorHiddenColumnName} for selected items.",
    "label_intent_to_bid": "Intent to Bid",
    "label_intent_to_bid_due": "Intent to Bid Due",
    "label_intent_to_bid_due_date": "Intent to Bid Due Date",
    "label_internal_documents": "Internal Documents",
    "label_internal_file": "Internal File",
    "label_internal_files": "Internal Files",
    "label_internal_link": "Internal Link",
    "label_invalid_column": "Invalid Column",
    "label_invalid_selection": "Invalid Selection",
    "label_invalid_state": "Invalid State",
    "label_invitation_for_bid": "IFB (Invitation for Bid)",
    "label_invitation_pending": "Invitation Pending",
    "label_invitation_to_bid": "ITB (Invitation to Bid)",
    "label_invitation_to_negotiate": "ITN (Invitation to Negotiate)",
    "label_invitation_to_pre_qualify": "ITPQ (Invitation to Pre-Qualify)",
    "label_invitation_to_quote": "ITQ (Invitation to Quote)",
    "label_invitation_to_tender": "ITT (Invitation to Tender)",
    "label_invitations": "Invitations",
    "label_invite_expired": "Invite expired",
    "label_invite_expires": "Invite expires",
    "label_invite_new_advisor_by_email": "Invite New Advisor by Email",
    "label_invited_advisors": "Invited Advisors",
    "label_invited_editors": "Invited Editors",
    "label_invited_observers": "Invited Observers",
    "label_invited_opportunities": "Invited Opportunities",
    "label_my_opportunities": "My Opportunities",
    "label_invited_reviewers": "Invited Reviewers",
    "label_is_this_site_secure": "Is this site secure?",
    "label_item_name": "Item Name",
    "label_items": "Items",
    "label_just_now": "just now",
    "label_key": "Key",
    "label_keyword": "Keyword",
    "label_kpi_money_awarded_graph_title": "Money Awarded to Proposals over Time",
    "label_kpi_money_awarded_graph_description_deprecated": "[Deprecated] The sum of the total prices for awarded Proposals. Note: This chart is being depreacted in favor of the Project Spend Insight",
    "label_kpi_money_saved_graph_title": "Money Saved over Time",
    "label_kpi_money_saved_graph_description": "The sum of the money saved. Money saved is calculated as the difference between the cost of the best ranked Proposal and the highest cost Proposal.",
    "label_kpi_number_of_users_per_project_graph_title": "Average Number of Users per Project",
    "label_kpi_number_of_users_per_project_graph_description": "The number of users by role per project.",
    "label_kpi_paper_saved_graph_title": "Pages of Paper Saved over Time",
    "label_kpi_paper_saved_graph_description": "The sum of pages of all proposal files multiplied by the number of participants in the project.",
    "label_kpi_project_configuration_graph_title": "Average Project Configuration",
    "label_kpi_project_configuration_graph_description": "The average number of Evaluation Groups, Criteria, Requested Information, Internal Documents, and Public Documents.",
    "label_kpi_project_count_graph_title": "Count of Projects Created over Time",
    "label_kpi_project_count_graph_description": "The number of Projects created over time.",
    "label_kpi_project_stage_time_graph_title": "Average Time in each Project Stage",
    "label_kpi_project_stage_time_graph_description": "The average amount of time projects spend in each stage.",
    "label_kpi_submission_time_graph_title": "Count of Proposals by Submission Time",
    "label_kpi_submission_time_graph_description": "Submission time is calculated as the difference between the time the first file was uploaded and the time the last file was uploaded.",
    "label_kpi_user_count_graph_title": "Count of Users by Role over Time",
    "label_kpi_user_count_graph_tooltip": "The number of users over time, categorized by user role",
    "label_kpis": "KPIs",
    "label_label": "Label",
    "label_last_activity": "Last Activity",
    "label_last_login": "Last Login",
    "label_last_name": "Last Name",
    "label_last_score_to_completion": "Last Score to Completion",
    "label_latest_version_here": "latest version here",
    "label_lead_date": "Lead Date",
    "label_lead_time": "Lead Time",
    "label_lock_items": "Lock Items",
    "label_log_in_to_bonfire": "Log in to Bonfire",
    "label_long_text": "Long Text",
    "label_low": "Low",
    "label_low_score_reasons": "Low Score Reasons",
    "label_lowest": "Lowest",
    "label_main_evaluation": "Main Evaluation",
    "label_manage": "Manage",
    "label_managing_requested_info": "Managing Requested Information",
    "label_mandatory": "MANDATORY",
    "label_mandatory_criteria": "Mandatory Criteria",
    "label_mark_as": "Mark As",
    "label_mark_as_completed": "Mark as Completed",
    "label_marker": "Marker",
    "label_match_to": "Match to",
    "label_max": "Max",
    "label_max_data_slot_text_length": "Max Length: %s characters",
    "label_max_size": "Max size",
    "label_medium_score_reasons": "Medium Score Reasons",
    "label_merged_vendor": "Merged Vendor",
    "label_merge_vendors": "Merge Vendors",
    "label_message": "Message",
    "label_message_too_long": "Your message exceeds the character limit of 2000 characters",
    "label_message_to_suppliers": "Message to Suppliers",
    "label_min": "min",
    "label_x_min_ago": "%{x} min. ago",
    "label_misc_notes": "Misc. Notes",
    "label_money_awarded": "Money Awarded",
    "label_money_saved": "Money Saved",
    "label_move_to_active_projects": "Move to Active Projects",
    "label_move_to_basket": "Move to Basket",
    "label_multi_category_decision": "Multi-Category Decisions",
    "label_multiple_files": "Multiple Files",
    "label_my_data_has_headers": "My data has headers",
    "label_name": "Name",
    "label_need_help": "Need Help?",
    "label_new_email": "New Email",
    "label_new_file": "New File",
    "label_new_filename": "New Filename",
    "label_new_password": "New Password",
    "label_new_password_again": "New Password Again",
    "label_new_user_email": "New User Email",
    "label_create": "Create",
    "label_next": "Next",
    "label_next_2_years": "Next 2 Years",
    "label_next_3_months": "Next 3 Months",
    "label_next_6_months": "Next 6 Months",
    "label_next_item": "Next Item",
    "label_next_step": "Next Step",
    "label_next_vendor": "Next Vendor",
    "label_next_year": "Next Year",
    "label_no_basket": "No Basket",
    "label_no_bid": "No Bid",
    "label_no_conflict": "No Conflict",
    "label_no_document_selected": "No Document Selected",
    "label_no_documents": "No Documents",
    "label_no_keywordBidTables": "No %{keywordBidTables}",
    "label_no_owner": "No Owner",
    "label_no_question_sets": "No Question Sets",
    "label_no_questionnaires": "No Questionnaires",
    "label_no_reminders": "No reminders",
    "label_no_resources_to_display": "No %{resources} to display",
    "label_no_comment_provided": "No Comment Provided",
    "label_no_x": "No %{x}",
    "label_noi": "NOI (Notice of Intent)",
    "label_non_extendable": "Non-Extendable",
    "label_none": "None",
    "label_not_applicable_abbrv": "N/A",
    "label_not_signed": "Not Signed",
    "label_note": "Note",
    "label_note_you_do_not_need_to_add_the_at_symbol": "Note: You do not need to add the @ symbol. For example if you wanted to add Gmail to the list you would simply enter 'gmail.com' in the text field above.",
    "label_notes": "Notes",
    "label_nothing_submitted": "Nothing Submitted",
    "label_notification_preferences": "Notification Preferences",
    "label_nrfp_negotiated_request_for_proposal": "NRFP (Negotiated Request For Proposal)",
    "label_number_of_files": "# Files",
    "label_number_of_items": "Number of Items",
    "label_number_of_pages": "Number of Pages",
    "label_numeric": "Numeric",
    "label_observing": "Observing",
    "label_old_matches": "Old Matches",
    "label_old_password": "Old Password",
    "label_one_vendor_found": "1 vendor found",
    "label_open": "Open",
    "label_open_date": "Open Date",
    "label_open_public_opportunities": "Open Public Opportunities",
    "label_open_to_close": "Open to Close",
    "label_opportunity_intent_to_bid": "Opportunity Intent to Bid",
    "label_opportunity_non_disclosure_agreements": "Opportunity Non-Disclosure Agreements",
    "label_opportunity_notification": "Opportunity Notification",
    "label_optional": "Optional",
    "label_options": "Options",
    "label_or_type_a_value_to_create_a_new_s": "or type a value to create a new %s",
    "label_or_type_an_email_to_invite_a_new_user": "or type an email to invite a new User",
    "label_or_type_an_email_and_press_enter_to_invite_a_new_user": "or enter an email, then press Enter to invite",
    "label_order_list": "Order List",
    "label_order_list_selection": "Order List Selection",
    "label_organization": "Organization",
    "label_organizations": "Organizations",
    "label_original_filename": "Original Filename",
    "label_orphaned_data": "Orphaned Data",
    "label_orphaned_documents": "Orphaned Documents",
    "label_other": "Other",
    "label_overall": "Overall",
    "label_owner": "Owner",
    "label_owners": "Owners",
    "label_page_number": "Page #",
    "label_pages": "Pages",
    "label_password": "Password",
    "label_password_again": "Password (Again)",
    "label_past_public_opportunities": "Past Public Opportunities",
    "label_pending": "Pending",
    "label_pending_conversion": "Pending conversion",
    "label_pending_processing": "Pending Processing",
    "label_pg_abbrv": "Pg.",
    "label_phone_number": "Phone Number",
    "label_pic": "Pic",
    "label_please_add_category_items": "Please add category items",
    "label_please_describe_your_problem": "Please describe your problem",
    "label_portal_link": "Portal Link",
    "label_postal_zip_code": "Postal / Zip Code",
    "label_prepare_an_additional_submission": "Prepare an Additional Submission",
    "label_prepare_as": "Prepare as",
    "label_prepare_as_a_new_organization": "Prepare as a new Organization",
    "label_prepare_your_submission": "Prepare Your Submission",
    "label_preview": "Preview",
    "label_previous_item": "Previous Item",
    "label_previous_vendor": "Previous Vendor",
    "label_pricing": "PRICING",
    "label_primary_responses": "Primary Responses",
    "label_progress": "Progress",
    "label_project_details_have_changed": "Project Details Have Changed",
    "label_project_description": "Project Description",
    "label_project_link": "Project Link",
    "label_project_name": "Project Name",
    "label_project_name_or_reference_number": "Project Name or Reference #",
    "label_project_owner": "Project Owner",
    "label_project_type": "Project Type",
    "label_protected": "Protected",
    "label_proposal_document": "Proposal Document:",
    "label_pts": "pts",
    "label_public": "Public",
    "label_public_document_takers": "Public Document Takers",
    "label_public_documents": "Public Documents",
    "label_public_file": "Public File",
    "label_public_files": "Public Files",
    "label_purchaser_column": "Purchaser Column",
    "label_purchaser_columns": "Purchaser Columns",
    "label_quantity_provided": "Quantity Provided",
    "label_quantity_required": "Quantity Required",
    "label_question": "Question",
    "label_question_set": "Question Set",
    "label_question_set_title": "Question Set Title",
    "label_questions": "Questions",
    "label_questions_due": "Questions Due",
    "label_questions_due_date": "Questions Due Date",
    "label_rank": "Rank",
    "label_reason_for_award_mandatory": "Award Reason (Required):",
    "label_reason_for_elimination": "Reason for Elimination",
    "label_reason_for_the_change": "Reason for the Change",
    "label_reason_optional": "Reason (optional)",
    "label_reason_required": "Reason (required)",
    "label_received": "Received",
    "label_recorded": "Recorded",
    "label_reference_id": "Reference ID",
    "label_rejector": "REJECTOR",
    "label_related_criteria": "Related Criteria",
    "label_release_to_first_score": "Release to First Score",
    "label_remember_email": "Remember email",
    "label_reopened": "Reopened",
    "label_request_details": "Request Details",
    "label_request_files": "Request Files",
    "label_request_for_bid": "RFB (Request for Bid )",
    "label_request_for_standing_offer": "RSO (Request for Standing Offer)",
    "label_request_to_resign": "Request to re-sign",
    "label_requested_document": "Requested Document",
    "label_requested_info": "Requested Info",
    "label_requested_information_has_change": "Requested Information Has Changed",
    "label_requested_keywordBidTable": "Requested %{keywordBidTable}",
    "label_requested_keywordBidTables": "Requested %{keywordBidTables}",
    "label_requested_questionnaire": "Requested Questionnaire",
    "label_required": "Required",
    "label_visible_project": "Show on Project Tables",
    "label_required_document": "Required document",
    "label_reset": "Reset",
    "label_reviewers_can_view": "Reviewers Can View",
    "label_rfc_request_for_comments": "RFC (Request for Comments)",
    "label_rfi_request_for_information": "RFI (Request For Information)",
    "label_rfp_request_for_proposal": "RFP (Request For Proposal)",
    "label_rfpq_request_for_pre_qualification": "RFPQ (Request For Pre Qualification)",
    "label_rfq_request_for_quotation": "RFQ (Request For Quotation)",
    "label_rfs_request_for_service": "RFS (Request For Service)",
    "label_rfsq_request_for_supplier_qualification": "RFSQ (Request For Supplier Qualification)",
    "label_rft_request_for_tender": "RFT (Request for Tender)",
    "label_role": "Role",
    "label_role_type": "Role Type",
    "label_s_matching_responses": "%{number} matching responses",
    "label_s_must_after_s": "%s must be after %s",
    "label_s_must_be_a_valid_date": "%{s} must be a valid date",
    "label_s_must_be_a_valid_s": "%s must be a valid %s.",
    "label_s_must_be_provided": "%s must be provided.",
    "label_s_vendors_found": "%s vendors found",
    "label_score": "Score",
    "label_scoring": "Scoring",
    "label_seal_status": "Seal status",
    "label_sections_to_include": "Sections to include",
    "label_secure_submit": "Secure Email Submission",
    "label_select_a_date": "Select a date...",
    "label_select_a_user": "Select a User",
    "label_select_an_option": "Select an option",
    "label_select_decisions": "Select Decisions",
    "label_select_department": "Select Department",
    "label_select_departments": "Select one or more departments",
    "label_select_document_type": "Select Document Type",
    "label_select_existing_proposal": "Select an Existing Proposal",
    "label_select_field": "Select field",
    "label_select_file_to_amend": "Select File to Amend",
    "label_select_order_list": "Select Order List",
    "label_select_questionnaire": "Select a Questionnaire",
    "label_select_role": "Select Role",
    "label_select_value_first": "Select Value First",
    "label_selected": "Selected",
    "label_selected_items": "Selected Items",
    "label_selection_summary": "Selection Summary",
    "label_sending_emails": "Sending emails",
    "label_set": "Set",
    "label_short_text": "Short Text",
    "label_show_hide_columns_panel": "Show/Hide Columns Panel",
    "label_show_slash_hide": "Show/Hide",
    "label_sign_and_submit": "Sign and submit",
    "label_sign_the_non_disclosure_agreement": "Sign the Non-Disclosure Agreement",
    "label_sow_statement_of_work": "SOW (Statement of Work)",
    "label_staff": "Staff",
    "label_start_date": "Start Date",
    "label_start_month": "Start Month",
    "label_start_year": "Start Year",
    "label_state_province": "State / Province",
    "label_status": "Status",
    "label_step_1": "Step 1",
    "label_step_2": "Step 2",
    "label_step_3": "Step 3",
    "label_step_x": "Step %s",
    "label_still_loading_please_wait": "Still loading, please wait...",
    "label_strong_matches": "Strong Matches",
    "label_submission_data": "Submission Data",
    "label_submission_received": "Submission Received",
    "label_submission_status": "Submission Status",
    "label_submission_time": "Submission Time",
    "label_submit_and_finalize": "Submit & Finalize",
    "label_success_reason": "Success Reason",
    "label_summary_of_changes": "Summary of Changes:",
    "label_supplier": "SUPPLIER",
    "label_supplier_submission_instructions": "Supplier Submission Instructions",
    "label_support_describe_problem": "Describe the problem.",
    "label_supporting_documentation": "Supporting Documentation",
    "label_template_file": "Template File",
    "label_template": "Template",
    "label_terminated": "Terminated",
    "label_termination_date": "Termination Date",
    "label_the_eliminated": "the Eliminated",
    "label_their_email": "Their email",
    "label_time": "Time",
    "label_title": "Title",
    "label_to_replace_an_uploaded_file": "To replace an uploaded file, simply upload another file into that slot. You will be prompted to confirm that you want to replace it.",
    "label_toggle_all": "Toggle All",
    "label_total": "Total",
    "label_total_cost": "Total Cost",
    "label_total_formula": "Total Formula",
    "label_total_pending_invites": "Total Pending Invites",
    "label_total_points": "Total Points",
    "label_total_price": "Total Price",
    "label_total_projects": "Total Projects",
    "label_total_score": "Total Score",
    "label_total_selected": "Total Selected",
    "label_total_users": "Total Users",
    "label_total_value": "Total Value",
    "label_true_slash_false": "True/False",
    "label_unit_price": "Unit Price",
    "label_unlock_items": "Unlock Items",
    "label_unseal": "Unseal",
    "label_unsealed_by": "Unsealed by",
    "label_unsealed_by_s_on_s": "Unsealed by %s on %s",
    "label_unsealed_on": "Unsealed on",
    "label_unselected_items": "Un-selected Items",
    "label_untitled_keywordBidTable": "Untitled %{keywordBidTable}",
    "label_upload_files": "Upload Your Files",
    "label_upload_files_using_the_buttons_below": "Upload files using the buttons below, each file upload will start immediately.",
    "label_uploaded": "Uploaded",
    "label_url": "URL",
    "label_value_options": "Value Options",
    "label_value_type": "Value Type",
    "label_values": "Values",
    "label_vendor_awards": "Vendor Awards",
    "label_vendor_column": "Vendor Column",
    "label_vendor_columns": "Vendor Columns",
    "label_vendor_list": "Vendor List",
    "label_vendor_list_name": "Vendor List Name",
    "label_vendor_lists": "Vendor Lists",
    "label_vendor_name": "Vendor Name",
    "label_vendor_type": "Vendor Type",
    "label_view": "View",
    "label_view_all_project_notes": "View All Project Notes",
    "label_view_amendment": "View Amendment",
    "label_view_archived_projects": "View Archived Projects",
    "label_view_nda": "View NDA",
    "label_view_original": "View Original",
    "label_view_s": "View %{s}",
    "label_visibility": "Visibility",
    "label_visible_to_the_public": "Visible to the public:",
    "label_weak_matches": "Weak Matches",
    "label_weight": "WEIGHT",
    "label_weighted": "WEIGHTED",
    "label_weighted_criteria": "Weighted Criteria",
    "label_your_comment": "Your Comment",
    "label_your_confirmation_code_id": "Your Confirmation Code ID",
    "label_your_current_profile_picture": "Your current profile picture",
    "label_your_email": "Your Email",
    "label_your_name": "Your Name",
    "label_your_organization": "Your Organization",
    "keyword_your_progress": "Your Progress",
    "label_your_score": "Your Score",
    "language_code_en": "English",
    "language_code_fr": "Français",
    "language_code_ru": "русский",
    "language_code_pt": "Português",
    "language_code_es": "Español",
    "math_dne": "does not equal",
    "math_eq": "equals",
    "math_gt": "greater than",
    "math_gte": "greater than or equal to",
    "math_lt": "less than",
    "math_lte": "less than or equal to",
    "message_youve_uploaded_a_s_for_the_s_which_requires_a_s_type_file_please_try_again_with_the_required_file_type": "You've uploaded a %s for the %s which requires a %s type file. Please try again with the required file type.",
    "notice_a_criteria_must_be_unanimously_failed_to_receive_a_fail": "A criterion must be unanimously failed in order to receive an overall 'Fail' rating.",
    "notice_a_decision_named_x_already_exists_with_the_same_categories": "A decision named %s already exists with the same category selections.",
    "notice_a_project_must_have_at_least_one_evaluation_group": "A project must have at least one evaluation group.",
    "notice_a_reviewer_with_that_email_has_already_been_invited_for_this_project": "A reviewer with that email has already been invited for this project",
    "notice_a_user_with_that_email_is_already_active_on_bonfire": "A user with that email is already active on Bonfire",
    "notice_a_user_with_that_email_is_already_active_on_bonfire_please_add_them_from_the_drop_down_menu_above": "A user with that email is already active on Bonfire. Please add them from the drop down menu above",
    "notice_a_vendor_with_that_email_is_already_active_on_bonfire": "A vendor with that email is already active on Bonfire",
    "notice_account_with_your_email_already": "There is a submission account already associated with that email address. Please log in (see right).",
    "notice_all_columns_referenced_in_the_calculated_column_formulas_must_exist_before_keywordBidTable_can_be_requested": "All purchaser and vendor columns referenced in the calculated column formulas must exist before the %{keywordBidTable} can be requested.",
    "notice_all_items_associated_with_this_category_will_be_deleted_as_well": "All items associated with this category will be deleted as well.",
    "notice_all_required_data_not_submitted_try_again": "One or more required data fields have not been filled in. Please refresh the page and try again.",
    "notice_all_required_documents_not_submitted_try_again": "One or more required documents have not been submitted. Please refresh the page and try again.",
    "notice_all_required_questionnaires_not_submitted_try_again": "One or more required questionnaires have not been submitted. Please refresh the page and try again.",
    "notice_all_subsets_in_question_set_x_must_contain_at_least_one_question": "All Subsets in Question Set %s must contain at least 1 question.",
    "notice_are_you_sure_you_want_to_action_this_thing": "Are you sure you want to %{action} this %{thing}?",
    "notice_are_you_sure_you_want_to_archive": "Are you sure you want to archive this project?",
    "notice_are_you_sure_you_want_to_break_the_seal_on_this_slot": "Are you sure you want to unseal this document/data?",
    "notice_are_you_sure_you_want_to_cancel_creating_this_project": "Are you sure you want to cancel creating this project? This cannot be undone.",
    "notice_are_you_sure_you_want_to_cancel_this_invitation": "Are you sure you want to cancel this invitation?",
    "notice_are_you_sure_you_want_to_create_a_project_from_this_decision": "Are you sure you want to create a project from this decision?",
    "notice_are_you_sure_you_want_to_deanonymize_the_project": "Are you sure you want to deanonymize the project? This will reveal the vendor names to everyone who can view the project.",
    "notice_are_you_sure_you_want_to_deanonymize_this_proposal": "Are you sure you want to deanonymize this proposal? This action cannot be undone.",
    "notice_are_you_sure_you_want_to_delete_data_slot": "Are you sure you want to delete this data field value?",
    "notice_are_you_sure_you_want_to_delete_this_amendment_this_cannot_be_undone": "Are you sure you want to delete this amendment? This cannot be undone.",
    "notice_are_you_sure_you_want_to_delete_this_conflict_of_interest_record": "Are you sure you want to delete this Conflict of Interest record? This action cannot be undone.",
    "notice_are_you_sure_you_want_to_delete_this_department": "Are you sure you want to delete this department",
    "notice_are_you_sure_you_want_to_delete_this_domain": "Are you sure you want to delete this domain?",
    "notice_are_you_sure_you_want_to_delete_this_file": "Are you sure you want to delete this file?",
    "notice_are_you_sure_you_want_to_delete_this_file_this_cannot_be_undone": "Are you sure you want to delete this file? This cannot be undone",
    "notice_are_you_sure_you_want_to_delete_this_project": "Are you sure you want to delete this project? This cannot be undone.",
    "notice_are_you_sure_you_want_to_delete_this_questionnaire_cannot_be_undone": "Are you sure you want to delete this Questionnaire? This cannot be undone.",
    "notice_are_you_sure_you_want_to_lock_the_project": "Are you sure you want to lock the project and hide the project details? This action will not affect other Organization Members.",
    "notice_are_you_sure_you_want_to_mark_this_project_as_completed": "Are you sure you want to mark this project as Completed?",
    "notice_are_you_sure_you_want_to_mark_this_submission_as_compliant": "Are you sure you want to mark this submission as Compliant?",
    "notice_are_you_sure_you_want_to_move_back_to_evaluation": "Are you sure you want to move this project back to the Evaluation Stage?",
    "notice_are_you_sure_you_want_to_move_project_back_into_active_projects": "Are you sure you want to move this project back into Active Projects?",
    "notice_are_you_sure_you_want_to_move_this_submission_back_to_active_state": "Are you sure you want to move this Submission back to the Active state?",
    "notice_are_you_sure_you_want_to_release_this_evaluation_group": "Are you sure you want to release this Evaluation Group? This will unseal all documents for this Evaluation Group, auto-score any criteria linked to requested data, and notify Reviewers to begin scoring.",
    "notice_are_you_sure_you_want_to_remove_this_evaluation_group": "Are you sure you want to remove this evaluation group? This cannot be undone.",
    "notice_are_you_sure_you_want_to_replace_the_existing_file_with_the_new_file": "Are you sure you want to replace the existing file with the new file",
    "notice_are_you_sure_you_want_to_replace_the_file": "Are you sure you want to replace the file",
    "notice_are_you_sure_you_want_to_resend_this_invitation": "Are you sure you want to resend this invitation?",
    "notice_are_you_sure_you_want_to_s_this_contract": "Are you sure you want to %s this contract?",
    "notice_are_you_sure_you_want_to_unaward_this_proposal": "Are you sure you want to un-award this proposal?",
    "notice_are_you_sure_you_want_to_unlock_the_project": "Are you sure you want to unlock the project and make the project details visible to you? This action will not affect other Organization Members. The project owner will be notified.",
    "notice_are_you_sure_you_want_to_unrelease_this_evaluation_group": "Are you sure you want to unrelease this Evaluation Group? Reviewers will no longer be able to view Documents or score Criteria.",
    "notice_are_you_sure_you_want_to_unsubmit_your_submission": "Are you sure you want to un-submit your submission?",
    "notice_are_you_sure_you_want_to_update_proposal_names": "Are you sure you want to regenerate the proposal names? This action cannot be undone.",
    "notice_best_value_may_only_release_one_evaluation_group_at_a_time": "In Best Value projects, you may only release one evaluation group at a time.",
    "notice_budget_values_must_be_numeric": "Budget values must be numeric.",
    "notice_cannot_release_because_criteria_dont_sum_to_100": "This project cannot be released because the Criteria weights do not sum to 100%.",
    "notice_cannot_unsubmit_primary_until_unsubmit_alternates": "Primary submissions can't be un-submitted. If you want to delete this submission, please delete the alternate submissions first.",
    "notice_changes_to_requested_info_detected": "Changes to requested information detected.",
    "notice_click_here_to_continue_using_bonfire": "Click here to continue using Bonfire",
    "notice_consensus_scoring_disabled": "Consensus Scoring has been disabled for this project.",
    "notice_correct_the_highlighted_issues": "CORRECT THE HIGHLIGHTED ISSUES BELOW BEFORE SUBMITTING",
    "notice_criteria_imported_successfully": "Criteria imported successfully!",
    "notice_criterion_has_been_deleted": "Criterion has been deleted",
    "notice_delete_this_requested_info": "Are you sure you want to delete this item of Requested Information?",
    "notice_delete_x_this_action_cannot_be_undone": "Are you sure you want to delete this %{x}? This action cannot be undone.",
    "notice_department_was_created": "Department was created!",
    "notice_department_was_deleted": "Department was deleted!",
    "notice_department_was_updated": "Department was updated!",
    "notice_do_you_wish_to_create_a_project_using_similar_attributes": "Do you want to create a new project using this project's Criteria and Requested Information?",
    "notice_do_you_wish_to_duplicate_this_project": "Do you want to duplicate this project? All scores, submissions, criteria, evaluation groups, reviewers, advisors will be copied to the new project.",
    "notice_document_no_longer_requested_please_refresh": "This document is no longer being requested as part of your submission. Please refresh the page to get a new list of the required documents to upload.",
    "notice_domain_has_been_added": "Domain has been added",
    "notice_each_category_must_contain_at_least_one_item_before_creating_decision": "Each category must contain at least one item before creating a decision.",
    "notice_each_criteria_score_is_the_avg_of_all_reviewers": "Each criteria score is an average of all reviewers' scores for that criteria, out of a maximum of 10 points. The overall score is converted to a score out of 100 points.",
    "notice_email_address_not_valid": "Email address not valid!",
    "notice_email_changed_success": "Email changed successfully!",
    "notice_email_must_match": "Must match other email",
    "notice_enable_js": "You need to enable Javascript before continuing. Enabling Javascript is easy: refer to the",
    "notice_enable_js_instructions": "instructions here",
    "notice_enable_js_reload": "and reload this page",
    "notice_error_changing_email": "Sorry, there was an error changing your email. Please try again.",
    "notice_error_changing_password": "Sorry, there was an error changing your password. Please try again.",
    "notice_error_confirming_account": "Sorry, there was an error confirming your account. Please try again.",
    "notice_error_creating_account": "Sorry, there was an error creating your account. Please try again.",
    "notice_error_finalizing_submission": "Sorry, there was an error finalizing your submission. Please ensure that your submission files meet the requirements and try again. If you need further assistance please contact Support@GoBonfire.com.",
    "notice_error_saving_question_score_1": "There was an error saving the score for ",
    "notice_error_saving_question_score_2": ", question # ",
    "notice_error_saving_question_score_3": ". Please refresh the page or try again.",
    "error_initialization_failed": "Initialization of this module has failed",
    "notice_errors_you_may_want_to_fix_errors": "These missing or invalid responses will NOT prevent you from finalizing your submission; however, you may want to correct them and re-upload the file before finalizing your submission.",
    "notice_evaluation_group_has_been_unreleased": "Success! The Evaluation Group has been unreleased.",
    "notice_failure": "Failure.",
    "notice_file_too_large": "File is too large (%s MB max)",
    "notice_file_type_not_allowed": "File type not allowed",
    "notice_file_uploaded_successfully": "The file was uploaded successfully!",
    "notice_found_numItems_keywordBidTable_items": "Found %{numItems} %{keywordBidTable} items. Please match the columns in your imported data.",
    "notice_i_understand_i_cant_change": "I understand that I can't change any of the submission details or documents once the project closes.",
    "notice_import_templates_improved": "We have updated the import template to make it more flexible (e.g. you can insert/delete rows). If you have an older template, you can still use it; however, we recommend downloading and using the new template.",
    "notice_invalid_criteria_type": "Invalid criteria type.",
    "notice_invalid_criteria_weight": "You have entered an invalid criteria point value",
    "notice_invalid_points": "Invalid point value",
    "notice_invalid_reason_set": "Invalid reason set.",
    "notice_invalid_weight": "Invalid weight",
    "notice_invitation_was_cancelled": "Invitation was cancelled!",
    "notice_invitation_was_renewed": "Invitation was renewed!",
    "notice_keywordBidTable_needs_at_least_one_vendor_column_before_it_can_be_requested": "The %{keywordBidTable} needs at least 1 vendor column before it can be requested.",
    "notice_keywordBidTable_needs_total_formula_before_it_can_be_requested": "The %{keywordBidTable} needs a Total formula before it can be requested.",
    "notice_keywordBidTables_cannot_be_added_until": "%{keywordBidTables} cannot be added until the project has been created. Once the project is created, you will be able to create %{keywordBidTables} and add them as Requested Information.",
    "notice_loading_caps": "LOADING",
    "notice_max_file_size": "Each file has a maximum size of 100MB",
    "notice_must_be_at_least_x_chars": "Must be at least %s characters",
    "notice_must_be_valid_email": "Must be a valid email address",
    "notice_must_be_x_chars_or_less": "Must be %s characters or less",
    "notice_must_meet_min_browser_req": "You must meet the minimum system requirements listed above before continuing.",
    "notice_no_advisor_specified": "No Advisors specified - add some below.",
    "notice_no_advisors_yet": "There are currently no advisors.",
    "notice_no_criteria_for_project": "There are no criteria set for this project.",
    "notice_no_document_slots_found_add_some_below": "No document slots found - add some below",
    "notice_no_eval_groups_found_add_some_below": "No Evaluation Groups found - add some below",
    "notice_no_files_available_to_download": "Sorry, there are no files available to download.",
    "notice_no_mandatory_criteria_set": "No mandatory criteria set.",
    "notice_no_project_activty_yet": "No activity yet - add some projects to get started.",
    "notice_no_projects_found": "No projects found!",
    "notice_no_projects_open_at_this_time": "There are no open projects at this time.",
    "notice_no_proposals_submitted_yet": "No proposals have been submitted yet",
    "notice_no_reviewers_specified": "No reviewers specified - add some below.",
    "notice_old_ie_browser_before": "before continuing",
    "notice_only_numbers_ge_0_are_allowed": "Only numbers >= 0 are allowed.",
    "notice_or": "or",
    "notice_orphaned_documents_are_not_part_of_the_submission": "Orphaned documents / data are not included as part of your submission. Please delete and re-upload the content where appropriate.",
    "notice_partial_submissions_can_longer_be_submitted": "Partial submissions can no longer be submitted.",
    "notice_pending_action": "Pending Action",
    "notice_pick_score_value_first": "Pick Score Value First",
    "notice_please_add_at_least_one_evaluation_group_to_manage_criteria": "Please add at least one Evaluation Group in order to manage the Criteria.",
    "notice_please_add_at_least_one_person_to_the_reminder": "Please add at least one person to the reminder.",
    "notice_please_agree_to_the_terms": "Please agree to the terms.",
    "notice_please_choose_a_scoring_reason": "Please choose a scoring reason.",
    "notice_please_choose_an_excel_file": "Please choose an Excel file.",
    "notice_please_enter_a_category_item_name": "Please enter a category item name.",
    "notice_please_enter_a_category_name": "Please enter a category name.",
    "notice_please_enter_a_decision_name": "Please enter a decision name.",
    "notice_please_enter_a_future_date": "Please enter a future date.",
    "notice_please_enter_a_question": "Please enter a question.",
    "notice_please_enter_a_reminder": "Please enter a reminder.",
    "notice_please_enter_a_title": "Please enter a title.",
    "notice_please_enter_a_valid_date": "Please enter a valid date.",
    "notice_please_enter_a_valid_email_address": "Please enter a valid email address",
    "notice_please_enter_questionnaire_title": "Please enter a questionnaire title.",
    "notice_please_enter_reason": "Please enter a Reason.",
    "notice_please_enter_response": "Please enter a response.",
    "notice_please_enter_subset_title": "Please enter a subset title.",
    "notice_please_enter_title_question_set_x": "Please enter a title for question set %s",
    "notice_please_enter_valid_reason": "Please enter a valid Reason.",
    "notice_please_enter_value_between_0_and_x_inclusive": "Please enter a value between 0 and %s inclusive.",
    "notice_please_fix_error_and_try_again": "Please fix the error and try again.",
    "notice_please_provide_a_criteria_group_title": "Please provide a Criteria Group title.",
    "notice_please_provide_a_description_for_criteria": "Please provide a Description for criteria #",
    "notice_please_provide_a_reason": "Please provide a reason.",
    "notice_please_provide_a_title": "Please provide a title.",
    "notice_please_provide_a_title_for_criteria": "Please provide a Title for criteria #",
    "notice_please_provide_a_title_for_each_requested_info": "Please provide a Title for each item of Requested Information",
    "notice_please_provide_a_value": "Please provide a value",
    "notice_please_provide_a_weight_for_criteria": "Please provide a point value for criteria #",
    "notice_please_select_a_department": "Please select a Department.",
    "notice_please_select_a_document_to_amend": "Please select a document to amend.",
    "notice_please_select_a_user": "Please select a user.",
    "notice_please_select_a_user_role": "Please select a User Role.",
    "notice_please_select_an_item_from_drop_down": "Please select an item from the drop-down list.",
    "notice_please_select_at_least_one_supplier_to_send_the_email_to": "Please select at least one supplier to send the email to",
    "notice_please_select_document_type": "Please select a document type.",
    "notice_please_send_private_link_to_vendors": "Please send the complete encrypted link below to any vendors to be invited to bid on this project.",
    "notice_please_set_a_description_for_the_project_it_can_be_changed_later": "Please set a description for the project. It can be changed later.",
    "notice_please_set_a_project_name_before_continuing": "Please set a project name before continuing",
    "notice_please_upgrade_to": "Please upgrade to",
    "notice_powered_by": "Powered by",
    "notice_problems_with_submission_details": "There were the following problems with your submission details",
    "notice_project_close_as_of": "Project closed as of",
    "notice_project_configuration_has_been_cancelled": "Project configuration has been cancelled!",
    "notice_project_is_configured_and_ready_to_go": "Project is configured and ready to go!",
    "notice_projects_without_requested_information_cannot_accept_submissions": "Projects without requested information cannot accept submissions.",
    "notice_proposal_fails_if_unanimous_fail_one_criteria": "For a proposal to receive a 'Fail', it unanimously failed at least 1 of its criteria.",
    "notice_question_set_may_not_contain_more_than_x_questions": "A question set may not contain more than %s questions + subsets.",
    "notice_question_set_x_must_contain_at_least_one_question": "Question Set %s must contain at least 1 question.",
    "notice_questionnaire_can_have_max_x_question_sets": "A questionnaire may not contain more than %s question sets.",
    "notice_questionnaire_must_have_at_least_one_question_set": "A questionnaire must contain at least 1 question set.",
    "notice_questionnaires_cannot_be_added_until": "Questionnaires cannot be added until the project has been created. Once created, you will be able to import Questionnaires and add them as Requested Information.",
    "notice_remove_conflict_of_interest_override": "Are you sure you want to remove this conflict of interest override?",
    "action_remove_this_reviewer_from_the_project": "Remove this reviewer from the project?",
    "notice_requested_documents_file_types": "Requested Documents File Types",
    "notice_reviewer_has_been_added": "Reviewer has been added",
    "notice_reviewer_has_been_changed": "Reviewer has been changed",
    "notice_row_must_be_criterion_or_group": "A row must be either a Criterion or a Criteria Group.",
    "notice_row_must_be_question_or_subset": "A row must be either a Question or a Subset.",
    "notice_s_this_s_this_action_cannot_be_undone": "Are you sure you want to %s this %s? This action cannot be undone.",
    "notice_same_keywordBidTable_may_only_be_requested_once": "The same %{keywordBidTable} may only be requested once.",
    "notice_same_questionnaire_may_only_be_requested_once": "The same questionnaire may only be requested once.",
    "notice_scorecard_alternate_submsision": "You're currently working on an Alternate Submission. As such, you only need to score the %s criteria.",
    "notice_scores_or_notes_have_been_recorded_for_criterion": "Scores and/or Notes have been recorded for this criterion. Are you sure you want to delete it? This action cannot be cancelled later or undone.",
    "notice_seconds_due_to_inactivity": "seconds due to inactivity.",
    "notice_see_no_warnings_youre_good": " Unless you see warnings on the submission pages, your browser meets these requirements.",
    "notice_site_requires_js": "This site was designed to use Javascript, which has been disabled in your browser. ",
    "notice_something_went_wrong": "Something went wrong",
    "notice_sorry_the_page_could_not_be_found": "Sorry! The page you are looking for could not be found.",
    "notice_sorry_we_cant_upload_this_picture": "Sorry, we can't upload this picture due to its file type. Please try another.",
    "notice_sorry_you_cannot_remove_yourself_as_an_org_admin": "Sorry, you cannot remove yourself as an Organization Administrator",
    "notice_start_date_must_be_before_end_date": "The start date must be before the end date.",
    "notice_submission_complete": "Submission Complete",
    "notice_submission_preview_mode": "(Submission button disabled in preview mode)",
    "notice_submissions_are_closed": "Submissions are now closed",
    "notice_submissions_available_after_project_closes": "The submissions will be available after the project closes.",
    "notice_submissions_for_this_project_closed_on_s": "Submissions for this project closed on %s.",
    "notice_subset_must_contain_at_least_one_question": "A Subset must contain at least 1 question.",
    "notice_success": "Success!",
    "notice_success_an_email_has_been_sent_to_s": "Success! An email has been sent to %s",
    "notice_success_an_email_will_be_sent_an_email_when_you_click_finish": "Success! An email will be sent an email when you click Finish",
    "notice_success_criteria_updated": "Success! Criteria updated",
    "notice_success_file_deleted": "Success! File deleted",
    "notice_success_project_draft_has_been_created": "Success! Project Draft has been created.",
    "notice_success_project_info_updated": "Success! Project info updated.",
    "notice_success_project_released": "Success! Project Released",
    "notice_success_requested_info_updated": "Success! Requested Information updated.",
    "notice_success_submission_marked_as_compliant": "Success! Submission marked as Compliant",
    "notice_success_the_new_user_was_sent_an_email_invitation_to_complete_their_account_creation": "Success! The new user was sent an email invitation to complete their account creation",
    "notice_success_the_project_has_been_marked_complete_you_can_find_it_in_the_left_side_menu": "Success! The project has been marked as Completed. You can find it in the left side menu",
    "notice_success_user_has_been_disabled": "Success, user has been disabled",
    "notice_success_user_has_been_enabled": "Success, user has been enabled",
    "notice_success_youve_created_the_project": "Success! You've created the project",
    "notice_that_you_previously_uploaded_with_this_new_file": "that you previously uploaded with this new file",
    "notice_the_budget_high_value_must_be_higher_than_the_budget_low_value": "The Budget-High value must be higher than the Budget-Low value",
    "notice_the_close_date_must_be_before_the_evaluated_by_date": "The Close date must be before the Evaluate By date",
    "notice_the_criteria_weights_add_up_to_over_100": "The criteria weights add up to over 100%",
    "notice_the_criteria_weights_dont_add_up_to_100": "The criteria weights don't add up to 100%",
    "notice_the_deadline_to_submit_your_intent_to_bid_was_date": "The deadline to submit your Intent to Bid was %{date}",
    "notice_the_open_date_must_be_before_the_close_date": "The Open date must be before the Close date",
    "notice_the_project_must_have_an_identifying_reference": "The project must have an identifying Reference #",
    "notice_the_project_was_successfully_deleted": "The project was successfully deleted",
    "notice_the_uploaded_document_does_not_contain_the_correct_number_of_question_sets": "The uploaded document does not contain the correct number of question sets.",
    "notice_the_user_already_has_this_role": "The user already has this role",
    "notice_the_weight_of_each_criterion_must_be_greater_than_zero": "The point value of each criterion must be greater than zero",
    "notice_there_are_criteria_and_documents_being_reviewed_in_this_eval_group": "There are criteria and documents being reviewed in this evaluation group. You'll need to change them to another evaluation group before you can remove this.",
    "notice_there_are_no_open_projects": "There are no open projects at this time.",
    "notice_there_are_some_errors_in_the_value_provided": "There are some errors in the values provided.",
    "notice_there_was_a_problem_with_one_of_your_files": "There was a problem with one of your files. Please try uploading them again.",
    "notice_this_action_will_remove_all_criteria_within_this_criteria_set": "This action will remove all criteria within this criteria set.",
    "notice_this_action_will_remove_the_entire_questionnaire_from_being_scored": "This will remove the entire questionnaire from being scored.",
    "notice_this_action_will_remove_the_question_set_from_being_scored": "This will remove the question set from being scored.",
    "notice_this_column_is_used_in_a_formula_and_cannot_be_deleted": "This column is used in a formula and cannot be deleted.",
    "notice_this_invitation_has_already_been_accpeted": "This invitation has already been accepted.",
    "notice_this_invitation_has_been_cancelled": "This invitation has been cancelled.",
    "notice_this_project_is_not_open_for_proposal_submissions_at_this_time": "This project is not open for proposal submissions at this time.",
    "notice_this_project_requires_you_to_submit_to_decisions": "This project requires you to submit to a specific set of decisions. Please return to Step 1 and select some decisions.",
    "notice_this_site_was_designed_for_use_with_a_modern_browser": "This site was designed for use with a modern browser for security and stability.",
    "notice_this_site_was_designed_to_use_javascript_which_has_been_disabled_in_your_browser": "This site was designed to use Javascript, which has been disabled in your browser",
    "notice_this_user_has_already_been_granted_access_to_this_contract": "This user has already been granted access to this contract.",
    "notice_this_user_has_already_been_invited_to_access_this_contract": "This user has already been invited to access this contract.",
    "notice_this_user_will_has_the_following_work_assigned": "WARNING: This user has the following work assigned.",
    "notice_unsaved_changes_will_be_lost": "All unsaved changes will be lost. Are you sure you want to continue?",
    "notice_upgrade_your_browser": "You are using an <strong>outdated</strong> browser. Please <a href='http://browsehappy.com/' target='_blank'>upgrade your browser</a> to use this feature.",
    "notice_upload_alternate_submsision": "You're currently working on an Alternate Submission. As such, you may only upload for the slots labelled:",
    "notice_upload_cancelled": "The file upload has been cancelled.",
    "notice_upload_error_generic": "Upload error. Please refresh page.",
    "notice_uploaded_responses_contained": "The uploaded file contained",
    "notice_use_a_supported_broweser": "Use a Supported Browser",
    "notice_user_already_has_role": "The user already has this role.",
    "notice_viewing_another_users_scores": "%{scoredByUserFirstName}, you are currently viewing %{scoreUserFirstName} %{scoreUserLastName}'s scores. Click here to unlock the scoring UI. %{scoreUserFirstName} will be notified via email of any changes you make.",
    "notice_warning": "WARNING",
    "notice_warning_changing_multi_category_decision_content_in_open_stage": "Warning: You are changing the Multi-Category Decision content in the Open stage.",
    "notice_warning_changing_multi_category_decision_content_when_submissions_exist": "Warning: You are changing the Multi-Category Decision content and there are in-progress or completed submissions.",
    "notice_warning_changing_requested_info_in_open_stage": "Warning: You are changing the Requested Information from vendors in the Open stage.",
    "notice_warning_changing_requested_info_when_submissions_exist": "Warning: You are changing the Requested Information from vendors and there are in-progress or completed submissions.",
    "notice_we_recommend_you_do_not_add_categories_or_category_items_to_existing_requested_information": "We recommend that you do not add additional categories/category items to existing Requested Information as it may invalidate submission documents/data that have already been uploaded.",
    "notice_we_recommend_you_do_not_change_the_unique_per_alternate_attribute_of_existing_requested_information_from_unique_to_common": "We recommend that you do not change the Unique per Alternate attribute of existing Requested Information from unique to common as it may invalidate submission documents/data that have already been uploaded.",
    "notice_welcome_please_login": "Welcome! Please log in.",
    "notice_working": "Working",
    "notice_x_created_successfully": "The %s was created successfully.",
    "notice_x_has_changed_one_of_your_scores": "%s %s has changed one of your scores for the %s submission.",
    "notice_x_not_found": "%{x} not found",
    "notice_x_not_set": "%{x} not set",
    "notice_you_can_only_invite_users_from_these_domains": "You can only invite users from these domains:",
    "notice_you_can_only_save_1_file": "You can only save 1 file into each document upload slot",
    "notice_you_cannot_delete_this_x_because_there_are_y_associated_with_it": "You cannot delete this %s because there are %s associated with it.",
    "notice_you_cannot_manage_a_proposal_state_at_this_time": "You cannot manage a proposal state at this time",
    "notice_you_cannot_release_this_project": "You cannot release this project",
    "notice_you_cannot_release_this_project_because_its_completed": "This project has been marked as Completed and as such you cannot release documents and criteria to Evaluation Groups. If you wish to release to an Evaluation Group you must first move the project 'Back to Evaluation'.",
    "notice_you_cannot_update_requested_info_at_this_stage_in_project": "Sorry. You can not update the Requested Information at this stage in the Project.",
    "notice_you_do_not_have_permissions_to_complete_this_action": "You do not have permissions to complete this action",
    "notice_you_do_not_have_sufficient_permissions_to_access_this": "Sorry, you do not have sufficient permissions to access this.",
    "notice_you_do_not_have_sufficient_permissions_to_access_this_project": "Sorry, you do not have sufficient permissions to access this project.",
    "notice_you_dont_have_permission_to_edit_this_projects_schedule": "You don't have permission to edit this project's schedule",
    "notice_you_dont_have_sufficient_permissions_to_perform_this_action": "You don't have sufficient permissions to perform this action",
    "notice_you_have_been_logged_out_due_to_inactivity": "You have been logged out due to inactivity.",
    "notice_you_have_not_been_assigned_to_evaluate_this_question": "You have not been assigned to evaluate this question",
    "notice_you_havent_made_any_notes_on_this_proposal": "You haven't made any notes or comments on this proposal yet.",
    "notice_you_may_only_add_x_eval_groups": "You may only add %s Evaluation Groups to a Project.",
    "notice_you_may_only_upload_one_file_for_this_upload_slot": "You may only upload 1 file for this upload slot.",
    "notice_you_must_agree_to_the_above_to_continue": "You must agree to the above to continue.",
    "notice_you_must_enter_some_text_to_save_a_note": "You must enter some text to save a note",
    "notice_you_must_first_submit_the_primary_proposal": "Your primary proposal has not been submitted yet. In order to submit this alternate proposal, you must first submit the primary.",
    "notice_you_must_press_finalize": "You must press the 'Finalize My Submission' button at the bottom of this form by the Closing Time indicated above in order to complete your submission.",
    "notice_you_were_made_owner_of_the_project": "You were made Project Owner of the %{project} Project",
    "notice_you_were_made_owner_of_the_project_draft": "You were made Project Owner of the %{project} Project Draft",
    "notice_you_were_made_owner_of_the_project_template": "You were made Project Owner of the %{project} Project Template",
    "notice_you_will_be_logged_out_in": "You will be logged off in",
    "notice_you_will_see_this_if_dont_meet_min_sys_reqs": "Unless you see warnings at the top of this page, your browser meets these requirements.",
    "notice_your_password_may_not_contain_any_whitespace_at_the_beginning_or_end": "Your password may not contain any whitespace at the beginning or end.",
    "notice_your_project_only_has_x_eval_groups": "Your project only has %s Evaluation Groups. Please add the required Evaluation Groups or change row %s to another group.",
    "notice_your_score_for_criterion_or_question_x_has_been_changed": "Your score for %s %s has been changed.",
    "notice_your_submission_is_not_finalized": "YOUR SUBMISSION IS CURRENTLY NOT FINALIZED",
    "password_must_be_between_x_y_characters": "Password must be between %{min}-%{max} characters",
    "pharse_an_email_will_be_sent_to_all_people_listed_on_this_contract_letting_them_know_the_contract_is_now_status": "An email will be sent to all People listed on this contract letting them know the contract is now %{status}.",
    "phrase_bafo_open_to_responses": "Open to responses",
    "phrase_bafo_closed_to_responses": "Closed to responses",
    "phrase_requires_action": "Requires action",
    "phrase_has_attachment": "Has attachment",
    "phrase_1_day_until_close": "1 day until Close",
    "phrase_access_to_bonfire_are_you_sure_you_want_to_continue": "access to Bonfire. Are you sure you want to continue?",
    "phrase_access_youll_need_to_remove_him_as_a_reviewer": "access you'll need to remove him/her as a reviewer on the above project(s).",
    "phrase_access_youll_need_to_transfer_ownership_of_the_above_project_to_someone_else": "access you'll need to transfer ownership of the above project(s) to someone else.",
    "phrase_action_required": "Action required",
    "phrase_activate_contract_term": "Are you sure you want to activate this term and extend the contract end date?",
    "phrase_add_an": "Add an",
    "phrase_add_an_alternate_option": "Add an alternate option to an existing vendor's proposal.",
    "phrase_add_question_set_to_questionnaire": "Add Question Set to Questionnaire",
    "phrase_after_selecting_a_criteria_to_score": "After selecting a criteria to score",
    "phrase_all_instances_of_vendors_will_be_replaced": "All instances of Vendor 1 and Vendor 2 will be replaced by the Vendor as defined in the middle column.",
    "phrase_all_subsequent_submissions_have_been_eliminated": "All subsequent submissions have been eliminated.",
    "phrase_all_the_projects_reviewers_are_finished": "All the project's reviewers are finished scoring all proposals",
    "phrase_alt_upload": "If you are unable to reach Bonfire Support before the submission deadline, you may upload your files into our <a href='https://dropbox.yousendit.com/BonfireSecureSubmit'>backup secure submission site here</a>",
    "phrase_alternate_decisions_explanation": "You are currently working on an Alternate Submission, so the list of decisions you can select from is limited to the decisions you have selected for your Primary Submission.",
    "phrase_an_email_receipt_has_been_sent_to_you": "A submission receipt has also been emailed to you.",
    "phrase_and": "and",
    "phrase_and_thats_all_you_need_to_know_to_get_started": "... and that's all you need to know to get started!",
    "phrase_are_you_sure_you_want_to_activate_this_response": "Are you sure you want to activate this response? The vendor's current active response will be deactivated, and, if it was selected, it will be deselected.",
    "phrase_are_you_sure_you_want_to_complete_project": "Are you sure you want to Complete this project? It will only be accessible in the Completed section of the site.",
    "phrase_are_you_sure_you_want_to_continue": "Are you sure you want to continue?",
    "phrase_are_you_sure_you_want_to_do_this": "Are you sure you want to do this?",
    "phrase_are_you_sure_you_want_to_eliminate_these_responses": "Are you sure you want to eliminate these responses?",
    "phrase_are_you_sure_you_want_to_include_these_responses": "Are you sure you want to include these responses?",
    "phrase_are_you_sure_you_want_to_remove_the_vendor_from_this_list": "Are you sure you want to remove the vendor from this list?",
    "phrase_as_related_to": "as related to",
    "phrase_ask_a_question": "Ask a question",
    "phrase_associate_your_contract_to_an_existing_project_and_the_winning_submission_for_easy_access": "Associate your contract to an existing project and the winning submission for easy access.",
    "phrase_at_the_front_of_the_url": "at the front of the URL, (2) a small lock icon, (3) a green background color in the address bar, or (4) some combination of these indicators.",
    "phrase_auto_scored_by_s_requested_data": "Auto-scored by %s Requested Data",
    "phrase_auto_scored_when_s_evaluation_group_was_released": "Automatically scored when %s Evaluation Group was released.",
    "phrase_awaiting_processing_by_x": "Awaiting processing by %s.",
    "phrase_baskets_cannot_be_deleted_if_they_have_items": "Baskets cannot be deleted if they have Items in them. You will need to move the items to another Basket first.",
    "phrase_before_being_released_to_the_reviewers_for_scoring": "before being released to the reviewers for scoring. Please review the criteria, reviewers, and other project parameters for completeness before clicking the 'Release' button in the Evaluation Groups section.",
    "phrase_bonfire_account": "Bonfire account",
    "phrase_bonfire_help_center": "Access FAQs, tips, and other helpful articles at the <a href='https://bonfirehub.zendesk.com/hc' target='_blank'>Bonfire Help Center</a>",
    "phrase_bonfire_is_a_simple_web_tool_that_lets_you_paperlessly_manage_and_evaluate_buying_projects_accept_this_invitation_by_clic": "Bonfire is a simple web tool that lets you paperlessly manage and evaluate buying projects. Accept this invitation by clicking below and creating your account",
    "phrase_bonfire_notifications": "Bonfire Notifications",
    "phrase_bonfire_support_team": "Bonfire Support Team",
    "phrase_but_we_need_feedback_from_suppliers_like_you": "but we need feedback from suppliers like you.",
    "phrase_cannot_create_multiple_contracts_per_submission": "Cannot create multiple contracts per submission.",
    "phrase_cannot_submit_until_primary_is_submitted": "You cannot submit until you have completed your primary submission for this project.",
    "phrase_changed_the_dates_for_the": "%s changed the dates for the %s project",
    "phrase_changes_have_been_made_to_requested_info_for_project_x": "Changes have been made to the Requested Information for the following project: %s. Please review the changes carefully.",
    "phrase_changes_have_been_made_to_the_s_project": "Changes have been made to the %s project",
    "phrase_clean_up_this_order_list": "You have selections for submissions that are no longer active. In order to continue we will need to clean up this order list by removing these selections.",
    "phrase_click_a_code_to_drill_down": "Click a code to expand, or search by code or keyword to find codes.",
    "phrase_click_a_conversation_to_see_messages_here": "Click a discussion to see messages here",
    "phrase_click_an_internal_discussion_to_see_messages_here": "Click New Internal Discussion or click a conversation on the left to see message here.",
    "phrase_click_a_public_notice_to_see_messages_here": "Click New Public Notice or click a conversation on the left to see message here.",
    "phrase_click_a_proposal_to_view_its_individual_scores": "Click a proposal to view its individual scores",
    "phrase_click_a_score_above_to_update_it": "Click a score above to update it",
    "phrase_click_and_drag_to_select_an_area_to_crop": "Click and drag on the photo to select an area to crop.",
    "phrase_click_below_to_open_the_order_list_work_surface": "Click below to open the Order List work surface.",
    "phrase_click_below_to_score_questions": "Click below to score the questions for this question set criterion.",
    "phrase_click_below_to_see_changes": "Click the button below to view the project and see the changes.",
    "phrase_click_below_to_view_slash_manage_conflicts_of_interest_for_project": "Click below to view/manage the conflicts of interest for this project.",
    "phrase_click_here_to_add_some": "Click here to add some.",
    "phrase_click_here_to_lock_scoring_ui": "Click here to lock the scoring UI.",
    "phrase_click_on_a_note_to_view_the_page_on_which_it_was_recorded": "Click on a note to view the page on which it was recorded",
    "phrase_click_s_to_download_the_document": "Click %s to download the document.",
    "phrase_click_the_button_below_to_confirm_account": "Click the button below to confirm your account",
    "phrase_click_to_send_an_email_to_x": "Click to send an email to %s.",
    "phrase_comment_on": "Comment on",
    "phrase_confirmation_email_already_sent": "A confirmation email has already been sent.",
    "phrase_confirmation_email_not_sent": "A confirmation email has NOT been sent yet.",
    "phrase_contact_technical_support": "Contact technical support here",
    "phrase_contracts_add_people_permissions_explanation": "<strong>View</strong> allows the user to only view the contract with no editing access.<br><br><strong>Limited Control</strong> grants the user permissions to view this contract and the people related to it; create and edit their own internal tasks, approvals, reminders, and vendor contacts; as well as add files.<br><br><strong>Full Control</strong> gives the user full access to edit this contract.",
    "phrase_contracts_expiring": "contracts expiring",
    "phrase_contracts_starting": "contracts starting",
    "phrase_contracts_with_lead_time_starting_today": "contracts with lead time starting today",
    "phrase_create_categories_before_decisions": "In order to create Decisions you first need to create Categories and Category Items.",
    "phrase_criteria_was_changed_on": "criteria was changed on",
    "phrase_custom_vendor_fields_allow_you_to_ask_additional_information_from_vendors": "Custom Vendor Fields allow you to ask for additional information from Vendors during registration on the Portal.",
    "phrase_days_left_to_evaluate_the": "Only %s days left to evaluate the %s project",
    "phrase_days_to_submit": "days to submit",
    "phrase_days_to_submit_intent_to_bid_for_project": "%{days} days to submit Intent to Bid for %{project}",
    "phrase_decisions_explanation": "When preparing your submission on Bonfire, you will first be asked to select the Decisions you want to submit to. Based on your selections, Bonfire will build the list of Requested Information that you will need to provide.",
    "phrase_denotes_this_user_only_reviews_pricing": "* = Denotes this user only evaluates pricing.",
    "phrase_department_to": "department to",
    "phrase_department_youll_need_to_transfer_ownership_of_the_above_projects": "department you'll need to transfer ownership of the above projects.",
    "phrase_digital_submission_dropbox_please_contact_bonfire_at": "digital submission dropbox. Please contact",
    "phrase_disable": "disable",
    "phrase_do_you_intent_to_bid_on_this_opportunity": "Do you intend to bid on this opportunity?",
    "phrase_do_you_want_to_merge_these_projects": "Do you want to merge the project you selected with the current project? A new project will be created containing all of the information/data from the current project combined with the Project Files, Questionnaires, Evaluation Groups, Requested Information, Criteria, Reviewers, and Advisors from the project you selected.",
    "phrase_download_submission_instructions": "Download the submission instructions in Microsoft Word format",
    "phrase_each_file_has_a_maximum_size_of_100mb_you_will_upload_each_file_individually_into_the_dropbox": "Each file has a maximum size of 100MB. You will upload each file individually into the Dropbox",
    "phrase_edit_an": "Edit an",
    "phrase_email_us_at": "Email us at <a href='mailto:Support@GoBonfire.com'>Support@GoBonfire.com</a>",
    "phrase_enable": "enable",
    "phrase_enable_js": "Have Javascript enabled.",
    "phrase_enabling_javascript_is_easy": "Enabling Javascript is easy",
    "phrase_enabling_js_is_easy": "Enabling Javascript is easy",
    "phrase_enter_as_comma_separated_values": "Enter as comma separated values, e.g. Monday, Tuesday, Wednesday",
    "phrase_enter_as_comma_separated_values_or_leave_blank": "Enter Options as comma separated values (i.e. Monday, Tuesday, Wednesday) to appear as a dropdown. You can also leave this blank for free text entry.",
    "phrase_enter_question_set_title_here": "Enter question set title here",
    "phrase_enter_questionnaire_description_here": "Enter questionnaire description here",
    "phrase_enter_questionnaire_title_here": "Enter questionnaire title here",
    "phrase_enter_your_email": "Enter the email address you created your account with, and we'll send you a link to reset your password.",
    "phrase_error_sending_email_please_refresh_the_page_and_try_again": "Error sending email, please refresh the page and try again.",
    "phrase_evaluated_by_date_must_be_after_close_date": "The Evaluate By date must be after Close Date",
    "phrase_expiring_today": "expiring today",
    "phrase_x_filetypes_are_supported": "%{x} filetypes are supported.",
    "phrase_fill_in_the_table_below": "Fill in the table below",
    "phrase_finish_creating_your_account": "Please finish creating your account so that you will receive notifications when the proposals/bids are ready to be evaluated.",
    "phrase_for_more_techincal_details_regarding_the_requirements_please_see_the": "For more technical details regarding the requirements please see the %s on our %s",
    "phrase_for_page": "for Page",
    "phrase_for_your_convenience_weve_put_together_all_the_details": "For your convenience we've put together all the details you need to prepare your project into four steps. If you haven't figured out all the project details yet you can revist all of these items later on.",
    "phrase_from": "from",
    "phrase_generated_on": "Generated on %s",
    "phrase_got_a_question_or_need_help_email_us": "Have a question or need help using Bonfire? Email us at",
    "phrase_has_made_you_a_buying_agent_in": "has made you a buying agent in",
    "phrase_has_made_you_a_manager_in": "has made you a Manager in",
    "phrase_has_marked_the": "has marked the",
    "phrase_has_updated_your_scores_of_the": "has updated your scores for the",
    "phrase_hasnt_finished_evaluating_the_following_projects": "hasn't finished evaluating the following projects",
    "phrase_here_are_the_old_new_dates": "Here are the old => new dates",
    "phrase_i_s_hereby_agree_to_the_terms": "I, %s, hereby agree to the terms",
    "phrase_if_you_arent_done_evaluating_all_the_proposals": "If you aren't done evaluating all the proposals, please log in and finish",
    "phrase_if_you_believe_this_message_is_in_error_contact_us_at": "If you believe this message is in error, contact us at",
    "phrase_if_you_did_not_perform_this_action_please_contact_support": "If you did not perform this action, please contact Support@GoBonfire.com to secure your account.",
    "phrase_if_you_have_a_question_about_the_project": "If you have a question about the project, please contact the person listed in the RFx document",
    "phrase_if_you_need_help_dont_hesitate_to": "If you need help, don't hesitate to use the %s menu in the bottom left corner.",
    "phrase_if_you_need_to_access_the_proejct_details_you_can_find_them_on_the_portal_listing": "If you need to access the project details you can find them on the Portal Listing.",
    "phrase_if_you_need_to_prepare_an_additional_submission_click_s_to_get_started": "If you need to prepare an additional submission, click %s to get started.",
    "phrase_if_you_require_assitance_or_think_this_is_an_error_please_contact_us_at_s": "If you require assistance or think this is an error please contact us at %s",
    "phrase_if_you_think_theres_an_error_email_support": "If you think there has been an error contact support@gobonfire.com",
    "phrase_if_you_upload_more_than_1_file_into_the_same_upload_slot": "If you upload more than 1 file into the same upload slot, only the last file uploaded will be saved. Combine multiple files before uploading if required.",
    "phrase_if_you_would_like_to_resubmit_please": "If you would like to re-submit, please log in and go to your submission, verify your documentation, and click 'Submit & Finalize'.",
    "phrase_im_ready": "I'm Ready",
    "phrase_import_criteria_step_1": "Step 1: Download the template Excel file and fill it in.",
    "phrase_import_criteria_step_2": "Step 2: Return to this screen and upload the filled in template (.xlsx format only).",
    "phrase_in_addition_we_use_cloudeflare": "In addition we use CloudFlare to protect your website from a range of online threats from spammers to SQL injection to DDOS.",
    "phrase_in_order_to": "In order to",
    "phrase_in_order_to_change": "In order to change",
    "phrase_in_order_to_view_events_please_log_in": "In order to view all Events for this Opportunity, please Log In or Register",
    "phrase_in_order_to_prepare_a_submission_you_must_first_submit_your_intent_to_bid_by_date": "In order to prepare a submission you must first submit your Intent to Bid by %{date}.",
    "phrase_in_order_to_submit_you_must": "In order to submit your proposal using this public portal you must",
    "phrase_in_order_to_view_project_details_or_prepare_submission_you_must_button": "In order to view the Supporting Documentation, Requested Information, and other Project details, or to prepare a submission, you must %{button}",
    "phrase_in_order_to_view_this_content_you_first_need_to_sign_the_s_form": "In order to view this content you first need to sign the %s form.",
    "phrase_incorrect_project_link": "The link you are trying to access is incorrect. Please click on, or copy/paste the link provided in the submission instructions to access the project. If you need further assistance please contact: Support@GoBonfire.com",
    "phrase_invitation_found": "Invitation found.",
    "phrase_invitation_has_expired_for": "Invitation has expired for %s",
    "phrase_invitation_has_expired_please_ask_the_person_who_initially_invited_you_to_do_so_again": "Invitation has expired. Please ask the person who initially invited you to do so again.",
    "phrase_invites_you_to_advise_the": "invites you to advise the",
    "phrase_invites_you_to_review_the": "invites you to review the",
    "phrase_is_the_owner_of_the_following_projects": "is the owner of the following projects",
    "phrase_is_trying_to_accept_your_invitation_but_it_has_expired": "is trying to accept your invitation but it has expired",
    "phrase_its_easy_to_open_the_scorecard_and_record_your_score": "It's easy to open the scorecard and record your score:",
    "phrase_kpi_sparkline_tooltip_fytd": "KPI sparklines start at your FYTD which is currently set to %s.",
    "phrase_language_has_changed_please_refresh": "Language preference has changed successfully. Please refresh the page.",
    "phrase_learn_more_about_x_at_bonfire_help_center_with_uri": "Learn more about %{x} at the <a href='%{uri}' target='_blank'>Bonfire Help Center</a>",
    "phrase_learn_more_about_x_at_the": "Learn more about %{x} at the ",
    "phrase_learn_more_at_bonfire_help_center_with_uri": "Learn more at the <a href='%s' target='_blank' class='underline'>Bonfire Help Center <span class='fa-solid fa-external-link' /><span class='sr-only'>opens in new tab</span></a>",
    "phrase_log_in_or_create_account_to_view_invited_opportunities_after_s": "This invitation is only valid for this email address. Please Log In or Create an Account to view your Invited Opportunities. The opportunity you have been invited to can be accessed after %s.",
    "phrase_log_in_or_create_account_to_view_invited_opportunities_after_domain_s": "This invitation is only valid for this email address or domain. Please Log In or Create an Account to view your Invited Opportunities. The opportunity you have been invited to can be accessed after %s.",
    "phrase_logged_in": "Logged in",
    "phrase_made_you_a_manager_for": "made you a Manager for",
    "phrase_made_you_an_agent_for": "made you an Agent for",
    "phrase_make_sure_you_include_your_name_email": "Make sure you include your name, email and other important details.",
    "phrase_marked_as_awarded_by": "Marked as awarded by",
    "phrase_award_value_over_max": "The award value cannot be over %s. Please edit the amount to be below the limit.",
    "phrase_marking_a_visible_to_public_means": "Marking a document as 'Visible to the public' means that it will be available for download on the portal page for this project while the project is Open. Note that the description of the document will also be shown on the portal.",
    "phrase_max_dimensions_s_px_x_s_px": "Maximum dimensions: %{width}px by %{height}px",
    "phrase_message_for_project": "Message for Project",
    "phrase_message_to_advisors_for_project": "Message to Advisors for Project %s",
    "phrase_message_to_editors_for_project": "Message to Editors for Project %s",
    "phrase_message_to_observers_for_project": "Message to Observers for Project %s",
    "phrase_message_to_reviewers_for_project": "Message to Reviewers for Project %s",
    "phrase_must_contain_between_x_and_y_characters": "Must contain %s-%s characters",
    "phrase_name_sent_a_message_for_project": "%{name} sent a message for %{project}",
    "phrase_need_help": "Need help?",
    "phrase_new_support_request": "New Support Request",
    "phrase_new_to_bonfire_here_a_help_submission_process_video": "New to Bonfire? Here's a quick overview of the submission process.",
    "phrase_no_action_required": "No action required",
    "phrase_no_active_projects": "There are no Active Projects.",
    "phrase_no_comment": "no comment",
    "phrase_no_commment_provided": "No comment provided",
    "phrase_no_commodity_codes": "No commodity codes",
    "phrase_no_completed_projects_found": "No completed projects found!",
    "phrase_no_criteria_found": "No criteria found",
    "phrase_data_submitted": "Data Submitted",
    "phrase_no_data_submitted": "No Data Submitted",
    "phrase_no_documents_available_at_this_time": "No submission documents are available to you at this point in the evaluation.",
    "phrase_no_documents_available_until_close": "No submission documents are available to you until the project closes.",
    "phrase_no_documents_available_until_open": "No submission documents are available to you until the project opens.",
    "phrase_no_documents_submitted": "No documents submitted at this time.",
    "phrase_no_evaluation_groups": "There are no evaluation groups.",
    "phrase_no_invitation_found_try_copying_and_pasting_the_url_from_the_email_into_your_browsers_address_bar": "No invitation found. Try copying and pasting the URL from the email into your browser's address bar.",
    "phrase_no_messages": "No messages",
    "phrase_no_project_drafts": "There are no Project Drafts.",
    "phrase_no_project_files_have_have_been_added": "No Project Files have been added to the project.",
    "phrase_no_questionnaires": "No Questionnaires",
    "phrase_no_questionnaires_found": "There are no questionnaires.",
    "phrase_no_questionnaires_submitted": "No questionnaires submitted at this time.",
    "phrase_no_requested_data": "There are no Requested Data items",
    "phrase_no_requested_documents": "There are no Requested Documents",
    "phrase_no_requested_info": "There are no items of Requested Information",
    "phrase_no_requested_info_in_eval_group": "Warning! People in this Evaluation Group won't be given anything to read / review because none of the Requested Information have been mapped to this Evaluation Group.",
    "phrase_no_requested_information": "There is no Requested Information",
    "phrase_no_requested_questionnaires": "There are no Requested Questionnaires",
    "phrase_no_submissions_have_been_prepared": "No submissions have been prepared.",
    "phrase_no_submissions_have_been_received_yet": "No submissions have been received yet",
    "phrase_no_submissions_have_been_scored_yet": "No submissions have been scored yet",
    "phrase_not_avail_for_online_viewing": "This document is not available for online viewing.",
    "phrase_document_not_accessible": "You do not have sufficient permissions to view this document",
    "phrase_note_departments_cannot_be_deleted_if": "Note: Departments cannot be deleted if there are any users, projects, contracts, or vendor lists assigned to them. You must first delete or re-assign to other departments any users, projects, contracts, or vendor lists that belong to the department.",
    "phrase_note_only": "Note: Only",
    "phrase_note_that_only_submissions_that_have_been_finalized_and_submitted_will_be_considered": "Note that only submissions that have been finalized and submitted will be considered.",
    "phrase_note_that_these_date_changes_immediately_took_effect": "Note that these date changes immediately took effect",
    "phrase_note_the_domains_listed_here_are_used_to_restrict": "Note: The domains listed here are used to restrict who can be invited to create an account (this include Managers, Agents, and Reviewers). Removing a domain will not affect users that have already have accounts, or have been invited.",
    "phrase_note_this_is_how_you_create_an_agent_or_manager": "This is not where you invite a Reviewer. To invite a Reviewer, go to a specific project and click the Manage Reviewers button.",
    "phrase_note_this_reason_is_shared": "Note: This reason is shared with your managers and reviewers on this project.",
    "phrase_note_this_reason_is_shared_with_your_managers": "Note: This reason is shared with your Managers.",
    "phrase_of": "of",
    "phrase_on_page": "on Page",
    "phrase_one_day_to_submit_intent_to_bid_for_project": "1 day to submit Intent to Bid for %{project}",
    "phrase_one_of_your_scores_has_been_changed_on_the": "One of your scores has been changed on the %s project",
    "phrase_only_compliant_submissions_are_viewable": "Only compliant submissions are viewable and scorable by reviewers",
    "phrase_or_click_the_x_to_permanently_dismiss_this_message": "or click the 'x' to permanently dismiss this message.",
    "phrase_organize_your_submission_into_the_following_individual_files_note_the_required_file_types": "Organize your submission into the following individual files (note the required file types)",
    "phrase_orphaned_documents_explanation": "Orphans are files or data that you've uploaded which are no longer associated with an upload slot. This can occur when the project owner makes changes to the requested information, after you have already uploaded some content.",
    "phrase_owner_roles": "Owner Role(s)",
    "phrase_page": "page",
    "phrase_pending_conversion": "Pending conversion",
    "phrase_please_choose_a_primary_scoring_reason": "Please choose a primary scoring reason.",
    "phrase_please_choose_a_score_value": "Please choose a score value.",
    "phrase_please_chose_a_file_to_upload": "Please choose a file to upload.",
    "phrase_please_click_here_for_more_information_about_this_role": "Please click %s for more information about this role.",
    "phrase_please_click_to_view_the_changes": "Please click the button below to view the changes.",
    "phrase_please_ensure_submission_content_meets_new_requirements": "Please ensure that your submission content meets the new requirements.",
    "phrase_please_ensure_the_pricing_input_box": "Please ensure the pricing input box only contains numbers before saving.",
    "phrase_please_enter_a_descriptive_scoring_comment": "Please enter a descriptive scoring comment.",
    "phrase_please_enter_a_reason_for_your_conflict_of_interest": "Please enter a reason for your conflict of interest.",
    "phrase_please_enter_a_valid_email_address": "Please enter a valid email address.",
    "phrase_please_enter_your_first_and_last_name_as_shown_in_the_box": "Please enter your first and last name (as shown) in the signature input box.",
    "phrase_please_fill_in_each_field_before_submitting_your_issue": "Please fill in each field before submitting your issue",
    "phrase_please_fill_out_data_slots_as_described_in_rfx": "Please fill in the following data fields as instructed in the RFx document.",
    "phrase_please_go_back_to_step_1_and_edit_your_data": "Please go back to Step 1 and edit your data.",
    "phrase_please_log_in_to_bonfire_to_view_details": "Please log in to Bonfire to view the details.",
    "phrase_please_login_to_ask_a_question": "Please log in to ask a question.",
    "phrase_please_login_to_download_the_response_template": "Please log in to download the Response Template.",
    "phrase_please_login_to_fill_in_the_s": "Please log in and start your submission to fill in the %s.",
    "phrase_please_note_the_type_and_number_of_files": "Please note the type and number of files allowed. The maximum upload file size is %s MB.",
    "phrase_please_provide_a_reason_for_this_conflict": "Please provide a reason as to the nature of this conflict of interest.",
    "phrase_please_review_changes_to_requested_info": "Please review the changes to the Requested Information below. Any suppliers with finalized or in-progress submissions will be notified via email with the updated list of Requested Information.",
    "phrase_please_save_or_cancel_any_outstanding_contact_information_changes": "Please save or cancel any outstanding changes to the submission contact information.",
    "phrase_please_save_or_cancel_any_outstanding_data_slot_changes": "Please save or cancel any outstanding changes to the required data fields.",
    "phrase_please_select_file_to_amend": "Please select a file to amend...",
    "phrase_please_select_one_or_more_items": "Please select 1 or more items.",
    "phrase_please_select_yes_or_no_for_each_vendor": "Please select yes or no for each vendor.",
    "phrase_please_sign_the_s_form": "Please sign the %s form.",
    "phrase_please_structure_your_submission": "Listed below are the documents and information needed to complete your submission",
    "phrase_please_try_again": "Please try again.",
    "phrase_please_upgrade_to": "Please upgrade to",
    "phrase_prepare_by_entering_new_vendor_info": "Prepare by entering new vendor information: Organization, Name, Email.",
    "phrase_primary_reason_for_your_score": "Primary Reason for Your Score",
    "phrase_profile_picture_has_changed": "Profile picture has changed successfully.",
    "phrase_project_advisors_can_view_project_information": "Project advisors can view supplier submissions for this project and record notes.",
    "phrase_project_editors_can_edit_project_information": "Project editors can edit this project.",
    "phrase_project_details_now_visible_to_organization_members_with_necessary_permissions": "The project details are now visible to all Organization Members that have the necessary permissions to view the project.",
    "phrase_project_is_evaluated": "project is Evaluated",
    "phrase_project_is_now_evaluated": "project is now Evaluated",
    "phrase_project_is_open_and_still_accepting_submissions": "This project is in the Open stage and is still accepting submissions. You will be notified by email when the submissions are ready to be scored.",
    "phrase_project_is_pending_your_review": "%s - %s project is pending your review before evaluators can begin scoring",
    "phrase_project_is_ready_to_be_reviewed": "project is ready to be reviewed",
    "phrase_project_is_scheduled_to_open_tomorrow": "project is scheduled to open tomorrow",
    "phrase_project_is_scheduled_to_open_tomorrow_at": "project is scheduled to open tomorrow at",
    "phrase_project_is_still_protected_for_other_organization_members": "This project is still Protected for other Organization Members.",
    "phrase_project_not_open_for_submissions": "This project is not open for proposal submissions at this time. If you need assistance, please contact: Support@GoBonfire.com",
    "phrase_project_observers_can_observe_project_information": "Project observers can observe this project.",
    "phrase_project_owners_can_control_the_open_close_dates_for_a_project": "Project owners can control the open/close dates for a project, manage the criteria and reviewers, and can generate scoring summaries",
    "phrase_project_requires_your_attention": "project requires your attention!",
    "phrase_project_reviewers_can_view_and_score_the_supplier_submissions": "Project reviewers can view and score the supplier submissions for this project.",
    "phrase_project_staff": "Project staff",
    "phrase_project_view": "Project View",
    "phrase_project_was_received": "project was received",
    "phrase_proposal_submitted_for": "Proposal submitted for",
    "phrase_public_award_notices_explanation": "A public Award Notice will show the vendor's company name on the Public Portal. The reason provided above, however, will not be made public.",
    "phrase_public_files_can_be_downloaded_on_the_portal": "Public files and their descriptions can be viewed and downloaded on the Portal while the Project is Open.",
    "phrase_questionnaire_imported_successfully": "The questionnaire was imported successfully.",
    "phrase_questions_about_min_reqs": "These minimum requirements are in place to ensure a secure and stable submission process. If you have a question about these requirements, please contact",
    "phrase_recorded": "Recorded",
    "phrase_recorded_on": "Recorded on",
    "phrase_refer_to_these_instructions": "refer to these instructions",
    "phrase_remember_you_cannot_bid_on_this_opportunity_unless_you_submit_yes_on_intent_to_bid_by_date": "Remember, you cannot bid on this opportunity unless you submit 'Yes' on Intent to Bid by %{date}.",
    "phrase_reminder_for_contract_s": "Reminder for contract: %s",
    "phrase_request_details": "Request Details",
    "phrase_reviewers_will_be_notified_once_you_release": "Reviewers will be notified once you release the project for viewing/scoring",
    "phrase_s_days_until_close": "%s days until Close",
    "phrase_s_has_invited_you_to_submit_on_s_opportunity": "%s has invited you to submit on their %s opportunity",
    "phrase_s_has_not_signed_this_form": "%s has not signed this form",
    "phrase_s_has_signed_this_form_on_s": "%s has signed this form on %s",
    "phrase_s_has_turned_off_the_protected_attribute_for_s": "%s has turned off the Protected attribute for %s.",
    "phrase_s_has_unlocked_s_and_is_now_able_to_view_details": "%s has unlocked %s and is now able to view the project details.",
    "phrase_s_this_may_take_a_while": "%s...this may take a while",
    "phrase_saved_successfully": "Saved successfully",
    "phrase_saved_vendor_details": "Saved vendor details",
    "phrase_score_on": "Score on",
    "phrase_scoring_summary": "Scoring Summary",
    "phrase_search_by_contract_details": "Search by Contract Name",
    "phrase_search_by_project_ref_owner": "Search by Project, Ref. #, or Owner",
    "phrase_secure_submit": "Email your documents with the Project Reference #, Project Name, and Issuing Organization detailed in the body of the email to <a href='mailto:SecureSubmit@GoBonfire.com'>SecureSubmit@GoBonfire.com</a>.",
    "phrase_security_and_integrity_are_our_num_1_priority": "The security and integrity of supplier and buyer information is our #1 priority. If you'd like more information on Bonfire's security, please contact us at",
    "phrase_see_the_submissions_section_for_details": "see the Submissions section for details",
    "phrase_send_a_message": "Send a message",
    "phrase_sign_your_name_s_below": "Sign your name (%s) below",
    "phrase_so_please_click_through_soon": "so please click through soon",
    "phrase_sorry_you_dont_have_permission_to_export_scoring": "Sorry, you don't have permission to export scoring. You've been logged out as a security measure",
    "phrase_sorry_you_dont_have_permission_to_view_scores_for_this_project_youve_been_logged_out_as_a_security_measure": "Sorry, you don't have permission to view scores for this project. You've been logged out as a security measure",
    "phrase_delete_selected_thread": "Delete Selected Thread",
    "phrase_start_a_new_internal_discussion": "Start a new Internal Discussion",
    "phrase_start_a_new_message_thread": "Start a new message thread",
    "phrase_message_thread_status_opened_update_notification": "Info: This message thread has been opened to new replies.",
    "phrase_message_thread_status_closed_update_notification": "Info: This message thread has been closed to new replies.",
    "phrase_starting_today": "starting today",
    "phrase_submission_as": "submission as",
    "phrase_submission_marked_as_x_for_the_following_project": "A submission was marked as %s for the following project: %s",
    "phrase_submissions_are_awaiting_processing_by": "Submissions are awaiting processing by",
    "phrase_submissions_will_not_be_accepted_after": "Submissions will not be accepted after",
    "phrase_submitted_intent_to_bid": "submitted Intent to Bid",
    "phrase_submitted_successfully": "Submitted Successfully",
    "phrase_success_now_redirect_to_login": "Success! You will now be redirected to the login page.",
    "phrase_supported_min_sys_to_submit": "These minimum requirements are in place to ensure a secure and stable submission process. If you have a question about these requirements, please contact",
    "phrase_survey_link_email_body": "As you have experienced, %s is currently accepting online submissions for their RFx projects through Bonfire. Bonfire would like to hear some feedback from vendors about their experience. The anonymous survey takes only 2 minutes to complete and can be accessed by clicking the link below.",
    "phrase_thank_you_for_your_submission": "Thank you for your submission",
    "phrase_thank_you_for_your_time": "Thank you for your time!",
    "phrase_thanks_for_your_support_request_we_will_contact_you_as_soon_as_possible": "Thanks for your support request - we will contact you as soon as possible!",
    "phrase_the_x_is_expiring_soon_you_set_a_lead_time_of": "The %{x} is expiring soon. You set a Lead Time of %s days before the End Date of %s. This will be your only reminder.",
    "phrase_the_contract_is_now_s": "The contract is now %s.",
    "phrase_the_document_view_lets_you_view_the_supplier_documents": "The Document View lets you view the supplier documents right in your browser / on your tablet - you can navigate quickly and find what you're looking for.",
    "phrase_the_inputs_below_are_custom_attributes_that_have_been_setup_for_this_organization": "The inputs below are custom attributes that have been setup for this organization.",
    "phrase_the_invitation_will_expire_on": "The invitation will expire on",
    "phrase_the_new_user_will_receive_an_email_with_a_link": "The new user will receive an email with a link to create their account. They won't appear as a user in Bonfire until they finish creating their account. View the 'Pending User Invitations' Section to edit the new user's assigned roles.",
    "phrase_the_optimum_value_could_not_be_calculated": "The optimum value could not be calculated.",
    "phrase_the_pricing_score_will_be_automatically_determined": "The pricing score will be automatically determined based on the value entered, relative to all other submissions' pricing",
    "phrase_the_project_has_been_marked_as_completed_scores_can_not_be_added_or_changed": "The project has been marked as %s. Scores can not be added or changed.",
    "phrase_the_project_is_pending_review_by": "The project is pending review by",
    "phrase_the_project_owner_can_control_the_open_close_dates_for_a_project": "The project owner can control the open/close dates for a project, manage the criteria and reviewers, and can generate scoring summaries",
    "phrase_the_project_view_gives_you_an_overview": "The Project View gives you an overview of your project - key dates, the evaluation criteria, and the supplier submissions.",
    "phrase_the_project_will_automatically_open_at_the_specified_date_time": "The project will automatically open at the specified date/time. You may want to double check the requirements before they become available to vendors",
    "phrase_the_reason_provided_was": "The reason provided was",
    "phrase_the_reason_that_was_given": "The reason that was given",
    "phrase_the_response_template_can_be_dl_from_the_bonfire_portal": "The Response Template can be downloaded from the project listing on the Bonfire portal.",
    "phrase_the_s_has_instructions_on_alternative_ways_to_submit": "The %s has instructions on alternative ways of submitting a proposal.",
    "phrase_the_send_email_button_will_generate_an_email_notification": "The 'Send Email' button will generate an email notification to the %s that you select in the table below. This notification will inform them that changes have been made to the project and will contain the message you have provided.",
    "phrase_the_template_designed_to_work_with_all_projects": "The template is designed to work with all Bonfire projects. As a result, your project may not contain the same number of Evaluation Groups as listed in the template.",
    "phrase_the_work_in_progress": "the Work-in-Progress",
    "phrase_there_are_bid_opportunities_that_match": "There are bid opportunities that match your vendor profile.",
    "phrase_there_are_no_advisors_for_this_project": "There are currently no advisors for this project.",
    "phrase_there_are_no_criteria_set_for_this_project": "There are no criteria set for this project",
    "phrase_there_are_no_custom_attributes_for_this_contract_type": "There are no custom attributes for this contract type.",
    "phrase_there_are_no_editors_for_this_project": "There are currently no editors for this project",
    "phrase_there_are_no_matches_for_this_category": "There are no matches for this category.",
    "phrase_there_are_no_observers_for_this_project": "There are currently no observers for this project",
    "phrase_there_are_no_projects_for_you_to_work_on_right_now": "There are no projects for you to work on right now.",
    "phrase_there_are_no_reviewers_for_this_evaluation_group": "There are no reviewers for this Evaluation Group.",
    "phrase_there_are_no_reviewers_for_this_project": "There are currently no reviewers for this project",
    "phrase_there_are_no_submissions": "There are no submissions.",
    "phrase_there_are_too_many_questions_in_the": "There are too many Questions in the '%s' Question Set",
    "phrase_this_action_will_send_an_email": "This action will send an email.",
    "phrase_this_contract_key_has_values_asscoated_with_it": "This contract field has %{numberOfValues} values associated with it.",
    "phrase_this_contract_type_has_items_associated_with_it": "This contract type has %{numberOfContracts} contracts and %{numberOfFields} contract fields associated with it. Deleting this type will remove the types from those resources.",
    "phrase_this_document_is_generated_with_the_dates_and_requested_info": "This document is generated with the dates and requested information you've specified for this project",
    "phrase_this_form_can_not_be_changed": "This form can not be changed.",
    "phrase_this_form_can_not_be_changed_if_you_made_an_error_and_need_to": "This form can not be changed. If you made an error and need to re-submit your %s form contact %s.",
    "phrase_this_group_has_been_released": "This group has been released.",
    "phrase_this_group_is_currently_withheld": "This group is currently withheld.",
    "phrase_this_is_for_tech_issue_refere_questions_to_org_contact": "Note: this form is only for technical issues related to using this web portal. It is NOT for project questions. Please direct those questions to",
    "phrase_this_is_where_the_vendor_can_ask_any_questions": "This is where the vendor can ask any questions they have related to the project.",
    "phrase_to_see_these_projects_go_to_the_projects_page": "To see these questions, go to the Project's page.",
    "phrase_this_is_where_the_vendor_can_ask_any_questions_they_have": "This is where the vendor can ask any questions they have related to the project. To see these questions, go to the Project's page.",
    "phrase_this_is_where_your_internal_discussions_will_appear": "This is where your internal discussions will appear.",
    "phrase_this_is_where_your_public_notices_will_appear": "This is where your public notices will appear.",
    "phrase_this_is_where_your_messages_will_appear": "This is where your messages will appear.",
    "phrase_this_key_has_been_deleted": "This key has been deleted by the organization and is no longer required.",
    "phrase_this_link_has_expired_please_go_to_settings_and_resend_email": "This link has expired. Please go to the settings page and resend the confirm account email.",
    "phrase_this_link_will_expire_date": "This confirmation expires %{date}.",
    "phrase_this_means_you_wont_recieve_new_opportunity_notifications": "This means you won't receive new opportunity notifications.",
    "phrase_this_portal_is_secured_with": "This portal is secured with 256-bit SSL encryption, the same level of encryption that is used to secure online banking and other online financial services.",
    "phrase_this_project_allows_you_to_submit_up_to_s_alternate": "This project allows you to submit up to %s alternate options. If you elect to submit an alternate option you will be asked to only submit the Requested Information that is designated as 'Unique per Alternate'.",
    "phrase_this_project_does_not_accept_submissions": "This project does not accept submissions.",
    "phrase_this_project_has_multiple_decisions": "This project has multiple Decisions that you can submit to listed below:",
    "phrase_this_report_is_confidential_and_should_not_be_shared_outside_orgname": "This report is highly confidential and must not be shared or discussed with anyone outside of",
    "phrase_this_score_change_was_triggered_by_s_s": "This score change was triggered by %s %s",
    "phrase_this_score_was_deleted_by_s_s": "This score was deleted by %s %s",
    "phrase_this_score_was_updated_by_s_s": "This score was updated by %s %s",
    "phrase_this_site_was_designed_for_use_with_a_modern_browser": "This site was designed for use with a modern browser",
    "phrase_this_ssl_encryption_creates_an_end_to_end": "This SSL encryption creates an end-to-end encrypted 'channel' between your computer and our servers - any information (text and files) that you upload can't be intercepted or read by a third-party.",
    "phrase_this_value_has_been_hidden_best_value": "This value has been hidden because this project is using the best value process.",
    "phrase_to_accept_this_invitation_click_below_and_create_your_account": "To accept this invitation, click below and create your account.",
    "phrase_to_download_files_login_or_register_for_a_bonfire_account": "To download files, Log in or Register for a Bonfire Account.",
    "phrase_to_prepare_an_additional_submission": "To prepare an additional submission, return the the Portal Listing page.",
    "phrase_to_see_the_changes_click_the_button_below_to_view_the_project": "To see the changes, click the button below to view the project. The changes are visible in your scorecard",
    "phrase_today_is_the_deadline_for_evaluations_for_the": "Today is the deadline for evaluations for the %s project",
    "phrase_tried_to_accept_their_invitation_after_it_expired": "tried to accept their invitation after it expired. To renew their invite log in and click the Resend Invite button in the Reviewers section next to their name. If you want to cancel their invitation, click the 'Cancel' button in the Reviewers section.",
    "phrase_type_your_note_here": "Type your note here...",
    "phrase_type_your_text_here": "Type your %{text} here",
    "phrase_unfortunately": "Unfortunately",
    "phrase_unfortunately_your_browser_does_not_support_the_functions": "Unfortunately your browser does not support the functions needed for this feature. Please upgrade to a modern browser.",
    "phrase_unreleased_criteria": "Greyed out criteria belong to a different evaluation stage. A notification will be sent out when these criteria are released for review.",
    "phrase_upload_electronic_proposals_and_documents_to": "Upload Electronic Proposals and Documents To",
    "phrase_upload_num_no_bid_responses_part_1": "The uploaded file contained",
    "phrase_upload_num_no_bid_responses_part_2": "No Bid responses.",
    "phrase_upload_your_files_instructions": "Click Upload File to add a requested document. Please note the type and number of files allowed for each requested document. The maximum file size accepted is %s MB.",
    "phrase_uploading_file": "Uploading file",
    "phrase_uploading_large_documents_may_take_significant_time": "Uploading large documents may take significant time, depending on the size of the file(s) and your Internet connection speed. Allocate sufficient time for all uploads to complete prior to",
    "phrase_vendor_awards_explanation": "To mark a vendor as awarded, select 'Award' from the Actions menu in the Submissions tab of the Vendors section.",
    "phrase_vendor_will_not_be_emailed": "This contact information is for internal record keeping purposes only, the vendor <strong>will not</strong> be sent a receipt upon uploading their submission.",
    "phrase_vendor_is_required_to_complete_registration": "Additional information is required in order to complete your registration.",
    "phrase_view_the_contract": "View the contract",
    "phrase_view_the_opportunity": "View the opportunity",
    "phrase_view_the_project": "View the project",
    "phrase_watch_our_training_video_link": "Watch our <a href='https://bonfirehub.zendesk.com/hc/en-us/articles/201896553-Evaluating-a-project-in-Bonfire-for-Evaluators-' target='_blank'>short training video</a> to help you get started.",
    "phrase_we_are_continually_improving_the_digitial_submission_process_at": "We are continually improving the digitial submission process at",
    "phrase_we_have_also_sent_you_and_email_receipt_containing_the_above_information": "We have also sent you and email receipt containing the above information for this submission, you should receive it shortly.",
    "phrase_we_need_your_feedback": "We Need Your Feedback!",
    "phrase_we_take_feedback_seriously": "Bonfire takes feedback very seriously and uses it to improve your experience on future submissions.",
    "phrase_we_will_respond_to_you_shortly": "We will respond to you shortly!",
    "phrase_welcome_nothing_to_access": "Welcome! It appears there isn't anything here for you to access.",
    "phrase_what_can_we_help_you_with": "What can we help you with?",
    "phrase_when_preparing_your_submission_on_bonfire": "When preparing your submission on Bonfire you will first be asked to select which Decisions you are going to submit to. Based on what you choose Bonfire will build a list of Requested Information that you need to provide.",
    "phrase_will_be_notified_via_email_of_any_changes": "will be notified via email of any changes you make",
    "phrase_wip_submission": "Work-In-Progress submission",
    "phrase_wip_submissions_explaination": "Work-In-Progress submissions are submissions that have been prepared but have not yet been finalized. As such, these submissions are not included in the evaluation but are shown here for your reference.",
    "phrase_with_lead_time_starting_today": "with lead time starting today",
    "phrase_x_has_declared_a_conflict_of_interest_for_project_y": "%s has declared a conflict of interest for project %s",
    "phrase_yes_no_maybe_or_leave_blank": "Yes, No, Maybe; or leave blank",
    "phrase_you_are_a_reviewer_on_the_project": "You are a reviewer on the project",
    "phrase_you_are_about_to": "You are about to",
    "phrase_you_are_about_to_change": "You are about to change",
    "phrase_you_are_currently_viewing": "you are currently viewing",
    "phrase_you_are_no_longer_an_advisor_of_the": "You are no longer an advisor of the %s project",
    "phrase_you_are_no_longer_an_editor_of_the": "You are no longer an editor of the %s project",
    "phrase_you_are_no_longer_an_observer_of_the": "You are no longer an observer of the %s project",
    "phrase_you_are_no_longer_a_reviewer_of_the": "You are no longer a reviewer of the %s project",
    "phrase_you_are_no_longer_owner_of_the": "You are no longer Project Owner of the %s Project",
    "phrase_you_are_no_longer_project_owner_for_the": "You are no longer project owner for the %s project",
    "phrase_you_asked": "You asked",
    "phrase_you_can_also_record_your_scores": "You can also record your scores, write private notes, and download the documents.",
    "phrase_you_can_only_upload_one_file_into_each_document_upload_slot": "You can only upload ONE file into each document upload slot. ",
    "phrase_you_can_see_evidence_of_ssl": "You can see evidence of this secure SSL connection in your address bar in all modern browsers - look for either",
    "phrase_you_cannot_access_the_content_for_this_project_please_review_s": "You cannot access the content for this project. Please review your %s",
    "phrase_you_cannot_prepare_a_submission_because_you_did_not_submit_your_intent_to_bid_by_date": "You cannot prepare a submission because you did not submit your Intent to Bid by %{date}.",
    "phrase_you_can_enter_custom_message": "You can enter an optional custom message to be included in the invite email (2000 character maximum length)",
    "phrase_you_dont_have_any_associated_commodity_codes": "Hey! You don't have any associated Commodity Codes.",
    "phrase_explain_commodity_codes": "Commodity Codes are used to match vendors and opportunities.",
    "phrase_you_have_1_new_message": "You have 1 new message",
    "phrase_you_have_been_granted_the_following_user_role_in_bonfire": "You have been granted the following User Role in Bonfire: %s",
    "phrase_you_have_been_made_a_x_in_the_x_department": "You have been made a %s in the %s Department.",
    "phrase_you_have_been_made_a_x_on_the_x_x": "You have been granted the role of %s on the %s %s.",
    "phrase_you_have_been_made_a_x_on_x_x": "You have been granted the role of %s on %s %s.",
    "phrase_you_have_been_made_an_x_in_the_x_organization": "You have been made an %s in the %s Organization.",
    "phrase_you_have_been_removed_from_the_following_user_role_in_bonfire": "You have been removed from the following User Role in Bonfire: %s",
    "phrase_you_have_been_removed_from_all_roles_in_x_contracts": "You have been removed from all roles in %s Contract(s)",
    "phrase_you_have_been_removed_from_the_role_of_x_in_the_x_department": "You have been removed from the role of %s in the %s Department.",
    "phrase_you_have_been_removed_from_the_role_of_x_in_the_x_organization": "You have been removed from the role of %s in the %s Organization.",
    "phrase_you_have_been_removed_from_the_role_of_x_in_the_x_x": "You have been removed from the role of %s on the %s %s.",
    "phrase_you_have_been_removed_from_the_role_of_x_in_x_x": "You have been removed from the role of %s on %s %s.",
    "phrase_you_have_been_removed_from_all_roles_in_x_x": "You have been removed from all roles on %s %s.",
    "phrase_you_have_declared_a_conflict_of_interest_on_this_project_as_such_you_cannot_access_s": "You have either declared a %s on this project or have not signed off on all of the vendors, as such you cannot access the %s.",
    "phrase_you_have_new_messages": "You have %s new messages",
    "phrase_you_have_no_criteria_to_review": "No project criteria are available to you at this point in the evaluation.",
    "phrase_you_have_other_wip_submissions": "You have another Work-in-Progress submission for this project that needs to be completed.",
    "phrase_you_have_s_other_wip_submissions": "You have %s other Work-in-Progress submissions for this project that need to be completed.",
    "phrase_you_havent_made_any_notes_or_comments_on_this_proposal_yet": "You haven't made any notes or comments on this proposal yet",
    "phrase_you_may_override_this_users_conflict_of_interest": "You may override this user's conflict of interest without requiring them to re-sign.",
    "phrase_you_may_want_to_notify_suppliers_or_dismiss": "You may want to distribute updated Submission Instructions and 'Notify Suppliers via Public Notices' to review the Project Details and Requested Information listed on the portal.",
    "phrase_you_must_agree_to_the_terms": "You must agree to the terms.",
    "phrase_you_only_have": "You only have %s days left to finish evaluating the proposals. Your evaluation must be completed by %s.",
    "phrase_you_only_have_days_to_submit_your_intent_to_bid_for_project": "You only have %{days} days to submit your Intent to Bid for %{project}.",
    "phrase_you_only_have_one_day_to_submit_your_intent_to_bid_for_project": "You only have 1 day to submit your Intent to Bid for %{project}.",
    "phrase_you_were_made_a_reviewer_for_the": "You were made a reviewer for the",
    "phrase_you_were_made_an_advisor_of_the": "You were made an advisor of the %s project",
    "phrase_you_were_made_an_editor_of_the": "You were made an editor of the %s project",
    "phrase_you_were_made_an_observer_of_the": "You were made an observer of the %s project",
    "phrase_you_were_made_a_reviewer_of_the": "You were made a reviewer of the %s project",
    "phrase_you_will_be_notified_by_email_when_they_are_ready_to_be_scored": "You will be notified by email when the submissions are ready to be scored",
    "phrase_you_will_be_notified_when_supplier_submissions_are": "You will be notified when supplier submissions are available to view and score.",
    "phrase_you_will_be_required_to_download_and_respond_to_questionnaires": "You will be required to download and respond to the questions in the following questionnaires",
    "phrase_you_will_be_required_to_enter_the_following_data": "You will be required to fill in the following data fields as instructed in the RFx document",
    "phrase_you_will_need_to_fill_out_the_s_response_template": "You will need to fill out the provided Response Template for this %s.",
    "phrase_you_will_need_to_complete_the_BidTable_online_in_the_browser": "You will need to complete the %s online in the browser. The %s can be filled any time during your submission.",
    "phrase_you_will_receive_an_email_confirmation_receipt": "You will receive an email confirmation receipt after all files have been uploaded, and you have finalized your submission",
    "phrase_you_will_receive_an_email_with_further_instructions": "You’ll receive an email with further instructions.",
    "phrase_your_account_has_not_been_confirmed": "Your account has not been confirmed. To confirm your account, press the Confirm button, and an email will be sent to you with further instructions.",
    "phrase_your_request_for_support_has_been_received_and_weve_emailed_you_a_copy_of_your_question_we_will_contact_you_shortly": "Your request for support has been received, and we've emailed you a copy of your question. We will contact you shortly",
    "phrase_your_s_submission_for_the_s_project_has_been_withdrawn": "Your %s submission for the %s project has been withdrawn.",
    "phrase_your_score_for_the": "Your score for the",
    "phrase_your_scoring_comment": "Your Scoring Comment",
    "phrase_your_submission_for_the": "Your submission for the",
    "phrase_your_submission_has_been_finalized": "Your submission has been finalized. Please see below for your confirmation details.",
    "phrase_your_submission_must_be_uploaded_prior_to_the_closing_time_of": "Your submission must be uploaded, submitted, and finalized prior to the Closing Time of",
    "phrase_youre_currently_logged_in_with_an_account_that_cannot_submit_proposals": "You're currently logged in with an account that cannot submit proposals. In order to prepare a submission Logout, then Register as a Vendor.",
    "phrase_youve_been_as_a_reviewer_invited_by": "You've been invited to be a reviewer / evaluator of the proposals/bids by",
    "placeholder_select_a_thing": "Select a %{thing}",
    "placeholder_select_an_thing": "Select an %{thing}",
    "project_timeline_five_day_eval": "An email will be sent to all reviewers to remind them evaluations are due in five days",
    "project_timeline_two_day_eval": "An email will be sent to all reviewers to remind them evaluations are due in two days",
    "project_timeline_zero_day_eval": "An email will be sent to all reviewers to remind them evaluations are due today",
    "project_timeline_open_notify": "An email will be sent to the Project Owner to remind them that the project opens in one day ",
    "project_timeline_open_state": "This project will open for submissions on %{date}",
    "project_timeline_completed_state": "This project's evaluation is scheduled to be completed on %{date}",
    "project_timeline_question_tooltip": "Vendor Questions are due on %{date}",
    "project_timeline_intent_tooltip": "Vendor Intent to Bid statements are due on %{date}",
    "project_timeline_evaluating_state": "This project will move to the evaluating stage on %{date}",
    "project_timeline_close_state": "This project will be closed for submissions on %{date}",
    "project_timeline_five_day_eval_past": "An email was sent to all reviewers to remind them evaluations were due in five days",
    "project_timeline_two_day_eval_past": "An email was sent to all reviewers to remind them evaluations were due in two days",
    "project_timeline_zero_day_eval_past": "An email was sent to all reviewers to remind them evaluations were due today",
    "project_timeline_open_notify_past": "An email was sent to the Project Owner to remind them that the project opened in one day ",
    "project_timeline_open_state_past": "This project opened for submissions on %{date}",
    "project_timeline_completed_state_past": "This project's evaluation was completed on %{date}",
    "project_timeline_question_tooltip_past": "Vendor Questions were due on %{date}",
    "project_timeline_intent_tooltip_past": "Vendor Intent to Bid statements were due on %{date}",
    "project_timeline_evaluating_state_past": "This project moved to the evaluating stage on %{date}",
    "project_timeline_close_state_past": "This project closed for submissions on %{date}",
    "project_timeline_public_notice": "Public Notice '%{name}' was created on %{date}",
    "project_timeline_public_document": "Public Document '%{name}' was created on %{date}",
    "project_timeline_vendor_download": "%{name} downloaded '%{filename}' on %{date}",
    "project_timeline_completed_submission": "%{name} finalized their submission on %{date}",
    "project_timeline_started_submission": "%{name} began filling out their submission on %{date}",
    "project_timeline_multiple_submissions": "Multiple Submissions",
    "project_timeline_reviewer_score": "%{name} created %{length} scores from %{start} to %{end}",
    "project_timeline_consensus_score": "%{length} consensus scores were created from %{start} to %{end}",
    "questionnaire_import_instructions_a_question_set_may_contain_at_most_x_questions_plus_subsets": "A question set may contain at most %s questions + subsets. Do not insert additional rows.",
    "questionnaire_import_instructions_a_valid_question_requires_some_content": "A valid question requires some content and a point value. You may select a reason set from the dropdown if desired (see reason set table below).",
    "questionnaire_import_instructions_fill_in_the_questionnaire_details_above": "Fill in the questionnaire details above.",
    "questionnaire_import_instructions_for_each_question_set_that_you": "For each question set that you want to include in the questionnaire, fill in the question set title and enter/paste (without formatting) your questions in the provided table.",
    "questionnaire_import_instructions_if_you_need_help_contact": "If you need help, contact us at: Support@GoBonfire.com",
    "questionnaire_import_instructions_if_you_require_additional_question_sets": "If you require additional question sets, create a copy of one of the question set worksheets and move it to the end of the workbook.",
    "questionnaire_import_instructions_note_this_template_is_not": "Note: This template is not the file you share with suppliers! It is only used to import your questions.",
    "questionnaire_import_instructions_please_do_not_change_the_structure": "Please do not change the structure of this template. Changing the structure will cause the import to fail.",
    "questionnaire_import_instructions_the_remaining_worksheets_in_this_workbook": "The remaining worksheets in this workbook represent question sets.",
    "questionnaire_import_instructions_you_can_group_questions_into_subsets": "You can group questions into subsets by selecting 'Subset' from the dropdown in the first column and filling in the subset title. All questions entered below a subset row belong to that subset. A subset must contain at least one question.",
    "submission_instructions_at_least_one": "at least ONE (1) day",
    "submission_instructions_before_closing_time_to_begin_the_uploading": "before Closing Time to begin the uploading process and to finalize your submission.",
    "submission_instructions_each_file_has_a_maximum_size": "Each Requested Document has a maximum size of 100MB. Any Requested Document exceeding this limit will not be accepted.",
    "submission_instructions_each_submission_file_uploaded": "Each item of Requested Information will only be visible after the Closing Time.",
    "submission_instructions_for_technical_questions_related": "for technical questions related to your submission. You can also visit their help forum at",
    "submission_instructions_if_you_upload_more_than_one": "If you upload more than one file into the same slot, the previous file will be overwritten.",
    "submission_instructions_important_notes": "Important Notes:",
    "submission_instructions_in_order_to_submit_you_will_also": "In order to submit you will be required to fill in the following data fields (note the data types):",
    "submission_instructions_minimum_system_requirements": "Minimum system requirements: Microsoft Edge, Google Chrome, or Mozilla Firefox. Javascript must be enabled. Browser cookies must be enabled.",
    "submission_instructions_need_help": "Need Help?",
    "submission_instructions_only_one": "only ONE (1) file can be uploaded for each Requested Document above.",
    "submission_instructions_please_do_not_embed_any_documents": "Please do not embed any documents within your uploaded files, as they will not be accessible or evaluated.",
    "submission_instructions_please_follow_these_instructions": "Please follow these instructions to submit via our Public Portal.",
    "submission_instructions_please_note_that": "Please note that ",
    "submission_instructions_please_note_that_s_may_take_a_significant_amount_of_time_to_prepare": "Please note that %s may take a significant amount of time to prepare.",
    "submission_instructions_please_note_that_text_fields": "Please note that text fields have a limit of %s characters.",
    "submission_instructions_prepare_your_submission_materials": "1. Prepare your submission materials:",
    "submission_instructions_the_s_response_templates_can_be_obtained_at_": "The %s Response Templates can be obtained at ",
    "submission_instructions_upload_your_submission_at": "2. Upload your submission at: ",
    "submission_instructions_uploading_large_documents_may_take": "Uploading large documents may take significant time, depending on the size of the file(s) and your Internet connection speed.",
    "submission_instructions_uses_a_BonfireHub_portal_for_accepting_and_evaluating": "uses a Bonfire portal for accepting and evaluating proposals digitally. Please contact Bonfire at",
    "submission_instructions_we_recommend_you_prepare_your_responses_in_advance": "We recommend you prepare your responses in advance to ensure they fit within the length restrictions.",
    "submission_instructions_we_strongly_recommend_that_you": "We strongly recommend that you give yourself sufficient time and",
    "submission_instructions_you_must_answer_each_question_in_the_questionnaire_individually": "You must answer each Question in the Questionnaire individually.",
    "submission_instructions_you_will_be_notified_by_email_when_you_receive_a_response": "You will be notified by email when you receive a response.",
    "submission_instructions_you_will_not_be_able_to_prepare_a_submission_unless_you_submit_your_intent_to_bid_by": "You will not be able to prepare a submission unless you submit 'Yes' for your Intent to Bid by",
    "submission_instructions_you_will_receive_an_email_confirmation": "You will receive an email confirmation receipt with a unique confirmation number once you finalize your submission.",
    "title_account_access": "Account Access",
    "title_all_questions": "All Questions",
    "title_ammend_proposalname": "Amend %s",
    "title_award_proposal": "Mark Proposal as Awarded",
    "title_awarded_projects": "Awarded Projects",
    "title_bonfire_import_criteria_template": "Bonfire Import Criteria Template",
    "title_bonfire_import_questionnaire_template": "Bonfire Import Questionnaire Template",
    "title_bonfire_reminder": "Bonfire Reminder",
    "title_change_department": "Change Department",
    "title_complete_your_submission": "Complete Your Submission",
    "title_conflict_of_interest": "Conflict of Interest",
    "title_conflict_of_interest_override": "Conflict of Interest Override",
    "title_contract_access": "Contract Access",
    "title_create_a_new_project": "Create a New Project",
    "title_create_agent_manager": "Create Agent / Manager",
    "title_create_contract_document": "Create Contract Document",
    "title_create_contract_role": "Create Contract Role",
    "title_create_department": "Create Department",
    "title_create_project_file": "Create Project File",
    "title_create_reminder": "Create Reminder",
    "title_criterion_slash_group": "Criterion/Group",
    "title_delete_department": "Delete Department",
    "title_edit_contract_document": "Edit Contract Document",
    "title_edit_contract_role": "Edit Contract Role",
    "title_edit_department": "Edit Department",
    "title_edit_project_file": "Edit Project File",
    "title_edit_reminder": "Edit Reminder",
    "title_edit_schedule": "Edit Schedule",
    "title_edit_user_invitation_roles": "Edit User Invitation Roles",
    "title_edit_user_roles": "Edit User Roles",
    "title_edit_vendor_record": "Edit Vendor Record",
    "title_edit_vendor_records": "Edit Vendor Records",
    "title_eliminate_submission": "Eliminate Submission",
    "title_email_sent": "Email Sent",
    "title_error": "Error",
    "title_getting_help": "Getting Help",
    "title_help_request": "HELP REQUEST",
    "title_internal_discussion": "Internal Discussion",
    "title_import_criteria": "Import Criteria",
    "title_import_criteria_template": "Import Criteria Template",
    "title_import_questionnaire_template": "Import Questionnaire Template",
    "title_importing_questionnaires": "Importing Questionnaires",
    "title_keywordBidTable_response_template": "%{keywordBidTable} Response Template",
    "title_login": "Login",
    "title_manage": "Manage",
    "title_manage_advisors": "Manage Advisors",
    "title_manage_criteria": "Manage Criteria",
    "title_manage_document_slots": "Manage Document Slots",
    "title_manage_editors": "Manage Editors",
    "title_manage_observers": "Manage Observers",
    "title_manage_reviewers": "Manage Reviewers",
    "title_message_thread_subtype": "Message Thread Subtype",
    "title_message_thread_type": "Message Thread Type",
    "title_multi_category_decisions": "Multi-Category Decisions",
    "title_new_support_request": "New Support Request",
    "title_notes": "Notes",
    "title_percent_complete": "% Complete",
    "title_project_closed": "Project Closed",
    "title_question_set": "Question Set",
    "title_question_set_scoring_summary": "Question Set Scoring Summary",
    "title_question_set_x": "Question Set %s",
    "title_question_sets": "Question Sets",
    "title_question_slash_subset": "Question/Subset",
    "title_questionnaire_details": "Questionnaire Details",
    "title_questionnaire_response_template": "Questionnaire Response Template",
    "title_reason_sets": "Reason Sets",
    "title_requested_from_vendors": "Requested From Vendors",
    "title_reset_your_password": "Reset Your Password",
    "title_score_updates": "Score Updates",
    "title_scorecard_for": "Scorecard for",
    "title_submission_amendments": "Submission Amendments",
    "title_submission_instructions": "Submission Instructions",
    "title_submission_receipt": "Submission Receipt",
    "title_support": "Support",
    "title_the_getting_started_tour": "The Getting Started Tour",
    "title_thoughts_on_esubmission": "Thoughts on your eSubmission to %s?",
    "title_vendor_award": "Vendor Award",
    "title_work_in_progress": "Work-in-Progress",
    "title_work_in_progress_s": "Work-in-Progress %s",
    "tooltip_allow_domain_match": "Domain Match will also allow access to the domains of the people you are inviting. For example mike@example.com with Domain Match would also allow anyone at @example.com",
    "tooltip_coi_not_applicable": "The Conflict of Interest declaration is not applicable for this project because the vendor submissions have been anonymized",
    "tooltip_contract_reminder": "Only users that have been explicitly granted access to this contract can be added to a reminder.",
    "tooltip_dataslot_type_bool": "True/False inputs allow you to choose either true or false",
    "tooltip_dataslot_type_yes_no": "Yes/No inputs allow you to choose either Yes or No",
    "tooltip_dataslot_type_number": "Number inputs require that you enter a valid number with only digits, and optionally a decimal. (For example 1000.50).",
    "tooltip_dataslot_type_text": "Text inputs allow you to write plain text.",
    "tooltip_domain_name": "Domain Name Match allows you to grant access others users at the vendor's organization beyond the person you initially invited. Note: Domain Name Matching is only relevant for Invite-Only opportunities.",
    "tooltip_email_address_or_domain_name": "An example of a valid email address would be john@example.com. An example of a valid domain name would be @example.com.",
    "tooltip_external_submission": "External Submissions are submissions that were created by vendors that registered and prepared via the portal.",
    "tooltip_grant_access_to_contract": "To add an existing user, select the user from the dropdown list. To invite a new user, type the new user's email address and press enter.",
    "tooltip_import_questionnaire_first": "You must import a questionnaire into Bonfire before adding a Requested Questionnaire.",
    "tooltip_input_description": "Input: Allows the evaluator to enter any value between 0 and the point weighting of the criteria",
    "tooltip_internal_submission": "Internal Submissions are submissions that were created by the Project Owner or other internal staff.",
    "tooltip_invalid_selection": "There is an invalid vendor selected for this Item. You can resolve by opening the Order List and running the cleanup function.",
    "tooltip_lead_time": "Lead Time (Days) is the number of days before a contract term expires. An auto-reminder email is sent out on this date. If Lead Time (Days) is set to 0, no auto-reminder is sent.",
    "tooltip_locked_projects": "In a <strong>Locked Project</strong>, the project details are hidden from Organization Members that are not directly involved in the project but would normally have the necessary permissions to see the project details (Organization Administrators, Department Managers, Project Managers). These users have the ability to view the project details if required, but the Project Owner will be notified if they do so.",
    "tooltip_multiple_files_requested_info": "Vendors may upload more than one file per Requested Document.",
    "tooltip_people_advisors": "Advisors are users that can only view projects (and the accompanying proposals) that they have been invited to. They cannot score, edit, or otherwise do anything with the project; they are merely observers who can view project details, and can be given access to vendor submissions.",
    "tooltip_people_editors": "An Editor can view all components of a Project Draft. Editors can make changes to the project however the cannot publish a Draft.",
    "tooltip_people_observers": "An Observer can view all components of a Project Draft or a Published Project including all the scores. Observers cannot make any changes to the project. Project Observer is a read-only role.",
    "tooltip_people_reviewers": "A Reviewer can only view basic components of the project and is required to score criteria and read documents assigned to their Evaluation Groups.",
    "tooltip_project_visibility": "<strong>Public Projects</strong> are visible on the Public Portal and allow any vendor to upload submissions.<br><br><strong>Private Projects</strong> can only be accessed through an encrypted link provided to the vendors by the purchaser.<br><br><strong>Invite Only Projects</strong> require that the vendor's email is on the invite list or matches the domain name in order to view & submit.",
    "tooltip_project_visibility_without_invite_only": "<strong>Public Projects</strong> are visible on the Public Portal and allow any vendor to upload submissions.<br><br><strong>Private Projects</strong> can only be accessed through an encrypted link provided to the vendors by the purchaser.",
    "tooltip_proposal_contract": "Access the Contract(s) for this submission via the Actions dropdown.",
    "tooltip_protected_projects": "In a <strong>Protected Project</strong>, the project details are hidden from Organization Members that are not directly involved in the project but would normally have the necessary permissions to see the project details (Organization Administrators, Department Managers, Project Managers). These users have the ability to unlock and view the project details if required, but the Project Owner will be notified if they do so.",
    "tooltip_public_document_takers": "<strong>Public Document Takers</strong>: Vendors will be able to see who has downloaded any public documents for the project.",
    "tooltip_required_requested_info": "A Required item of requested information MUST be uploaded by a vendor to complete their submission.",
    "tooltip_scale_description": "Scale: Allows the evaluator to enter a score based on the scale shown",
    "tooltip_sealed_requested_info": "A Sealed item of requested information CANNOT be viewed by anyone until the item of information is intentionally unsealed.",
    "tooltip_the_budget_values_are_not_visible_to_vendors": "The budget values are not visible to vendors.",
    "tooltip_the_commodity_code": "The commodity code for this contract (e.g. For the NIGP code set, you could enter 208-00-71 for Purchasing Software.).",
    "tooltip_the_commodity_code_set": "The commodity code set that your organization uses (e.g. NIGP, UNSPSC, GSIN, SIC, etc.).",
    "tooltip_unique_per_alternate_criteria": "When a Criteria is marked as Unique Per Alternate a different score will be required for each alternate vendor submission.",
    "tooltip_unique_per_alternate_slot": "When a Requested Information is marked as Unique Per Alternate a different file will need to be provided when a Vendor is uploading an alternate submission.",
    "tooltip_uploaded_by_s": "Uploaded by %s",
    "tooltip_visible_to_eval_grp": "For requested documents/data, all members of the selected evaluation groups can view the entire contents of any item uploaded by vendors. For requested questionnaires, all members of the selected evaluation groups can view the vendor's responses for the question set under consideration.",
    "tooltip_weight_scaling": "<strong>Weight Scaling</strong>: Override the weight for this group (rather than using the sum of its points).",
    "x_criteria_have_been_released_to_be_evaluated_by_y": "%s criteria have been released to be evaluated. The evaluation is to be completed by %s. Please click on the link below to view the submissions and begin your evaluation.",
    "x_has_invited_you_to_use_bonfire_at_y": "%s has invited you to use Bonfire at %s",
    "you_have_been_assigned_the_following_user_roles": "You have been assigned the following user roles:",
    "phrase_there_are_no_things": "There are no %{things}.",
    "phrase_signed_opportunity_nondisclosure_agreements": "signed Opportunity Non-Disclosure Agreements",
    "keyword_current": "Current",
    "label_click_here_to_go_there": "Click here to go there.",
    "phrase_current_advisors_already_include": "Current Advisors already include",
    "phrase_current_editors_already_include": "Current Editors already include",
    "phrase_current_observers_already_include": "Current Observers already include",
    "phrase_current_reviewers_already_include": "Current Reviewers already include",
    "phrase_please_provide_an_email_to_add_an_advisor": "Please provide an email to add an Advisor.",
    "phrase_please_provide_an_email_to_add_an_editor": "Please provide an email to add an Editor.",
    "phrase_please_provide_an_email_to_add_an_observer": "Please provide an email to add an Observer.",
    "phrase_please_provide_an_email_to_add_a_reviewer": "Please provide an email to add a Reviewer.",
    "phrase_there_are_no_vendors_to_send_messages_to_yet": "There are no vendors to send messages to yet. Once vendors have started engaging with this project on the portal, you can message them.",
    "phrase_you_should_sign_but_access_is_no_block_by_coi": "There are declaration forms on this project however based on your role your access to submissions is not blocked.",
    "label_insights": "Insights",
    "keyword_contract_term": "Contract Term",
    "label_current_thing": "Current %{thing}",
    "label_change_active_term": "Change Active Term",
    "keyword_terms": "Terms",
    "phrase_set_as_x": "Set as %{x}",
    "keyword_difference": "Difference",
    "label_new_contract_values": "New Contract Values",
    "label_change_to_contract_values": "Change to Contract Values",
    "error_x_must_be_greater_than_or_equal_to_zero": "%{x} must be greater than or equal to zero",
    "phrase_x_must_be_before_y": "%{x} must be before %{y}",
    "phrase_example_campus_department_abbreviation": "MC-P",
    "phrase_example_campus_department_name": "Main Campus - Purchasing",
    "label_custom_contract_fields": "Custom Contract Fields",
    "phrase_the_inputs_below_are_custom_contract_fields": "The inputs below are custom contract fields that have been set up for this organization.",
    "phrase_you_can_create_a_new_vendor": "You can create a new vendor or select an existing vendor from the dropdown.",
    "label_search_for_existing_vendor": "Search for existing vendor",
    "label_auction": "Auction",
    "label_auctions": "Auctions",
    "label_edit_x": "Edit %{x}",
    "label_edit_person": "Edit Person",
    "label_edit_project_template": "Edit Project Template",
    "action_publish_x": "Publish %{x}",
    "phrase_auction_updated_successfully": "Auction updated successfully",
    "label_number_of_things": "Number of %{things}",
    "label_lowest_bid": "Lowest Bid",
    "label_current_bid": "Current Bid",
    "label_no_bids": "No Bids",
    "label_auction_description": "Auction Description",
    "label_your_current_bid": "Your Current Bid",
    "label_place_bid": "Place Bid",
    "label_latest_bid": "Latest Bid",
    "label_target_price": "Target Price",
    "label_bids": "Bids",
    "label_requested_vendor_documents": "Requested Vendor Documents",
    "label_untitled_document": "Untitled Document",
    "tooltip_required_requested_vendor_document": "In order to prepare a submission, a vendor must upload a file for each required (and active) Requested Vendor Document.",
    "tooltip_active_requested_vendor_document": "Vendors may upload files to all active Requested Vendor Documents.",
    "phrase_requested_vendor_document_may_not_be_delete": "This Requested Vendor Document may not be deleted since one or more vendors have already uploaded some files. Please refresh the page.",
    "label_auction_title": "Auction Title",
    "phrase_auction_vendor_invite_help": "This invitation is valid only for %{vendorEmail}. You must login with this email address to view the auction. If you would like to access the auction with a different email address, you may contact the buyer at <a href='mailto:%{ownerEmail}'>%{ownerEmail}</a>.",
    "phrase_vendor_has_been_invited_to_bid": "%{vendorName} has been invited to bid on an eAuction at %{organization} as part of the submission process for %{project}.",
    "label_view_auction": "View Auction",
    "phrase_there_are_no_auctions_to_view_at_this_time": "There are no auctions to view at this time.",
    "phrase_auto_scored_by_results_of_auction_comment": "This score was automatically generated by %{name} on %{datetime} based on the results of the auction '%{auctionTitle}'",
    "label_subject": "Subject",
    "label_to": "To",
    "label_no_subject": "No Subject",
    "label_thing_not_found": "%{thing} not found",
    "phrase_bid_you_filled_in_some_of_the_values_for_item_but_didnt_provide_all_values": "You did not provide all of the values for %{item}. If you do not want to bid on this item, please change your response to say 'No Bid'. Otherwise, please fill in all of the values.",
    "phrase_no_bid_you_filled_in_some_of_the_values_for_item_but_didnt_provide_all_values": "You filled in some values for %{item} but did not provide all of the values. Since you selected 'No Bid', you need to clear these values as you've indicated you do not want to bid on the item. If you would like to bid, please change your response to say 'Bid' and fill in all of the values.",
    "phrase_the_auction_starts_at_startdate_and_ends_at_enddate": "The auction starts at <strong>%{startDate}</strong> and ends at <strong>%{endDate}</strong>.",
    "phrase_the_auction_ends_at_enddate": "The auction ends at <strong>%{endDate}</strong>.",
    "phrase_please_note_you_can_only_access_the_auction": "Please note that you can only access the auction using the email address %{email}.",
    "phrase_auction_title_starts_today": "Auction %{title} starts today",
    "phrase_auction_title_ends_today": "Auction %{title} ends today",
    "phrase_auction_title_is_starting_soon": "Auction %{title} is starting soon",
    "label_vendor_documents": "Vendor Documents",
    "action_back_to_vendors": "Back to Vendors",
    "action_back_to_request": "Back to Request",
    "label_live": "Live",
    "label_auto_score": "Auto-Score",
    "phrase_vendor_registration_requirements_not_met": "In order to prepare a submission, %{organization} requires that you first provide additional Vendor Registration Information.",
    "label_upload_documents": "Upload Documents",
    "phrase_please_go_to_the_settings_page_to_provide_this_information": "Please go to the settings page to provide this information.",
    "keyword_obsolete": "Obsolete",
    "label_upload_a_new_document": "Upload a New Document",
    "label_choose_a_requested_document": "Choose a Requested Document",
    "phrase_auction_has_ended": "Auction has ended",
    "label_public_notices": "Public Notices",
    "phrase_create_a_new_public_notice": "Create a new Public Notice",
    "phrase_in_order_to_view_the_supporting_documentation_you_must": "In order to view the Supporting Documentation, Requested Information, and other Project details, or to prepare a submission, you must",
    "tooltip_internal_discussion": "Internal Discussions are visible to only the People you select",
    "tooltip_public_notices": "Public Notices are visible to anyone who can view the opportunity",
    "phrase_no_requested_documents_at_this_time": "There are no Requested Documents at this time.",
    "error_column_name_is_already_in_use": "That column name is already in use.",
    "label_invited_auctions": "Invited Auctions",
    "phrase_support_team_contact_info": "Our support team can also be contacted by emailing support@gobonfire.com. Support by email is provided Monday to Friday from 8 AM - 8 PM EST/EDT.",
    "phrase_auction_component_of_project": "Auction component of %{project}.",
    "label_results": "Results",
    "label_activity": "Activity",
    "phrase_use_scroll_to_zoom_and_drag_to_move": "Use scroll to zoom and drag to move",
    "phrase_theres_no_activity_to_display": "There's no activity to display.",
    "phrase_user_bid_amount": "%{user} bid %{amount}",
    "phrase_auction_has_started": "Auction has started",
    "phrase_auction_has_been_extended_to_date": "Auction has been extended to %{date}",
    "phrase_user_has_left": "%{user} has left",
    "phrase_user_has_joined": "%{user} has joined",
    "label_extension_period": "Extension Period",
    "label_extend_by": "Extend By",
    "label_auction_starts_in": "Auction Starts In",
    "label_auction_ends_in": "Auction Ends In",
    "label_new_bid_amount": "New Bid Amount",
    "label_your_bids": "Your Bids",
    "phrase_check_sys_reqs": "Make sure your system meets the minimum requirements to submit (see below).",
    "phrase_use_backup_submission_method": "If you are still unable to submit, please use one of the following alternative methods:",
    "label_opportunity_intent_to_bids": "Opportunity Intent to Bids",
    "error_auction_has_not_started": "Auction has not started.",
    "error_must_be_a_valid_date": "Must be a valid date",
    "label_extension_period_description": "The extension period represents the period before the end of the auction where, if a bid is placed, the auction will be extended.",
    "label_extend_by_description": "This is the amount of time an auction will be extended by if a bid is placed during the extension period. If there is no extension period, the auction will never be extended.",
    "phrase_there_are_no_vendors_associated_with_this_project_yet": "There are no vendors associated with this project yet.",
    "phrase_requested_vendor_documents_description": "Requested Vendor Documents allow you to ask Vendors to upload certain documents before they can create a submission and participate in an opportunity.",
    "phrase_feature_has_been_disabled_for_this_organization": "%{feature} has been disabled for this organization.",
    "label_email_and_password": "Email and Password",
    "label_vendor_registration_fields": "Custom Fields",
    "label_vendor_registration_documents": "Vendor Documents",
    "label_previous": "Previous",
    "phrase_the_following_n_vendors_have_been_selected": "The following %{n} vendors have been selected",
    "label_add_to_another": "Add to Another",
    "label_remove_from_another": "Remove from Another",
    "label_remove_vendors_from_list": "Remove Vendors from List",
    "label_add_vendors_to_list": "Add Vendors to List",
    "label_no_reason_provided": "No Reason Provided",
    "label_reasons": "Reasons",
    "label_untitled_x": "Untitled %{x}",
    "label_move_to_subset": "Move to Subset",
    "label_no_subset": "No Subset",
    "label_no_evaluation_group": "No Evaluation Group",
    "label_create_decision_project": "Create Decision Project",
    "phrase_before_you_split_the_project": "Before you split the project, evaluate any criteria that apply to several decisions, including",
    "phrase_any_criteria_that_apply_to_multiple_categories": "Any criteria that apply to multiple categories",
    "phrase_any_criteria_that_apply_to_multiple_decisions_within_a_category": "Any critera that apply to multiple decisions within a category",
    "phrase_splitting_the_project_is_a_permanent_action": "Splitting the project is a permanent action. You will not be able to evaluate cross-decision criteria after you split the project.",
    "phrase_registered_on_date": "Registered on %{date}",
    "label_address": "Address",
    "label_manage_subsets": "Manage Subsets",
    "error_there_was_an_error_auto_scoring_the_responses": "There was an error auto-scoring the responses. Please refresh the page and try again. If the problem persists, contact Support@GoBonfire.com.",
    "phrase_questionnaire_auto_score_auto_generated_comment": "This score was automatically generated by %{name} on %{datetime} based on the following mapping of Responses to percentages:",
    "label_date_and_time": "Date and Time",
    "phrase_subsets_cannot_be_deleted_if_they_have_questions": "Subsets cannot be deleted if they have Questions in them. Please move the Questions to another Subset first.",
    "label_number_of_questions": "# Questions",
    "label_add_x": "Add %{x}",
    "label_phrase_all_questions_in_subset_will_also_be_deleted": "All Questions in this Subset will also be deleted.",
    "label_phrase_all_subsets_and_questions_in_subset_will_also_be_deleted": "All Subsets and Questions in this Question Set will also be deleted.",
    "error_field_must_be_a_number_gte_zero": "%{field} must be a number >= 0",
    "error_field_must_be_an_empty_string_or_csv": "%{field} must be one of: an empty string, or a comma-separated values string with 2 or more non-blank values",
    "label_delete_x": "Delete %{x}",
    "label_no_question_set_selected": "No Question Set Selected",
    "phrase_add_or_select_a_question_set_to_view_and_edit_subsets_and_questions": "Add or select a Question Set to view and edit the Subsets and Questions.",
    "phrase_this_tool_allows_you_to_add_questions_in_bulk": "This tool allows you to add new Questions in bulk. Copy and Paste your data from Excel (tab delimited) into the text box below.",
    "phrase_your_data_must_contain_at_least_count_columns": "Your data must contain at least %{count} columns: %{columns}",
    "phrase_you_may_include_additional_columns": "You may also include any of the following optional columns:",
    "label_add_another": "Add another",
    "phrase_public_files_explanation": "Public files are visible here and on the opportunity page for this project. Only people who can view this page or the opportunity page can see these files.",
    "phrase_internal_files_explanation": "Internal files are only visible here. Only people who can see this page can see these files.",
    "phrase_open_public_opportunities_rss_description": "The Open Public Opportinities rss feed contains all opportunities for this organization that are visible through the public portal.",
    "phrase_project_closes_date": "Project closes %{date}.",
    "label_link": "Link",
    "label_link_new_tab": "opens in new tab",
    "label_vendor_records": "Vendor Records",
    "label_add_a_thing": "Add a %{thing}",
    "label_edit_a_thing": "Edit a %{thing}",
    "label_vendor_registration_document": "Vendor Document",
    "label_vendor_registration_field": "Custom Field",
    "phrase_vendor_registration_fields_allow_you_to_ask_additional_information_from_vendors": "Custom Fields allow you to ask for additional information related to a Vendor.",
    "label_vendor_details": "Vendor Details",
    "label_not_found": "Not Found",
    "label_add_to_vendor_lists": "Add to Vendor Lists",
    "label_select_things": "Select %{things}",
    "keyword_percentage": "Percentage",
    "keyword_comment": "Comment",
    "phrase_n_new_scores_will_be_created": "%{n} new scores will be created.",
    "phrase_n_scores_will_be_updated": "%{n} scores will be updated.",
    "phrase_the_reason_is_missing_or_invalid_for_n_responses": "The reason is missing or invalid for %{n} responses.",
    "label_auto_scoring": "Auto-Scoring",
    "label_please_add_some_questions": "Please add some questions",
    "label_auto_scoring_n_questions": "Auto-Scoring %{n} Questions",
    "label_filtering_from_context": "Filtering from %{context}",
    "label_any_types": "Any (%{types})",
    "phrase_asterisk_denotes_internal_submission": "* Denotes internal submission.",
    "phrase_are_you_sure_you_want_to_delete_this_auction": "This will delete all Invites and Bids for this Auction, and disassociate the Auction from any Data Slots. This action cannot be undone. Are you sure that you want to delete this Auction?",
    "label_no_description": "No Description",
    "keyword_responses": "Responses",
    "label_response_comment": "Response Comment",
    "notice_please_select_a_response": "Please select a Response.",
    "notice_please_select_a_response_from_dropdown_for_question_number_n": "Please select a Response from the dropdown for Question # %{n}.",
    "notice_please_enter_response_comment": "Please enter a Response Comment.",
    "phrase_no_questions_to_auto_score_for_current_filter": "There are no Questions to Auto-Score for the current filter.",
    "phrase_no_response_comment_provided": "No Response Comment provided.",
    "phrase_no_response_provided": "No Response provided.",
    "notice_name_has_changed_some_of_your_scores": "%{name} has changed some of your scores.",
    "notice_some_of_your_scores_for_the_title_questionnaire_have_been_changed": "Some of your scores for the %{title} Questionnaire have been changed.",
    "label_vendor_response_options": "Vendor Response Options",
    "label_vendor_response_options_tooltip": "You can optionally provide a list of pre-defined responses that the vendor must choose from when answering this question. Example: 'Fully-Compliant, Partially Compliant, Non-Compliant, N/A'",
    "label_no_questions": "No Questions",
    "keyword_export": "Export",
    "label_extension_window": "Extension Window",
    "label_extension_amount": "Extension Amount",
    "label_current_spend": "Current Spend",
    "label_target_savings": "Target Savings",
    "label_map_price_values_to_criterion": "Map price values to Criterion",
    "error_this_field_must_be_a_percentage_between_0_and_100": "This field must be a percentage between 0 and 100",
    "label_price": "Price",
    "label_live_auctions": "Live Auctions",
    "label_create_a_thing": "Create a %{thing}",
    "label_allow_multiple_options": "Allow Multiple Options",
    "phrase_allow_multiple_options_tooltip": "Allow multiple options to be selected",
    "phrase_is_required_option_tooltip": "In order to prepare a submission, a vendor must fill in this registration field.",
    "phrase_viewing_for_proposal": "Viewing for proposal",
    "label_allow_multiple": "Allow Multiple",
    "label_go_to_settings": "Go to Settings",
    "phrase_this_auction_requires_your_new_bid_to_beat_the_current_best_by_x": "This auction requires your new bid to beat the current best by at least %{x}",
    "phrase_this_auction_requires_your_new_bid_to_beat_your_lowest_bid_so_far_by_x": "This auction requires your new bid to beat you lowest bid so far by at least %{x}",
    "label_vendors_joined_this_month": "Vendors Joined This Month",
    "label_registered_commodity_codes": "Registered Commodity Codes",
    "label_vendor_lists_created": "Vendor Lists Created",
    "label_vendors_joined": "Vendors Joined",
    "label_fiscal_year_to_date": "Fiscal Year to Date",
    "label_registered": "Registered",
    "label_added_to_list_name": "Added to List %{list}",
    "label_started_proposal_for_project": "Started proposal for %{project}",
    "label_submitted_proposal_for_project": "Submitted proposal for %{project}",
    "label_uploaded_document_for_slot": "Uploaded document for %{slot}",
    "label_bid_rule": "Bid Rule",
    "label_custom_auction": "Custom Auction",
    "label_english_auction": "English Auction",
    "label_dynamic_auction": "Dynamic Auction",
    "label_personal_best_auction": "Personal Best Auction",
    "label_best_overall": "Best Overall",
    "label_personal_best": "Personal Best",
    "phrase_auction_bid_rule_best_overall_explanation": "New bids must beat the leading bid by at least the Bid Decrement Amount",
    "phrase_auction_bid_rule_personal_best_explanation": "Vendors can place a bid at any price as long as it is lower than their previous bid by at least the Bid Decrement Amount, regardless of the bid prices of other vendors",
    "phrase_vendors_can_compare_bids_by_rank_and_by_price": "Vendors can compare their bid with other bids by rank and by price",
    "label_auction_type": "Auction Type",
    "label_active_requested_document": "Active Requested Document",
    "label_no_files_have_been_uploaded": "No files have been uploaded",
    "label_action_another": "%{action} another",
    "label_search_by_project": "Search by Project",
    "label_search_by_contract": "Search by Contract",
    "error_this_field_must_be_a_number_between_x_and_y": "This field must be a number between %{x} and %{y}",
    "label_minimum_bid_decrement_amount": "Bid Decrement Amount",
    "label_bid_ceiling": "Bid Ceiling",
    "phrase_bid_ceiling_explanation": "All vendor bids must be lower than the Bid Ceiling",
    "label_no_bid_ceiling": "No Bid Ceiling",
    "phrase_this_auction_requires_you_to_bid_less_than_bidCeiling": "This auction requires you to bid less than %{bidCeiling}",
    "phrase_lock_to_disable_bid_ceiling": "Lock to disable Bid Ceiling",
    "label_vendors_compare_bids": "Vendors Compare Bids",
    "label_new_thing": "New %{thing}",
    "label_history": "History",
    "label_savings": "Savings",
    "label_savings_vs_target": "Savings vs. Target",
    "label_bidders": "Bidders",
    "label_can_compare_bids": "Can Compare Bids",
    "label_enter_a_new_bid": "Enter a New Bid",
    "error_must_be_a_positive_value_less_than_or_equal_to_x": "Must be a positive value less than or equal to %{x} with 2 decimal places",
    "label_take_the_lead": "Take the Lead",
    "phrase_i_commit_my_organziation_to_this_price": "I commit my organization to this price in the event that this bid is the winner.",
    "label_your_rank": "Your Rank",
    "label_your_bid_count": "Your Bid Count",
    "label_your_last_bid": "Your Last Bid",
    "label_behind_lowest_bid": "Behind Lowest Bid",
    "error_please_accept_the_email_invitation_first": "In order to log in, please accept the email invitation first.",
    "phrase_yes_and_vendors_can_see_each_others_names": "Yes, and vendors can see each other's names",
    "phrase_yes_but_vendors_cannot_see_each_others_names": "Yes, but vendors cannot see each other's names",
    "label_vs_first": "vs. 1st",
    "label_auction_start": "Auction Start",
    "phrase_auction_end_date_explanation": "This is the earliest the auction will end. If you set a non-zero Extension Period and Extension Amount, then the auction could end later depending on when vendors stop bidding",
    "phrase_auction_extenstion_window_explanation": "If any vendors bid with less than this amount of time remaining before the end of the auction, the auction's End Date / Time will be extended by the Extension Amount",
    "phrase_auction_extension_amount_explanation": "If any vendors bid within the Extension Period, the auction's End Date / Time will be extended by this amount of time",
    "phrase_auction_auction_type_explanation": "Auction Types are defined by the properties shown in the table",
    "phrase_auction_bid_rule_explanation": "The Bid Rule defines the constraints on new bids for vendors",
    "phrase_auction_current_spend_explanation": "Current Spend is the amount of money you would expect to pay without running this auction. Vendors cannot see this",
    "phrase_auction_target_savings": "Target Savings is the percentage of Current Spend you aim to save by running this auction. Vendors cannot see this",
    "phrase_unlock_to_set_bid_ceiling": "Unlock to set Bid Ceiling",
    "phrase_auction_minimum_bid_decrement_explanation": "This is the amount by which any new bid must be less than the current bid, as determined by the the Bid Rule",
    "phrase_project_details_description": "Overview of the project dates, highest ranking supplier, and NDA + COI forms.",
    "label_signature_block": "Signature Block",
    "phrase_signature_block_description": "Formatted page where you can have all of the participants sign the report.",
    "phrase_questions_and_answers_description": "Questions from vendors and corresponding responses.",
    "label_opportunity_vendor_nda": "Opportunity Vendor NDA",
    "phrase_opportunity_vendor_nda_description": "Opportunity Non-Disclosure Agreements Summary Table (Name, Organizations, Date Signed).",
    "phrase_intent_to_bid_description": "Opportunity Intent to Bid Summary Table (Name, Organizations, Response, Reason, Date Signed).",
    "phrase_submissions_description": "List of the Vendors (Email, Name, Confirmation Codes) that submitted for this project.",
    "phrase_criteria_description": "List of the Criteria (Title, Points, Description) for this project.",
    "phrase_scoring_summary_description": "Scoring Summary table (Submissions and Criteria Scores).",
    "phrase_submission_scores_description": "Scoring Summary Score tables per Submission.",
    "label_submission_score_comments": "Submission Score Comments",
    "phrase_submission_score_comments_description": "Scoring Summary Comment tables per Submission.",
    "label_questionnaire_responses_and_scores": "Questionnaire Responses and Scores",
    "phrase_questionnaire_responses_and_scores_description": "Questionnaire Responses and Scores per Questionnaire.",
    "phrase_when_a_vendor_record_has_a_full_address": "When a Vendor Record has a full address a Google Map will display here...",
    "phrase_please_note_the_file_type": "Please note the file types allowed. The maximum upload file size is %{size} MB.",
    "phrase_all_vendors_for_the_listed_submissions_will_be_invited_to_participate": "All vendors for the submissions listed below will be invited to participate in the auction. The final Bid from vendors will be used to provide a Consensus Score against the Criteria auto-scored by the Data Slot selected below.",
    "phrase_upload_vendor_document_instructions": "For each slot, select the Requested Document, Choose a File, then click the 'Upload File' button as many times as necessary to fill all the requirements.",
    "label_remove_vendor_from_list": "Remove Vendor from List",
    "notice_are_you_sure_you_want_to_remove_the_vendor_from_this_list": "Are you sure you want to remove the vendor from this list?",
    "label_select_a_data_slot": "Select a Data Slot",
    "label_thing_joined": "%{thing} joined",
    "label_thing_was_created": "%{thing} was created",
    "label_thing_was_added": "%{thing} was added",
    "label_name_uploaded_a_document": "%{name} uploaded a document",
    "label_name_logged_in": "%{name} logged in",
    "instructions_please_do_not_use_formulas_in_your_responses": "Please do not use Excel formulas in your responses.",
    "label_pages_saved": "Pages Saved",
    "label_fiscal_year_to_date_acronym": "FYTD",
    "label_max_depth": "Max depth",
    "phrase_there_are_no_signatures_to_display_at_this_time": "There are no signatures to display at this time",
    "label_preview_declaration": "Preview Declaration",
    "tooltip_submissions_recieved_sparkline": "Based on the past 10 days for projects you can view",
    "phrase_this_total_does_not_include_all_items_for_this_basket": "This total does not include all items for this basket",
    "phrase_this_total_does_not_include_all_items_for_this_bidtable": "This total does not include all items for this %{bidTable}",
    "phrase_before_you_can_delete_department_you_must_first_delete_or_reassign_things": "Before you can delete %{department} you must first delete or reassign to different departments: %{things}.",
    "keyword_consensus": "Consensus",
    "keyword_template": "Template",
    "keyword_templates": "Templates",
    "label_create_project_from_template": "Create Project From Template",
    "label_project_drafts": "Project Drafts",
    "label_project_templates": "Project Templates",
    "phrase_do_you_want_to_create_project_from_template": "Do you want to create a new project using this project as its template?",
    "phrase_do_you_want_to_create_template_from_project": "Do you want to create a new template based on this project?",
    "phrase_do_you_want_to_create_project_draft_from_project_template": "Do you want to create a new project draft based on this project template?",
    "phrase_do_you_want_to_duplicate_this_template": "Do you want to duplicate this template?",
    "label_duplicate_template": "Duplicate Template",
    "label_response_option_score_percentages": "Response Option Score Percentages",
    "phrase_response_option_score_percentage_input_conditions": "Response Option Score Percentages '%{input}' must be one of: an empty string, or a comma-separated values string with %{count} non-blank numbers between 0 and 100",
    "phrase_percent_of_x_points": "% of %{x} Points",
    "label_vendor_response_option": "Vendor Response Option",
    "phrase_there_must_be_2_or_more_vendor_response_options_or_none": "There must either be 2 or more Vendor Response Options, or else none at all.",
    "label_percent_of_total_points_allocated": "% of Total Points Allocated",
    "phrase_each_vendor_response_option_must_have_a_score_percentage": "Each Vendor Response Option must have a non-empty percentage (0-100) of the total points to be allocated to it.",
    "phrase_define_autoscoring_points_allocation": "Define auto-scoring points allocation",
    "phrase_define_autoscoring_points_allocation_tooltip": "You can optionally assign each Vendor Response Option a percentage of the total points, which will be used to auto-score this question.",
    "phrase_vendors_will_not_see_this_points_allocation": "Vendors will not see these point allocations.",
    "label_points_allocated": "Points Allocated",
    "label_predefined_response_autoscore_value": "Pre-Defined Response Auto-Score Values",
    "label_bulk_edit_questions": "Bulk Edit Questions",
    "phrase_you_are_editing_x_questions": "You are bulk editing %{x} questions. Any changes will apply to all %{x} questions.",
    "phrase_select_checkbox_to_save_changes_to_fieldName": "Select checkbox to save changes to %{fieldName}",
    "phrase_please_select_a_question_set_first": "Please select a Question Set first",
    "phrase_please_select_an_evaluation_group_first": "Please select an Evaluation Group First",
    "phrase_since_you_are_changing_the_question_set_please_change_the_subset": "Since you are changing the Question Set, please change the Subset",
    "label_predefined_response_autoscore_values": "Pre-Defined Response Auto-Score Values",
    "phrase_x_questions_have_inidividualized_response_autoscore_values_explanation": "%{x} Questions have individualized response auto-score values set up by the buyer.",
    "phrase_1_question_has_individualized_response_autoscore_values_explanation": "1 Question has individualized response auto-score values set up by the buyer.",
    "phrase_individual_questions_predefined_response_autoscore_values": "individual question's pre-defined response auto-score values",
    "phrase_click_autoscore_to_record_your_scores": "Click 'Auto-Score' to record your scores.",
    "phrase_this_date_is_not_valid_please_update_schedule": "This date is not valid. Please update the schedule.",
    "error_x_is_not_valid": "%{x} is not valid",
    "label_download_template": "Download Template",
    "phrase_questionnaire_bulk_import_template_example_question": "Example Question: Are you compliant with our requirements?",
    "phrase_questionnaire_bulk_import_template_example_reasons": "Fully Compliant, Partially Compliant, Non Compliant",
    "phrase_please_match_your_data_to_columns": "Please match your data to %{columns}",
    "phrase_ignore_this_column": "Ignore this column",
    "label_example_question_set_title": "Example Question Set Title",
    "label_example_subset": "Example Subset",
    "error_you_cannot_provide_response_option_score_percentages_without_vendor_repsonse_options": "You cannot provide 'Response Option Score Percentages' without also providing 'Vendor Response Options'",
    "phrase_this_column_is_not_empty_are_you_sure_you_want_to_ignore_it": "This column is not empty. Are you sure you want to ignore it?",
    "phrase_use_existing_thing": "Use existing %{thing}",
    "phrase_we_found_things": "We found %{things}",
    "phrase_please_match_the_columns_in_your_imported_data": "Please match the columns in your imported data.",
    "label_region": "Region",
    "label_service_regions": "Service Regions",
    "label_all_regions_selected": "All Regions in %{region} Selected",
    "heading_add_draft": "Add Draft",
    "keyword_reopened": "Reopened",
    "keyword_participants": "Participants",
    "label_pages_of_paper_saved": "Pages of Paper Saved",
    "label_all_x": "All %{x}",
    "label_project_owners": "Project Owners",
    "label_project_types": "Project Types",
    "label_project_paper_savings": "Project Paper Savings",
    "label_project_configuration": "Project Configuration",
    "label_date_open": "Date Open",
    "label_date_close": "Date Close",
    "label_date_evaluation_released": "Date Evaluation Released",
    "label_date_of_first_score": "Date of First Score",
    "label_date_of_last_score": "Date of Last Score",
    "label_date_complete": "Date Complete",
    "label_project_cycle_time": "Project Cycle Time",
    "label_lack_of_consensus": "Lack of Consensus",
    "label_filter_responses": "Filter Responses",
    "label_item_eliminated": "Item Eliminated",
    "notice_error_modifying_question_score": "There was an error (%{error}) %{verb} the score for %{proposalName}, Question # %{questionNumber}. Please refresh the page and try again.",
    "label_item_not_selected": "Item Not Selected",
    "label_selected_from": "Selected From",
    "keyword_unselected": "Unselected",
    "phrase_choose_order_list_to_view_summary": "Choose an order list from the dropdown to view a summary of the selected and unselected items.",
    "placeholder_choose_an_thing": "Choose an %{thing}",
    "error_could_not_delete_keywordBidTable_item": "Error: Could not delete %{keywordBidTable} item because there are Vendor Responses, Order Lists, or Notes associated with it.",
    "phrase_warning_you_are_changing_a_keywordBidTable": "Warning: You are changing a %{keywordBidTable} and there are in-progress or completed submissions.",
    "phrase_warning_you_are_changing_a_keywordBidTable_with_downloaded_templates": "Warning: You are editing a %{keywordBidTable} that has had %{numberOfDownloads} template(s) downloaded. If you make any changes, you may invalidate these templates.",
    "label_make_this_column_visible_to_vendors": "Make this column visible to vendors",
    "tooltip_make_this_column_visible_to_vendors": "If 'Yes', this column will appear in the Vendor Response Template. Otherwise, this column and all Calculated Columns calculated using this column will not be visible to Vendors.",
    "tooltip_this_column_is_locked_because_columns_not_visible": "This is locked because the following columns are not visible to Vendors: %{columns}",
    "keyword_saving": "Saving",
    "keyword_deleting": "Deleting",
    "tooltip_this_column_is_not_visible_to_vendors": "This column is not visible to Vendors",
    "tooltip_this_column_cannot_be_hidden_from_vendors": "This column cannot be hidden from Vendors because it is used in the following Calculated Columns: %{columns}. Hide those Calculated Columns from Vendors before hiding this one.",
    "label_download_all_items": "Download All Items",
    "phrase_your_download_will_begin_shortly": "Your download will begin shortly.",
    "phrase_your_bidtable_is_too_large_to_process": "Your BidTable is too large to process.",
    "keyword_registration": "Registration",
    "phrase_we_ask_vendors_to_do_this_in_order_to_verify_your_identity": "We ask Vendors to confirm their identity so that you can access information on prospective bid opportunities.",
    "label_send_account_confirmation_email": "Send Account Confirmation Email",
    "phrase_your_account_with_the_email_address_email_has_been_confirmed": "Your account with the email address %{email} has been confirmed!",
    "phrase_in_addition_to_creating_your_account_organization_needs_the_following_information": "In addition to creating your account, you need to provide the following information. Please complete all of the steps below.",
    "phrase_please_fill_out_the_missing_information_in_the_form_below": "Please fill out the missing information in the form below.",
    "phrase_fields_that_are_required_are_denoted_with_a_red_asterisk": "Fields that are required are denoted with a red asterisk (<span class='fa-solid fa-asterisk bf-red'></span>).",
    "phrase_all_information_that_you_need_to_provide_has_been_filled_in": "All information that you need to provide has been filled in. You can make additional changes to this information below.",
    "phrase_organization_has_requested_that_you_provide_documentation": "%{organization} has requested that you provide a file for each registration document. If there is a Template associated with a document, there will be a download icon <span class='fa-solid fa-download'></span> next to the Upload File button.",
    "phrase_at_least_one_file_is_required_for_each_requested_document": "Click '<b>Upload File...</b>' to select a file to upload. You must upload at least 1 (one) file (Maximum file size is 1000MB) for each document slot that has a green Required badge <span class='label radius reversed-green bold' aria-hidden>REQUIRED</span>.",
    "phrase_please_note_the_file_types_allowed": "Please note the file types allowed. The maximum upload file size is 1000 MB.",
    "phrase_commodity_codes_are_used_for_classification_and_matching_to_future_bid_opportunities": "Commodity Codes help match you to future bid opportunities in your Service Region, with %{organization}. You can always go back and change them later.",
    "phrase_you_have_not_yet_completed_all_the_requirements_needed_to_register_at_organization": "You have <b>not</b> yet completed all the requirements needed to register your account at %{organization}.",
    "phrase_please_complete_the_steps_listed_in_order_to_complete_your_registration": "Please complete the steps listed in order to complete your registration.",
    "phrase_you_have_completed_all_of_the_requirements_needed_to_register_vendor_at_organization": "You have completed all of the requirements to register your Vendor account for %{vendor} at %{organization}.",
    "phrase_you_have_completed_all_of_the_requirements_needed_to_register": "You have completed all of the requirements to register your account.",
    "phrase_you_can_access_bid_opportunities_on_the_portal_or_by_clicking_here": "You can access Bid Opportunities on the <a href='/portal' title='%{titleAttribute}'><b>Portal</b></a> or by clicking <a href='/portal' title='%{titleAttribute}'><b>here</b></a>.",
    "label_go_to_the_portal": "Go to the Portal",
    "phrase_your_registration_at_organization_is_not_complete_for_the_following_vendors": "Your registration at %{organization} is not complete for the following Vendors: %{vendors}",
    "phrase_when_you_are_finished_click_save_to_save_your_changes": "When you are finished, click 'Save' to save your changes.",
    "label_accept_legal": "I accept the <a href='https://eunasolutions.com/privacy-policy' target='legal'>Privacy Policy</a> and <a href='https://gobonfire.com/terms-service' target='legal'>Vendor and Submission Portal Terms of Service</a>.",
    "label_legal_updated": "Bonfire's Privacy Policy and Terms of Service were updated on %{date}.",
    "label_accepted_legal": "You accepted the <a href='https://eunasolutions.com/privacy-policy' target='legal'>Privacy Policy</a> and <a href='https://gobonfire.com/terms-service' target='legal'>Terms of Service</a> on %{date}.",
    "label_account_confirmation": "Account Confirmation",
    "label_account_information": "Account Information",
    "label_documentation": "Documentation",
    "label_optional_commodity_codes": "Optional: Commodity Codes",
    "label_registration_complete": "Registration Complete",
    "label_go_to_bid_opportunities": "Go to Bid Opportunities",
    "label_go_to_projects": "Go to Projects",
    "label_continue_to_bonfire": "Continue to Bonfire",
    "label_continue_to_x": "Continue to %{x} &raquo;",
    "label_save_and_continue_to_x": "Save and continue to %{x} &raquo;",
    "label_step_x_y": "Step %{x}: %{y}",
    "phrase_you_must_confirm_your_account_before_you_can_see_your_invited_opportunities": "You must confirm your account before you can see your invited opportunities. Go to your Settings page to confirm your account.",
    "error_there_was_an_error_while_inputting_your_score": "There was an error while inputting your score. Please contact Support@GoBonfire.com",
    "error_field_must_be_an_integer_gt_zero": "%{field} must be an integer greater than 0",
    "error_field_must_be_non_empty_and_lte_max_characters": "%{field} must be non-empty and <= %{max} characters long",
    "error_field_must_be_numeric_and_may_not_contain_any_currency_symbols": "%{field} must be numeric and may not contain any currency symbols",
    "label_paste_data": "Paste Data",
    "keyword_errors": "Errors",
    "keyword_rows": "Rows",
    "keyword_columns": "Columns",
    "phrase_you_are_about_to_paste_things_of_data": "You are about to paste %{things} of data.",
    "phrase_confirm_your_changes_to_complete_the_paste": "Confirm your changes to complete the paste.",
    "phrase_we_found_validRowsString_and_invalidRowsString_of_data": "We found %{validRowsString} and %{invalidRowsString} of data. Please resolve all the errors before confirming your changes.",
    "phrase_valid_row": "valid row",
    "phrase_valid_rows": "valid rows",
    "phrase_invalid_row": "invalid row",
    "phrase_invalid_rows": "invalid rows",
    "error_could_not_complete_the_paste": "Error: Could not complete the paste. Please check that you are not attempting to paste more rows than there exist in the %{keywordBidTable}",
    "keyword_clipboard": "Clipboard",
    "phrase_this_action_cannot_be_undone": "This action cannot be undone.",
    "phrase_are_you_sure_you_want_to_paste_these_things": "Are you sure you want to paste these %{things}?",
    "tooltip_this_is_the_value_that_will_be_saved": "This is the value that will be saved after you confirm your paste",
    "tooltip_this_is_the_value_you_copied_to_your_clipboard": "This is the value you copied to your clipboard",
    "tooltip_this_is_the_value_that_was_in_the_grid_before_you_pasted": "This is the value that was in the grid before you pasted",
    "label_keywordBidTable_name": "%{keywordBidTable} Name",
    "phrase_please_add_some_keywordBidTable_items": "Please add some %{keywordBidTable} items",
    "error_could_not_delete_keywordBidTable_items": "Error: Could not delete %{keywordBidTable} items because there are Vendor Responses, Order Lists, or Notes associated with: %{bidTableItems}.",
    "error_could_not_delete_the_keywordBidTable_vendor_column": "Error: Could not delete the %{keywordBidTable} Vendor Column '%{columnName}' because there are Vendor Responses accociated with it.",
    "label_back_to_x": "Back to %{x}",
    "label_copying_and_pasting_data": "Copying and Pasting Data",
    "phrase_please_review_your_changes_and_click_confirm_and_save": "Please review your changes, then click <b>Confirm and Save</b> to save your changes.",
    "label_confirm_and_save": "Confirm and Save",
    "tooltip_if_you_change_this_it_will_invalidate_the_vendor_response_template": "If you change this it will invalidate the Vendor Response Template.",
    "tooltip_lock_to_prevent_further_changes": "Lock to prevent further changes",
    "tooltip_v2_question_set_score": "Sum of the average question scores.",
    "label_requested_information": "Lock requested information.",
    "phrase_lock_requested_information_explanation": "Requested information that is locked cannot be changed by vendors when the project is reopened.",
    "action_reopen_project": "Reopen Project",
    "heading_locked_requested_information": "Locked Requested Information",
    "heading_locked_requested_documents": "Locked Requested Documents",
    "heading_locked_requested_questionnaires": "Locked Requested Questionnaires",
    "heading_locked_requested_data": "Locked Requested Data",
    "heading_unlocked_requested_information": "Unlocked Requested Information",
    "heading_unlocked_requested_documents": "Unlocked Requested Documents",
    "heading_unlocked_requested_questionnaires": "Unlocked Requested Questionnaires",
    "heading_unlocked_requested_data": "Unlocked Requested Data",
    "notice_you_have_no_locked_requested_information": "You have no locked requested information!",
    "notice_you_have_no_unlocked_requested_information": "You have no unlocked requested information!",
    "notice_nothing_locked_explanation": "Vendors will be able to alter all of the information that they have already submitted. If you would like to lock some information click Manage on the Requested Information tab.",
    "notice_nothing_unlocked_explanation": "Vendors will not be able to submit any new information. Click Manage on the Requested Information tab to add new requested information.",
    "phrase_unlock_requested_document_explanation": "Requested information that is unlocked can be changed or added to by vendors when the project is reopened.",
    "phrase_these_suppliers_will_be_able_to_see": "These suppliers will be able to see that the project has reopened and will be able to update their submissions.",
    "notice_the_close_date_must_be_in_the_future": "The Close Date must be in the future",
    "notice_no_uneliminated_external_vendors": "You don't have any uneliminated external vendors, no one will be able to see that the project has reopened or be able to update their submissions.",
    "heading_additional_information": "Additional Information",
    "phrase_edit_your_submission_to_add_new_information": "Edit your submission to add new information",
    "phrase_the_project_has_beeen_reopened_information": "The project has been reopened. Please provide the additional requested information.",
    "heading_confirm_additional_information": "Confirm Additional Information",
    "phrase_some_information_may_be_locked": "Some or all of the information you have provided previously may be locked; this means that you will not be able to alter it.",
    "phrase_click_below_to_confirm": "Click below to confirm your additional information. You can return to this page to add more information or further modify the information you have already provided while the Project remains Reopened.",
    "phrase_lock_requested_document_explanation": "Requested information that is locked cannot be changed by vendors when the project is reopened.",
    "label_select_vendors": "Select Vendors",
    "db_string_key_project_reopened": "Project has Reopened",
    "notice_the_project_has_been_reopened": "The Project has been Reopened.",
    "label_modify_submission": "Modify Submission",
    "notice_locked_requested_information_vendor_explanation": "This Requested Information has been locked. This means that you cannot modify or delete the information.",
    "label_new_questions_due_date": "New Question Due Date",
    "label_new_close_date": "New Close Date",
    "label_new_evaluate_by_date": "New Evaluate By Date",
    "notice_the_question_due_date_must_be_in_the_future": "The Question Due Date must be in the future",
    "action_reopen_project_now": "Reopen Project Now",
    "heading_reopening_a_project": "Reopening a Project",
    "label_all_vendors": "All Vendors",
    "label_all_active_vendors": "All Active Vendors",
    "label_all_work_in_progress_vendors": "All Work in Progress Vendors",
    "label_all_eliminated_vendors": "All Eliminated Vendors",
    "label_project_has_reopened": "Project has Reopened",
    "label_new_spend": "New Spend",
    "keyword_awarded": "Awarded",
    "keyword_undo": "Undo",
    "keyword_chart": "Chart",
    "keyword_insight": "Insight",
    "keyword_filters": "Filters",
    "keyword_timeseries": "Timeseries",
    "label_scatter_plot": "Scatter Plot",
    "label_showing_x_rows": "Showing %{x} rows",
    "label_filtering_from_n_rows": "Filtering from %{n} rows",
    "keyword_timestamp": "Timestamp",
    "label_vendor_invitations": "Vendor Invitations",
    "label_submissions_created": "Submissions Created",
    "label_submissions_submitted": "Submissions Submitted",
    "label_all_other_proposals": "All Other Proposals",
    "label_actual_savings": "Actual Savings",
    "label_demo_mode": "Demo Mode",
    "label_organization_country": "Organization Country",
    "keyword_merge": "Merge",
    "label_reopen_date": "Reopen Date",
    "label_reclose_date": "Reclose Date",
    "label_publish_x": "Publish %{x}",
    "label_stack_columns": "Stack Columns",
    "label_group_by": "Group By",
    "label_display": "Display",
    "label_kpi_opportunity_funnel_graph_title": "Average Opportunity Funnel",
    "label_kpi_opportunity_funnel_graph_description": "The average number of proponents engaged throughout the stages of responding to a bid opportunity",
    "label_kpi_quality_vs_price_graph_title": "Quality vs Price Competitiveness",
    "label_kpi_quality_vs_price_graph_description": "Comparison of Proposal Quality vs Price Competitiveness based on Reviewer Scores",
    "label_pricing_competitiveness": "Pricing Competitiveness",
    "label_quality_competitiveness": "Quality Competitiveness",
    "label_kpi_project_spend_graph_title": "Project Spend",
    "label_kpi_project_spend_graph_description": "A measure of total spend (current and new) based on Project Cost Management data",
    "label_total_spend": "Total Spend",
    "label_kpi_projcet_savings_graph_title": "Project Savings",
    "label_kpi_projcet_savings_graph_description": "A measure of average savings (target and actual) based on Project Cost Management data",
    "label_average_savings": "Average Savings",
    "label_evaluators_and_advisors": "Evaluators and Advisors",
    "label_kpi_price_range_graph_title": "Potential Price Range",
    "label_kpi_price_range_graph_description": "The sum of the potential price range measured as the difference between the cost of the #1 ranked / Awarded Proposal vs the highest cost alternative.",
    "label_time_of_first_upload": "Time of First Upload",
    "label_time_of_last_upload": "Time of Last Upload",
    "label_best_cost": "Best Cost",
    "label_max_cost": "Max Cost",
    "label_min_cost": "Min Cost",
    "phrase_there_is_currently_nothing_to_display_here": "There is currently nothing to display here.",
    "warning_changing_the_response_template_will_invalidate_1_response_template": "Changing the Vendor Response Template will invalidate at least 1 Vendor Response Template that has already been downloaded.",
    "warning_changing_the_response_template_will_invalidate_x_response_templates": "Changing the Vendor Response Template will invalidate at least %{x} Vendor Response Templates that have already been downloaded.",
    "warning_you_have_invalidated_1_response_template": "You have invalidated 1 Vendor Response Template that has been downloaded.",
    "warning_you_have_invalidated_x_response_templates": "You have invalidated at least %{x} Vendor Response Templates that have been downloaded.",
    "warning_you_must_issue_a_public_notice_to_inform_vendors_of_the_change": "You must issue a Public Notice to inform Vendors of the change.",
    "phrase_click_to_unlock": "Click to unlock.",
    "alert_resource_name_saved": "%{resource} %{name} saved!",
    "label_new_messages_for_project": "New Messages for %{project}",
    "label_new_public_notices_for_project": "New Public Notices for %{project}",
    "label_new_internal_discussions_for_project": "New Internal Discussions for %{project}",
    "keyword_question_subset": "Question Subset",
    "warning_you_may_make_changes_however_this_may_change_the_vendor_response_template": "You may make changes, however, this may change the Vendor Response Template.",
    "phrase_warning_you_are_changing_a_keywordQuestionnaire": "Warning: You are changing a %{keywordQuestionnaire} for an Open Project and there are in-progress or completed submissions.",
    "warning_questionnaire_these_changes_will_invalidate_n_things_explanation": "If you add or change a Question, Question Set or Subset, Vendor Response Options, Response Option Comment Requirements, or the Title or Description of the Questionnaire then you will invalidate at least %{n} %{things}. Changing the Points or Description associated with a Question, the Auto-scoring, or Order of a Question will not invalidate existing templates.",
    "phrase_click_the_lock_icon_to_edit_x": "Click the lock icon to be able to edit this %{x}.",
    "phrase_click_the_lock_icon_to_edit_these_questions": "Click the lock icon to be able to edit these Questions.",
    "phrase_click_the_lock_icon_to_import_questions": "Click the lock icon to be able to import questions.",
    "warning_you_changed_the_vendor_response_template": "You have changed the Vendor Response Template; this means that any vendor that has already downloaded the template needs to download the new version before they can complete their submission. You must issue a public notice to inform them of this.",
    "warning_deleting_x_will_invalidate_responses": "Warning: Deleting this %{x} will invalidate any existing Vendor Response Templates. You will have to release a public notice informing Vendors of this change.",
    "phrase_the_buyer_has_changed_the_template": "The Buyer has changed the Response Template for the document you are trying to upload. Please download the newest version and use that for your submission.",
    "error_x_cannot_be_deleted_because_a_thing_would_be_affected": "This %{x} cannot be deleted because there are Criteria, Responses, or Scores would be affected.",
    "warning_if_you_change_this_questionnaire": "If you change the Title or Description of this Questionnaire",
    "warning_if_you_change_or_add_this_question_set": "If you add a Question Set or change the Title or Order of this Question Set",
    "warning_if_you_add_these_question": "If you add a Questions to this Questionnaire",
    "warning_if_you_change_or_add_this_question": "If you add a Question, or change the Content, Subset, Vendor Response Options, or Response Option Comment Requirements of this Question",
    "warning_if_you_change_these_questions": "If you change the Question Set, Question Content, Vendor Response Options, or Response Option Comment Requirements of these Questions",
    "warning_changing_points_will_not_invalidate": "Changing only the Points, Description, Autoscoring Percentages, or Requiring a Scoring Comment will not invalidate the existing templates.",
    "error_bad_implementation": "Bad Implementation!",
    "label_info_only": "Info Only",
    "error_use_info_only": "Use Info Only",
    "tooltip_info_only_explanation": "Questions marked as info only are shown to the assigned reviewers, but they won't be asked to score it and it will not affect the vendor's score.",
    "tooltip_click_to_score_explanation": "Click here to assign points to this question, this means that the assigned reviewers will see it and be asked to score it.",
    "tooltip_click_to_set_info_only_explanation": "Click here to mark this question as information only, this means that the assigned reviewers will see it, but won't be asked to score it and it will not affect the vendor's score.",
    "warning_you_will_invalidate_n_things": "you will invalidate at least %{n} %{things}",
    "label_vendor_response_template": "Vendor Response Template",
    "label_vendor_response_templates": "Vendor Response Templates",
    "tooltip_info_only_scoring": "This question is for your information only, it does not contribute towards the Vendor's Score or your Scoring completion.",
    "phrase_if_you_would_like_to_mark_a_question_as_info_only": "If you would like to mark a question as Info Only then set the Points for that Question to 0.",
    "phrase_if_you_change_points_change_corresponding_criteria_points": "If you change the total number of points associated with a Question Set you may wish to change the corresponding Criteria Points to match this change.",
    "action_score_question": "Score Question",
    "action_set_as_info_only": "Set as Info Only",
    "phrase_questionnaire_bulk_import_template_example_scoring_instructions_info_only": "Example Scoring Instructions: This question is for information purposes only. It will not be scored.",
    "phrase_seen_by": "Seen by",
    "phrase_no_messages_match_your_fitlers": "No messages match your filters",
    "label_the_following_response_templates_have_been_updated_recently": "The following Response Templates have been updated recently:",
    "label_the_following_response_template_has_been_updated_recently": "The following Response Template has been updated recently:",
    "phrase_if_you_have_a_response_template": "If you have a Response Template that was downloaded before the listed date, then you will have to download a new version to be able to submit that document.",
    "phrase_organizationName_may_have_issued_a_notice": "%{organizationName} may have issued a public notice or addendum about this update.",
    "phrase_please_contact_organizationName_if_you_have_questions": "Please contact %{organizationName} if you have any questions.",
    "phrase_require_scoring_comment": "Require Scoring Comment",
    "tooltip_require_scoring_comment_explanation": "If this option is selected, evaluators will be required to leave a comment when they score this question.",
    "phrase_a_comment_is_required_for_this_question": "A Comment is Required for this Question",
    "phrase_required_comment": "Required comments",
    "label_cut_off_extensions": "Cut Off Extensions",
    "phrase_extension_window_has_changed": "The Extension Window has changed!",
    "phrase_this_auction_can_no_longer_be_extended": "This Auction can no longer be extended.",
    "phrase_bids_within_the_last_t1_of_the_auction_will_extend_the_auction_by_t2": "Bids within the last %{extensionWindow} of the Auction will extend the Auction by %{extensionAmount}.",
    "error_invalid_date": "Invalid Date",
    "keyword_never": "Never",
    "label_on": "On",
    "tooltip_my_opportunities": "My Opportunities includes opportunities that you have been invited to participate in and opportunities that you have created submissions for.",
    "phrase_x_was_updated_by_name_on_date": "%{templateName} was updated by %{organziationName} on %{date}",
    "keyword_declaration": "Declaration",
    "label_opportunity_nda": "Opportunity NDA",
    "label_project_nda": "Project NDA",
    "label_project_coi": "Project COI",
    "error_the_declaration_cannot_be_blank": "The Declaration cannot be blank",
    "label_rfa_request_for_application": "RFA (Request for Application)",
    "keyword_share": "Share",
    "label_share_project": "Share Project",
    "label_share_with_groups": "Share with Groups",
    "label_select_groups": "Select groups",
    "phrase_shared_groups_updated_successfully": "Shared groups updated successfully.",
    "label_shared_by": "Shared By",
    "label_copy_projects": "Copy Projects",
    "label_shared_projects": "Shared Projects",
    "keyword_copy": "Copy",
    "phrase_you_have_selected_num_things_label_to_copy": "You have selected %{numThingsLabel} to copy.",
    "phrase_please_set_department_and_owner_information_below": "Please set the department and owner information below.",
    "label_k_of_n_things_copied": "%{numThingsCopied} of %{numThingsLabel} copied",
    "keyword_tags": "Tags",
    "confirm_are_you_sure_you_want_to_action_the_selected_resources": "Are you sure you want to %{action} the selected %{resources}?",
    "keyword_shared": "Shared",
    "keyword_yours": "Yours",
    "warning_paste_failed_because": "Warning: Paste failed because: ",
    "phrase_you_cannot_paste_onto_basket_rows": "you cannot paste onto Basket rows",
    "phrase_the_following_columns_are_not_editable": "the following columns are not editable: ",
    "label_internal_discussions": "Internal Discussions",
    "label_internal_discussions_for_this_project": "Internal Discussions for this project.",
    "label_public_notices_for_this_project": "Public Notices for this project.",
    "phrase_custom_contract_fields_explanation": "Custom Contract Fields allow you to add your own Fields to a Contract beyond the default attributes in Bonfire. If you assign a Custom Field to one or more Contract Types, it will be available when creating Contracts of only those specific Contract Types, otherwise it will be available for all Contracts.",
    "phrase_contract_type_explanation": "Contract Types are used to label a contract for searching and filtering (a Contract can have at most one type). Additionally you can assign Custom Fields to specific Contract Types.",
    "label_select_users": "Select Users",
    "keyword_sending": "Sending",
    "phrase_click_the_lock_icon_to_be_able_to_add_questions": "Click the lock icon to be able to add Questions.",
    "warning_if_you_change_or_add_this_question_subset": "If you add a Subset or change the Title or Order of this Subset",
    "label_custom_contract_field": "Custom Contract Field",
    "label_adjust_dates": "Adjust Dates",
    "phrase_users_who_will_be_notified_users": "Users who will be notified: %{users}",
    "error_bad_value": "Bad Value!",
    "phrase_must_be_x": "Must be %{x}",
    "label_publish_as_public_notice": "Publish as Public Notice",
    "label_primary_contact": "Primary Contact",
    "action_create_primary_contact": "Create Primary Contact",
    "action_update_primary_contact": "Update Primary Contact",
    "notice_success_primary_contact_updated": "Primary Contact updated successfully",
    "notice_success_primary_contact_created": "Primary Contact created successfully",
    "phrase_primary_contact_description": "Please provide a primary point of contact for your organization in the event that Bonfire needs to contact your organization quickly.",
    "phrase_questionnaire_bulk_import_template_example_scoring_instructions": "Example Scoring Instructions: Meets requirements => 5; Partially meets requirements => 2.5; Does not meet requirements => 0",
    "phrase_adjustable_tooltip": "All Contract Fields are editable. 'Adjustable' Contract Fields are managed through the Contract's Terms and Change Orders, and they are ideal for values you expect to change from Term to Term, such as dates.",
    "tooltip_question_scoring_instructions": "The scoring instructions are only visible internally (i.e. not visible to vendors) and can be used to advise evaluators on how to score the question.",
    "label_date_uploaded": "Date Uploaded",
    "tooltip_custom_contract_fields_apply_to_all_contract_types_unless_otherwise_specified": "Custom Contract Fields apply to all Contract Types unless otherwise specified.",
    "error_please_verify_you_are_not_a_robot": "Please verify that you are not a robot",
    "label_contracts_bulk_import": "Contracts Bulk Import",
    "label_contracts_bulk_import_step_1": "Step 1: Enter the Data",
    "label_contracts_bulk_import_step_2": "Step 2: Label the Data",
    "label_contracts_bulk_import_step_3": "Step 3: Verify and Edit",
    "label_contracts_bulk_import_step_4": "Step 4: Confirm and Import",
    "label_contracts_bulk_import_step_5": "Step 5: Review Contracts",
    "title_bonfire_contracts_import_template": "Bonfire Contracts Import Template",
    "label_can_manage_contract": "Can Manage Contract",
    "label_role_label": "Role Label",
    "phrase_copy_and_paste_your_data_from_excel": "Copy and Paste your data from Excel (tab delimited) into the text box below.",
    "phrase_excel_date_formatting_instruction": "Warning: Before filling in this template, select all of the cells in the worksheet (Ctrl+A) and set the formatting to 'Text' to ensure the correct values are imported.",
    "phrase_contracts_bulk_import_instructions_required_columns": "The following columns are required: %{columns}.",
    "phrase_contracts_bulk_import_instructions_optional_columns": "The following columns are optional: %{columns}.",
    "phrase_contracts_bulk_import_instructions_minimum_terms": "You must include at least one term per contract.",
    "phrase_contracts_bulk_import_instructions_required_term_columns": "The following columns are required for each term: %{columns}.",
    "phrase_contracts_bulk_import_instructions_optional_term_columns": "The following columns are optional for each term: %{columns}.",
    "phrase_contracts_bulk_import_instructions_minimum_people": "People are not required.",
    "phrase_contracts_bulk_import_instructions_required_person_columns": "The following columns are required for each person: %{columns}.",
    "phrase_contracts_bulk_import_instructions_optional_person_columns": "The following columns are optional for each person: %{columns}.",
    "phrase_contracts_bulk_import_instructions_date_format": "All date values must be formatted as '%{format}' and will be parsed in the %{timezone} time zone.",
    "phrase_contracts_bulk_import_instruction_please_verify_the_data": "Please verfiy the data, especially the highlighted rows.",
    "phrase_contracts_bulk_import_instruction_orange_class": "The cells <span %{class}>highlighted orange</span> are fine; they are highlighted to draw your attention to the row.",
    "phrase_contracts_bulk_import_instruction_blue_class": "The cells <span %{class}>highlighted blue</span> do not match any existing records and will cause new entities to be created. Please verify that they are correct.",
    "phrase_contracts_bulk_import_instruction_red_class": "The cells <span %{class}>highlighted red</span> contain invalid data and must be edited.",
    "phrase_contracts_bulk_import_instruction_strikethrough_class": "The cells with <span %{class}>red strikethrough</span> are values that do not apply to the Contract Type and will be ignored.",
    "phrase_contracts_bulk_import_instruction_if_you_find_any_incorrect_data": "If you find any incorrect values, please go back to <strong>Step 3: Verify and Edit</strong> to modify the values.",
    "phrase_double_click_to_edit_cells": "Double-click to edit cells.",
    "error_row_x_must_have_exactly_one_active_term": "Error: Row %{x} must have exactly one (1) Term marked as Active",
    "error_row_x_term_y_must_have_a_startDateField_before_the_endDateField_for_term_y": "Error: Row %{x} Term %{y} must have a %{startDateField} before the %{endDateField}.",
    "phrase_contracts_bulk_import_instructions_empty_terms_and_people": "If some of your contracts have fewer terms or people than others, ensure that all of the columns for those non-existant terms and people are empty.",
    "phrase_contracts_bulk_import_instructions_review_imported_contracts": "The contracts below have been imported successfully. Please review them, and if you need to make edits, you can do so by clicking the link to the individual contract",
    "phrase_contracts_bulk_import_instructions_only_showing_active_term": "Only showing data for the Active Term for each Contract.",
    "phrase_import_more_contracts": "Import More Contracts",
    "phrase_field_must_be_a_valid_url": "%{field} must be be a valid URL (e.g.: http://www.gobonfire.com)",
    "phrase_field_is_an_automatic_increment_field_and_cannot_be_set_manually": "%{field} is an Automatic Increment field and cannot be set manually.",
    "phrase_please_review_your_changes_then_click_confirm_and_import": "Please review your changes, then click <b>Confirm and Import!</b> to save your changes and import the contracts. This cannot be undone.",
    "label_confirm_and_import": "Confirm and Import!",
    "error_x_does_not_exist_for_your_organization": "%{x} does not exist for the organization",
    "error_cannot_set_term_for_field": "Cannot set term for %{field}",
    "error_must_set_term_for_field": "Cannot set term for %{field}",
    "error_term_x_is_missing_startDate_endDate_or_value": "Term %{x} is missing %{startDate}, %{endDate}, or %{value}",
    "error_person_x_is_missing_email_or_can_manage_contract": "Person %{x} is missing 'Email' or 'Can Manage Contract?'.",
    "label_automatic_increment_initial_value": "Initial Value (Integer greater than 0)",
    "phrase_auto_renewable_explanation": "Renewable indicates that this contract has the option to be renewed once expired.",
    "phrase_extendable_explanation": "Extendable indicates that this contract has the option to be extended into future contract terms. (This is only a label and does not automatically extend this contract.)",
    "phrase_field_must_be_between_x_and_y_characters_long": "%{field} must be between %{x} and %{y} characters long.",
    "phrase_confirm_account_email_sent_please_go_to_your_email_and_follow_the_link": "Account Confirmation email sent! Please go to your email Inbox and follow the link in the email to finish confirming your account.",
    "phrase_if_you_do_not_complete_your_account_confirmation_before_that_time": "If you do not complete your account confirmation before that time, you can log in to Bonfire and resend this email.",
    "error_question_response_options_are_too_long_for_question": "Error: Response Options are too long for Question",
    "phrase_if_a_response_option_requires_extra_context_or_instructions": "If a Response Option requires extra context or instructions, please include them in the Question.",
    "keyword_library": "Internal Library",
    "keyword_community": "Community",
    "label_copy_contracts": "Copy Contracts",
    "phrase_please_set_department_information_below": "Please set the department information below.",
    "label_share_contract": "Share Contract",
    "keyword_performance": "Performance",
    "label_vendor_performance_survey": "Vendor Performance Survey",
    "label_vendor_performance_surveys": "Vendor Performance Surveys",
    "label_vendor_performance_score": "Vendor Score",
    "label_survey_score": "Survey Score",
    "label_contract_performance_score": "Contract Score",
    "label_select_a_survey": "Select a survey",
    "label_survey_start": "Survey start",
    "label_survey_end": "Survey end",
    "phrase_additional_vpm_info_a": "For additional information regarding vendor performance management, please take a look at ",
    "phrase_additional_vpm_info_b": "our support article",
    "phrase_note_vpm_form_save": "Note: To enable the Save button make sure you fill out all the required fields under each tab.",
    "keyword_respondent": "Respondent",
    "keyword_respondents": "Respondents",
    "phrase_delete_respondent": "Delete Respondent",
    "phrase_how_likely_are_you_to_recommend_vendor": "How likely are you to recommend %{vendor} to a friend or colleague?",
    "keyword_inactive": "Inactive",
    "label_rated_scale": "Rated Scale",
    "keyword_repeats": "Repeats",
    "phrase_not_at_all_likely": "Not at all likely",
    "phrase_extremely_likely": "Extremely likely",
    "label_remove_respondent_from_survey": "Remove Respondent from Survey",
    "label_date_responded": "Date Scored",
    "phrase_contracts_bulk_import_instructions_true_false_columns": "Columns that require True/False values, such as 'Renewable?', end in a question mark ('?') and accept only '1' or '0' to represent 'true' and 'false', respectively.",
    "keyword_weekly": "Weekly",
    "keyword_monthly": "Monthly",
    "keyword_quarterly": "Quarterly",
    "keyword_yearly": "Yearly",
    "survey_not_configured": "Survey not configured",
    "phrase_no_vendor_performance_feature_preference_explanation": "You may edit existing Surveys, but you cannot activate any inactive Surveys or add new Respondents to any Survey.",
    "label_bulk_configure_vendor_performance_surveys": "Bulk Configure Vendor Performance Surveys",
    "phrase_warning_this_will_create_x_and_update_y_any_changes_will_apply_to_z": "Warning: This will create %{x} and update %{y}. Any changes will apply to %{z}",
    "phrase_this_will_create_x_and_y_changes_will_apply_to_z": "This will create %{x} and %{y}. Changes will apply to %{z}",
    "label_edit_vendor_performance_surveys_and_respondents": "Edit Vendor Performance Surveys and Respondents",
    "phrase_this_will_only_add_respondents_to_each_affected_vendor_performance_survey": "This will only add respondents to each affected Vendor Performance Survey. No respondent will be removed by this action.",
    "phrase_minimum_bid_decrement_amount_explanation_for_vendors": "Each bid must be this much lower than the previous bid",
    "keyword_quarter": "Quarter",
    "keyword_quarters": "Quarters",
    "phrase_you_have_already_responded_to_the_survey_this_x": "You have already responded to the survey this %{x}.",
    "phrase_you_have_already_responded_to_this_survey": "You have already responded to this survey",
    "phrase_tell_us_what_you_think_about_x": "Tell us what you think about %{x}",
    "phrase_how_likely_are_you_to_recommend_the_following_vendor_to_a_friend_or_colleague": "How likely are you to recommend the following company to a friend or colleague?",
    "phrase_please_dont_forward_this_email": "Please don't forward this email.",
    "phrase_it_was_generated_specifically_for_you": "It was generated specifically for you.",
    "phrase_why_am_i_getting_this_email": "Why am I getting this email?",
    "phrase_your_organization_is_collecting_feedback_on_this_vendor_on_a_cadence_basis": "Your organization is collecting feedback on this vendor on a %{cadence} basis and you are one of the current respondents.",
    "phrase_your_response_score_has_been_recorded": "Your response (%{score}) has been recorded",
    "phrase_thank_you": "Thank You!",
    "label_trend": "Trend",
    "keyword_requested": "Requested",
    "label_freshness": "Freshness",
    "keyword_recent": "Recent",
    "phrase_award_statement_variable_interpolation": "You can use the following tokens which will be replaced when the Public Award Notice is created: ",
    "label_award_proposal": "Award Proposal",
    "label_public_award_statement": "Public Award Statement",
    "action_post_public_notice": "Post Award Statement Publicly",
    "label_internal_notes": "Internal Notes",
    "label_date_awarded": "Date Awarded",
    "keyword_award": "Award",
    "phrase_x_will_be_replaced_by_y": "%{x} will be replaced by %{y}",
    "phrase_your_organization_name": "your Organization's Name",
    "phrase_this_project_name": "this Project's name",
    "phrase_this_project_reference_number": "this Project's Reference #",
    "phrase_the_awarded_proposal_value": "the awarded Proposal's Value",
    "phrase_the_vendor_address": "the Vendor's Address",
    "phrase_the_vendor_name": "the Vendor's Organization Name",
    "phrase_the_date_the_proposal_was_awarded": "the date the Proposal was awarded",
    "phrase_no_x_provided": "No %{x} Provided",
    "phrase_tied_for_x": "Tied for %{x}",
    "keyword_timezone": "Timezone",
    "keyword_default": "Default",
    "title_browser_incompatibility": "Browser Incompatibility",
    "phrase_please_upgrade_your_browser_to_use_bonfire": "Please upgrade your browser to use Bonfire.",
    "phrase_bonfire_is_built_using_the_latest_technology": "Bonfire is built using the latest technology, allowing Bonfire to be faster, smarter, and easier to use.",
    "phrase_unfortunately_your_browser_is_not_up_to_date": "Unfortunately, your browser is not up to date and does not support these technologies.",
    "phrase_please_use_one_of_the_following_browsers": "Please use one of the following browsers to access Bonfire:",
    "phrase_vendor_comment_type_explanation": "Response comments can be required, optional, or not allowed based on the vendor's response.",
    "label_not_allowed": "Not Allowed",
    "phrase_incomplete_x": "Incomplete %{x}",
    "phrase_comment_that_is_not_allowed": "Comment that is not allowed",
    "phrase_comments_that_are_not_allowed": "Comments that are not allowed",
    "phrase_a_comment_is_required_for_this_response": "A comment is required for this response",
    "phrase_a_response_comment_is_required": "A response comment is required",
    "phrase_the_comment_must_be_left_blank_for_this_response": "The comment must be left blank for this response",
    "phrase_response_must_be_one_of": "Response must be one of",
    "phrase_type_response_comment_with_arrow": "Type a Response Comment -->",
    "phrase_response_option_comment_requirements": "Response Option Comment Requirements",
    "phrase_response_option_comment_requirements_input_conditions": "Response Option Comment Requirements '%{input}' must be a comma-separated values string with %{count} non-blank values of Required, Optional, or Not Allowed",
    "tooltip_bid_table_description": "The description is shown in the Vendor Response Template and may be used to provide instructions on how to properly fill out the template.",
    "label_item_column_header_name": "Item Column Header Name",
    "tooltip_item_column_header_name": "Use this field to set a custom header name for the Item column in the Response Template and Order Lists. Leave this field blank to use the default header name.",
    "label_allow_additional_responses": "Allow Additional Responses?",
    "tooltip_allow_additional_responses": "If you select 'Yes', then the Response Template will have a worksheet where vendors can provide additional responses for each of the items.",
    "error_auction_has_been_deleted": "Auction has been deleted",
    "title_scoring_instructions_internal": "Scoring Instructions (Internal)",
    "keyword_scoring_instructions": "Scoring Instructions",
    "error_duplicate_values_are_not_permitted_multiple": "Duplicate values are not permitted. The following values have duplicates: ",
    "error_duplicate_values_are_not_permitted_single": "Duplicate values are not permitted. The following value has a duplicate: ",
    "heading_back_to_opportunity": "Back to Opportunity",
    "label_acan_advanced_contract_award_notification": "ACAN (Advanced Contract Award Notification)",
    "phrase_vendor_response_options_must_be_unique": "Vendor Response Options must be unique (Case-insensitive)",
    "label_allow_no_bids": "Allow No Bids?",
    "tooltip_allow_no_bids": "If you select 'Yes', then vendors are not required to bid on every item.",
    "instructions_you_must_bid_on_every_item": "You must bid on every item. To do so, all of the editable cells for the item must contain a valid value.",
    "phrase_bid_you_filled_in_some_of_the_values_for_item_but_didnt_provide_all_values_must_bid": "You did not provide all of the values for %{item}. Please fill in all of the values.",
    "warning_question_with_not_allowed_comment_will_be_ignored": "Any question that had a comment where one was not allowed will be treated as if it was left empty.",
    "error_this_row_will_be_ignored": "Anything entered in this row will be ignored",
    "phrase_commas_and_tildes_are_not_permitted_within_any_individual_response_option": "Commas (',') and tildes ('~') are not permitted within any individual response option",
    "phrase_invalid_leading_character_for_response_option": "Individual response options cannot begin with an equals sign (=) or a single quote mark (')",
    "error_account_locked": "Your account has been locked in order to ensure its security. You will be redirected to the login page in 5 seconds",
    "title_account_lockout": "Bonfire Account Locked",
    "phrase_this_account_is_locked_out": "Your account has been locked due to a high number of login attempts. You can either reset your password or contact Support to unlock it.",
    "error_you_must_select_comment_requirements_if_you_change_reasons": "You must select Comment Requirements if you change the Vendor Response Options",
    "error_all_reason_need_response_comment_types": "Ensure all Vendor Response Options have a Comment Requirement selected",
    "error_value_is_not_valid_for_this_organization": "Error: '%{value}' is not valid for the Organization. Please delete the value and try again. If the problem persists, contact Support@GoBonfire.com",
    "phrase_commas_and_tildes_are_special": "Commas (,) and tildes (~) are 'special'",
    "phrase_avoid_using_them_in_the_text_of_your_thing": "Avoid using them in the text of your %{thing}.",
    "phrase_commas_and_tildas_are_special_response_option_example": "Example: a response option of 'yes, I agree' will cause an error",
    "phrase_bulk_import_questions_response_options_instructions_1": "For each question, indicate any possible answers a vendor can select as a comma-separated list",
    "phrase_bulk_import_questions_response_options_instructions_1_examples": "Examples: 'True, False', 'Yes, No, Maybe'",
    "phrase_bulk_import_questions_response_options_instructions_1_conditions": "This field cannot begin with an equals sign (=) or single quotation mark (')",
    "phrase_bulk_import_questions_response_options_instructions_2": "If this column is included, you <b>must also include 'Response Options Comment Requirements'</b>",
    "phrase_bulk_import_questions_comment_requirements_instructions_1": "Must be included with 'Vendor Response Options'",
    "phrase_bulk_import_questions_comment_requirements_instructions_2": "For each possible response to a question, indicate the comment requirements as a comma-separated list",
    "phrase_bulk_import_questions_comment_requirements_instructions_3": "Comment requirements include:",
    "phrase_bulk_import_questions_comment_requirements_instructions_4": "There must be a Comment Requirement for each Response Option on a given question",
    "phrase_bulk_import_questions_comment_requirements_example_1": "When the vendor responds 'True' to question (1.1), the comment field becomes required.",
    "phrase_bulk_import_questions_comment_requirements_example_2": "When the vendor responds 'Fully Compliant' to question (1.2), the comment field is restricted, and will show an error if text is entered.",
    "label_questionnaire_setup": "Questionnaire Setup",
    "label_vendor_response": "Vendor Response",
    "label_comment_requirements": "Comment Requirements",
    "phrase_fully_compliant": "Fully Compliant",
    "phrase_partially_compliant": "Partially Compliant",
    "phrase_bulk_import_questions_scoring_percentages_instructions_1": "Set these values to pre-define auto-scoring calculations",
    "phrase_bulk_import_questions_scoring_percentages_instructions_2": "Can be blank, or a comma-separated string with a value for every Vendor Response Option on a given question",
    "phrase_bulk_import_questions_scoring_percentages_instructions_3": "Each value must be a number between 0 and 100",
    "phrase_bulk_import_questions_scoring_percentages_example_1": "When the vendor responds 'True' to question (1.1), they receive an auto-score of 100%",
    "phrase_bulk_import_questions_scoring_percentages_example_2": "When the vendor responds 'Partially Compliant' to question (1.2), they receive an auto-score of 50%",
    "label_score_percentages": "Score Percentages",
    "phrase_bulk_import_questions_points_instructions_1": "Set the maximum point value for the question",
    "phrase_if_you_included_respose_options_you_must_include_comment_requirements": "If you included Vendor Response Options you must include Response Option Comment Requirements",
    "phrase_if_you_believe_this_message_is_in_error": "If you believe this message is in error",
    "label_click_here_for_troubleshooting_instructions": "Click Here For Troubleshooting Instructions",
    "label_troubleshooting_instructions": "Troubleshooting Instructions",
    "phrase_try_clearing_your_browser_data": "Try clearing your browsing data",
    "phrase_try_browsing_in_private_or_incognito_mode": "Try browsing in Private or Incognito Mode",
    "phrase_try_using_a_different_computer_or_network": "Try using a different computer or network",
    "phrase_if_the_problem_persists_contact_support": "If the problem persists, contact Support@GoBonfire.com",
    "label_chrome": "Chrome",
    "label_microsoft_edge": "Microsoft Edge",
    "label_firefox": "Firefox",
    "phrase_clear_browsing_data_instructions_chrome": "Click 'Chrome' > 'Clear Browsing Data...'",
    "phrase_clear_browsing_data_instructions_edge": "Click 'Hub' > 'History' > 'Clear all history'",
    "phrase_clear_browsing_data_instructions_firefox": "Click 'History' > 'Clear Recent History'",
    "phrase_use_private_mode_instructions_chrome": "Click 'File' > 'New Incognito Mode'",
    "phrase_use_private_mode_instructions_edge": "Click 'More' > 'New InPrivate window'",
    "phrase_use_private_mode_instructions_firefox": "Click 'Tools' > 'Private Browsing Mode'",
    "phrase_please_include_this_line_in_your_message_to_Support": "Please include this line in your message to Support",
    "phrase_my_user_agent_string_is": "My user agent string is",
    "error_cell_must_be_numeric_part_1": "Error: Cell",
    "error_cell_must_be_numeric_part_2": "must be Numeric",
    "error_not_bidding_status_part_1": "Error: Clear values for",
    "error_not_bidding_status_part_2": "or select '%{bidLabel}'",
    "label_not_bidding": "Not Bidding",
    "error_bidding_status": "Error: Missing value for",
    "label_success_all_values_provided": "Success: All values provided",
    "error_invalid_bid_no_bid_decision": "Error: Invalid Bid/No Bid Decision",
    "keyword_rating": "Rating",
    "phrase_there_is_a_consensus_if_all_of_the_scores_in_the_total_are_within_30_percent": "There is a Consensus if all of the scores the total Rating are within 30% of each other",
    "phrase_your_response_has_been_recorded_you_can_add_a_comment": "Your response has been recorded. You can add more detail and/or modify your choice below.",
    "label_detailed_results": "Detailed Results",
    "phrase_enter_additional_instructions_for_this_item": "Enter any additional instructions for this %{item}. These will be displayed to the vendors in the template",
    "phrase_this_respondent_has_not_submitted_any_scores_yet": "This respondent has not submitted any scores yet",
    "phrase_this_respondent_has_not_submitted_any_older_scores": "This respondent has not submitted any older scores",
    "label_success_all_data_is_valid": "Success: All data is valid!",
    "error_check_cells": "Error: Check cell(s)",
    "error_please_provide_a_value_in_the_number_column": "Error: Please provide a value in the '#' column",
    "label_download_questionnaire": "Download Questionnaire",
    "title_additional_instructions": "Additional Instructions",
    "instructions_subset_instructions": "If specific instructions have been provided for a given subset, they will appear as a tooltip for a purple cell. Mouse-over to review them.",
    "error_all_other_fields_must_be_blank_for_instructions": "Every field other than Question Set and Question Subset must be blank for Vendor Instructions",
    "error_question_set_must_be_assigned_for_subset_instructions": "Adding instructions to a Question Subset requires the Question Set to be assigned",
    "error_name_must_be_a_thing_that_already_exists_or_will_be_created": "%{name} must be a %{thing} that already exists or will be created.",
    "phrase_add_instructions_to_a_questionnaire": "To add instructions to a Questionnaire leave the entire row empty except for the Instructions column. Only one row should be used for Questionnaire Instructions, others will be ignored.",
    "phrase_add_instructions_to_a_question_set": "To add instructions to a Question Set leave the entire row empty except for the Instructions and Question Set columns. Only one row should be used per Question Set, others will be ignored.",
    "phrase_add_instructions_to_a_question_subset": "To add instructions to a Question Subset leave the entire row empty except for the Instructions, Question Set, and Question Subset columns. Only one row should be used per Question Subset, others will be ignored.",
    "title_bonfire_bidtable_import_template": "Bonfire BidTable Import Template",
    "phrase_all_the_information_that_is_not_kept_in_the_merged_record_will_be_deleted": "All the information that is not kept in the merged Vendor record will be deleted.",
    "phrase_this_will_delete_all_the_performance_survey_data_for_vendor": "This will delete all the Performance Survey data for %{vendor}, are you sure you want to do this?",
    "tooltip_column_format_cannot_be_changed": "The format cannot be changed after the column is created.",
    "tooltip_column_order_may_be_changed_without_invalidating_templates": "The Order # may be changed without invalidating the existing Vendor Response Templates.",
    "label_calculated_columns": "Calculated Columns",
    "label_pause_respondents": "Pause Respondents",
    "label_restart_respondents": "Restart Respondents",
    "label_resend_survey": "Resend Survey",
    "phrase_inactive_respondents_are_not_surveyed": "Inactive Respondents will not be surveyed automatically",
    "label_not_scorable": "Not Scorable",
    "tooltip_not_scorable_information": "This question is being shown to you, but has not been assigned for you to score.",
    "label_configure_vendor_performance_survey": "Configure Vendor Performance Survey",
    "label_configure_vendor_performance_surveys": "Configure Vendor Performance Surveys",
    "keyword_configure": "Configure",
    "keyword_for": "for",
    "label_send_vendor_performance_surveys_now": "Send Vendor Performance Surveys NOW",
    "phrase_for_all_selected_active_surveys": "for all selected Active Surveys",
    "phrase_only_to_these_respondents": "Only to these Respondents",
    "phrase_only_to_active_respondents": "Only to Active Respondents",
    "label_date_downloaded": "Date Downloaded",
    "label_performance_rating": "Performance Rating",
    "label_performance_trend": "Performance Trend",
    "label_no_change": "No Change",
    "phrase_up_from_x": "Up from %{x}",
    "phrase_down_from_x": "Down from %{x}",
    "keyword_risk": "Risk",
    "label_download_all_contract_documents": "Download All Contract Documents",
    "error_another_session_open_1": "Your account is already active in another window or on another computer.",
    "error_another_session_open_2": "Click 'Continue' to log in here and end all other sessions",
    "error_reload_page": "An error occured, please reload the page",
    "error_single_session_modal": "Your session has expired. We will redirect you to the login page in a few seconds so you can resume your work in this tab. <a href='/login'>Go there now</a>",
    "error_single_session_login": "Your session has expired. Please login again to resume your work in this tab",
    "phrase_respondents_receive_this_survey_by_email_frequency": "Respondents receive this survey by email %{frequency}.",
    "phrase_next_survey_is_date": "Next survey is %{date}.",
    "label_vendors_at_risk": "Vendors At Risk",
    "phrase_vendors_at_risk_kpi_tooltip": "A Vendor is considered 'At Risk' if their performance rating is less than or equal to %{x}.",
    "phrase_you_have_not_configured_any_vendor_performance_surveys": "You currently have not configured any vendor performance surveys. Select vendors below and use the actions dropdown to start collecting results.",
    "phrase_vendor_performance_score_freshness_tooltip": "The Freshness score is the percentage of Scores used to calculate the Rating that are from this %{repeatPeriod}.",
    "keyword_trend": "Trend",
    "keyword_engagement": "Engagement",
    "label_total_respondents": "Total Respondents",
    "label_scores_current": "Scores Current",
    "label_scores_previous": "Scores Previous",
    "label_no_score_in_last_two": "No Score in Last Two",
    "label_vendor_feedback_engagement": "Survey Engagement",
    "phrase_vendor_feedback_engagement_tooltip": "Survey engagement is the average engagement of all surveys",
    "phrase_no_comments": "No comments",
    "phrase_not_engaged": "Not engaged",
    "phrase_total_engagement_points_tooltip": "Each respondent who has responded very recently contributes 1 point towards the Total Score. Each respondent who has responded somewhat recently contributes 0.5 points. All others count for 0 points.",
    "phrase_public_vendor_custom_field": "Public fields will be displayed to any viewer when a vendor is listed as a document taker on a project, or when the vendor is publicly awarded a bid.",
    "label_vendor_performance_rating": "Vendor Performance Rating",
    "label_generic_contract": "Generic Contract",
    "keyword_cadence": "Cadence",
    "keyword_update": "Update",
    "phrase_emails_will_not_be_sent_for_this_survey": "Emails will not be sent for this survey because it is Inactive",
    "label_email_address": "Email Address",
    "action_remove_x": "Remove %{x}",
    "keyword_pause": "Pause",
    "keyword_restart": "Restart",
    "label_add_respondents_from_contracts": "Add Respondents from Contracts",
    "phrase_number_people_are_currently_participating_in_the_survey_for_vendor": "%{number} people are currently participating in the survey for %{vendor}.",
    "phrase_number_people_responded_to_the_survey_for_vendor_this_repeatPeriod": "%{number} people responded to the survey for %{vendor} this %{repeatPeriod}.",
    "phrase_number_people_responded_to_the_survey_last_repeatPeriod_but_not_this_repeatPeriod": "%{number} people responded to the survey last %{repeatPeriod}, but not this %{repeatPeriod}.",
    "phrase_number_people_have_not_responded_in_the_last_countRepeatPeriods_repeatPeriods": "%{number} people have not responded in the last %{countRepeatPeriods} %{repeatPeriods}. These respondents may need a reminder.",
    "phrase_vendor_performance_survey_engagement_score_total_tooltip": "Engagement for this vendor survey is %{engagementScorePercentage}. Scores from this %{repeatPeriod} receive 1 point, scores in the previous %{repeatPeriod} receive 0.5 points. The total of these scores is divided by the maximum score to determine the engagement value. The maximum score is the total number of active respondents (%{countRespondents}).",
    "label_next_survey": "Next Survey",
    "cal_mondays": "Mondays",
    "cal_tuesdays": "Tuesdays",
    "cal_wednesdays": "Wednesdays",
    "cal_thursdays": "Thursdays",
    "cal_fridays": "Fridays",
    "cal_saturdays": "Saturdays",
    "cal_sundays": "Sundays",
    "phrase_survey_will_be_sent_immediately_and_following_survey_will_be_sent_date": "Survey will be sent <b>immediately</b> to <b>%{countRespondents} %{respondentsSinglePlural}</b>. The following survey will be sent <b>%{date}</b>, and will repeat %{rule}.",
    "phrase_vendor_performance_survey_repeat_rule": "%{repeatPeriodAdverb} on the <b>%{ordinal} %{weekday}</b> of the %{repeatPeriod}",
    "phrase_survey_will_be_sent_date_to_countRespondents_and_will_repeat_rule": "Survey will be sent <b>%{date}</b> at <b>9:00AM</b> to <b>%{countRespondents} %{respondentsSinglePlural}</b>, and will repeat %{rule}.",
    "phrase_or_the_last_weekday_of_the_repeatPeriod": "or the last %{weekday} of the %{repeatPeriod} in %{repeatPeriods} with fewer than %{count} %{weekdays}",
    "phrase_vendor_performance_score_scores_tooltip": "The number of individual Respondent Scores used to calculate the Rating, which includes the most recent Score per Respondent from this %{repeatPeriod} or last %{repeatPeriod}.",
    "phrase_evaluation_group_number_name_is_scoring_a_of_b_questions_worth_c_of_d_pts": "<b>Evaluation Group %{number}</b> - %{name} is scoring %{x} of %{y} questions worth %{a} of %{b} pts.",
    "phrase_user_is_scoring_questions_worth_x_of_y_points": "%{user} is scoring Questions worth %{x} of %{y} points for this Question Set.",
    "phrase_this_evaluation_group_is_scoring_questions_worth_x_of_y_points": "This Evaluation Group is scoring Questions worth %{x} of %{y} points for this Questionnaire.",
    "label_organization_vendor_record": "Organization Vendor Record",
    "label_global_vendor_record": "Global Vendor Record",
    "phrase_organization_vendor_record_description": "Your Organization Vendor Record is the record that %{organization} has on file and maintains internally. You may edit this record. Changes here, however, will only be reflected for %{organization}. Additionally, %{organization} may edit this record as well.",
    "phrase_global_vendor_record_description": "Your Global Vendor Record is the master record that you maintain on Bonfire and is used to create a new Organization Vendor Record when you log in at an Organization on Bonfire for the first time. Only you may edit this record. Additionally, this record can be used to subscribe to opportunity matching notifications based on Commodity Codes.",
    "label_select_specific_subregions": "Select Specific Subregions",
    "label_select_regions": "Select Regions",
    "phrase_no_vendors_for_your_account": "There are no vendors associated with your account. A vendor will be created for you the first time you prepare a submission. If you have any questions, please contact Support@GoBonfire.com.",
    "label_notify_me_of_opportunities_that_match_commodities": "Notify me of any opportunities that match my commodity codes",
    "tooltip_notify_me_of_opportunities": "Bonfire will send you email notifications as you are matched with opportunities.",
    "label_ss_sole_source": "SS (Sole Source)",
    "label_bi_best_interest": "BI (Best Interest)",
    "label_share_vendor_performance_survey": "Share Vendor Performance Survey",
    "label_copy_vendor_performance_surveys": "Copy Vendor Performance Surveys",
    "phrase_warning_this_will_overwrite_survey_data_for_the_following_vendors": "Warning: This will delete the existing survey data for the following vendors: %{vendors}. Do you wish to continue?",
    "phrase_you_have_no_vendors_associated_with_your_organization": "You have no Vendors associated with your Organization yet. Please copy some Projects or Contracts first, then try this again.",
    "phrase_please_set_the_vendor_and_dates_information_below": "Please set the Vendor and Dates information below.",
    "keyword_unique": "Unique",
    "keyword_once": "Once",
    "phrase_your_organization_is_collecting_feedback_on_this_vendor_once": "Your organization is collecting feedback on this vendor once and you are one of the current respondents.",
    "phrase_survey_will_be_sent_date_to_countRespondents_once": "Survey will be sent <b>%{date}</b> to <b>%{countRespondents} %{respondentsSinglePlural}</b> once and will not repeat.",
    "phrase_survey_will_be_sent_immediately_and_following_survey_will_be_sent_date_once": "Survey will be sent <b>immediately</b> to <b>%{countRespondents} %{respondentsSinglePlural}</b>. The following survey will be sent once <b>%{date}</b>, and will not repeat.",
    "phrase_vendor_performance_score_scores_tooltip_for_once": "The number of individual Respondent Scores used to calculate the Rating, which includes the most recent Score per Respondent since %{date}.",
    "phrase_vendor_performance_score_freshness_tooltip_for_once": "The Freshness score is the percentage of Scores used to calculate the Rating that were recorded since %{date}.",
    "phrase_number_people_responded_to_the_survey_for_vendor_since_date": "%{number} people responded to the survey for %{vendor} since %{date}.",
    "label_no_score_since_one_time_survey": "No Score since One-Time Survey",
    "phrase_number_people_have_not_responded_since_one_time_survey_was_sent_date": "%{number} people have not responded since the one-time survey was sent %{date}. These respondents may need a reminder.",
    "phrase_error_cannot_send_survey_now_for_vendor_because_the_survey_is_not_open": "Error: Cannot send survey now for %{vendor} because the survey is not open.",
    "phrase_you_have_until_date_to_complete_this_survey": "You have until %{date} to complete this survey.",
    "phrase_error_cannot_send_survey_now_for_vendor_because_one_time_survey": "Error: Cannot send survey now for %{vendor} because they have a one-time survey configured.",
    "phrase_bolded_opportunities_most_likely_good_match": "Bolded opportunities are most likely to be a good match.",
    "document_reprocess_message": "We’ve improved your document experience! Please refresh the page to transition to the new document viewer",
    "error_fetching_notes": "There was an error in opening the notes. Please try again. If this problem persists contact support@gobonfire.com",
    "phrase_send_survey_now": "Send survey now",
    "phrase_send_survey_now_tooltip": "Check this box to survey all active respondents immediately after saving. Regular surveys will follow on the Next Survey date, and repeat at your selected Cadence.",
    "label_public_contracts": "Public Contracts",
    "phrase_there_are_no_public_contracts_to_view_at_this_time": "There are no public contracts to view at this time.",
    "phrase_public_contract_key_add_edit_detail_explanation": "This field is public, and appears on the Public Portal if the contract is public.",
    "phrase_public_contract_key_tooltip": "Public fields are those that will appear on the Public Portal if the contract is public.",
    "label_login_to_download": "Log in to Download",
    "label_login_to_download_all_documents": "Login to download all documents",
    "heading_create_contract_from_template": "Create Contract From Template",
    "phrase_for_public_template": "Public contract templates will not be displayed to the public but contracts created from public templates will",
    "phrase_public_contract_tooltip": "Public contracts appear on the Public Portal.",
    "phrase_public_contract_document_tooltip": "This document is public, and appears on the Public Portal.",
    "label_download_all_active_proposals": "Download all active proposals",
    "label_download_all_eliminated_proposals": "Download all eliminated proposals",
    "phrase_you_cannot_select_this_vendor_because_this_would_delete_a_survey_you_want_to_copy": "You cannot select this Vendor because this would delete a survey you want to copy",
    "phrase_public_contract_term_unavailable": "Terms for this contract are currently unavailable.",
    "phrase_vendor_performance_survey_end_date_tooltip": "Survey will no longer be sent and responses will not be accepted on this date.",
    "phrase_end_date_must_be_a_valid_date_after_start_date": "End Date must be a valid date after Start Date",
    "heading_contracts_search": "Search Contracts",
    "heading_documents": "Documents",
    "heading_details": "Details",
    "heading_people": "People",
    "heading_contract_name": "Contract Name",
    "heading_department_name": "Department Name",
    "heading_vendor_name": "Vendor Name",
    "heading_contract_type": "Contract Type",
    "heading_description": "Description",
    "heading_name": "Name",
    "heading_email": "Email",
    "heading_filename": "Filename",
    "phrase_search": "Search...",
    "phrase_search_contracts": "You can search for details, documents and people",
    "tooltip_question_set_with_multiple_evaluation_groups": "This Question Set cannot be modified here because the questions have been assigned to multiple Evaluation Groups. Go to Questionnaires to make further changes.",
    "label_first": "First",
    "label_last": "Last",
    "label_showing": "Showing",
    "label_result": "result",
    "phrase_out_of": " out of ",
    "phrase_no_results": "No results found",
    "label_custom_fields": "Custom Fields",
    "label_registration_settings": "Registration Settings",
    "phrase_add_vendor_types_by_clicking_plus_button": "Click on the + button beside each Vendor Type to add",
    "label_select_vendor_types": "Select Your Vendor Types",
    "action_complete_additional_information": "Complete Additional Information",
    "phrase_complete_section_based_off_vendor_type": "Based on your selected Vendor Type, please complete the following sections",
    "label_vendor_types": "Vendor Types",
    "label_relevant_vendor_types": "Relevant Vendor types",
    "label_vendor_registration_type": "Vendor Type",
    "phrase_this_vendor_type_has_fields_documents_and_vendors_associated_with_it": "This Vendor Type has %{fields}, %{documents}, and %{vendors}. Deleting this type will remove the types from those resources.",
    "label_notice_of_availability": "NOA (Notice of Availability)",
    "label_complete_now": "Complete Now",
    "label_vendor_source": "Vendor Source",
    "phrase_your_response_has_been_recorded": "Your response has been recorded successfully.",
    "phrase_window_can_be_closed": "This window can now be closed.",
    "label_close_window": "Close Window",
    "keyword_survey": "Survey",
    "label_step_2_account_information": "Step 2: Account Information",
    "label_profile": "Profile",
    "tooltip_can_expire_requested_vendor_document": "Buyers and Vendors can make a document expire on a specific date",
    "label_expiration_date": "Expiration Date",
    "label_expires_on": "Expires On",
    "phrase_vendor_performance_survey_you_cannot_submit_response": "You cannot submit a response.",
    "tooltip_required_vendor_information": "There are required fields in this section that you have not filled in.",
    "label_decision_sensitivity_analysis": "Decision Sensitivity Analysis",
    "keyword_decreased": "decreased",
    "keyword_increased": "increased",
    "criteria_sensitivity_explanation": "This scenario is <strong>sensitive</strong> because this lower ranked vendor could displace the top ranked vendor if they made improvements in the highlighted criteria.",
    "criteria_consensus_explanation": "A criteria <strong>lacks consensus</strong> if the high scorer and the low scorer differ by 30% of the maximum score.",
    "phrase_if_average_score_is_improved_to_x_proposal_1_is_displaced": "If this proposal improved on this criteria to <strong>%{x}</strong>, this Proposal would displace the Rank 1 Proposal.",
    "phrase_if_question_set_total_score_is_improved_to_x_proposal_1_is_displaced": "If this proposal improved on this Question Set to <strong>%{x}</strong>, this Proposal would displace the Rank 1 Proposal.",
    "phrase_scoring_summary_sensitivity_warning_explanation": "This scenario is <strong>sensitive</strong> because this lower ranked vendor could displace the top ranked vendor if they made improvements in the highlighted criteria.",
    "phrase_criteria_is_sensitive_and_lacks_consensus": "This scenario is <strong>sensitive</strong> and <strong>lacks consensus</strong>.",
    "phrase_criteria_has_consensus_and_sensitivity": "This scenario has consensus, but still is <strong>sensitive</strong>.",
    "phrase_x_is_sensitive": "This %{x} is <strong>sensitive</strong>.",
    "phrase_criteria_lacks_consensus": "This criteria <strong>lacks consensus</strong>.",
    "phrase_project_has_x_sensitive_criteria": "This project has %{x} sensitive scenarios.",
    "phrase_project_sensitivity_explanation": "A project is <strong>sensitive</strong> if a lower ranked proposal can displace the top ranked proposal by making improvements in one criteria.",
    "label_hide_analysis": "Hide Analysis",
    "label_show_analysis": "Show Analysis",
    "phrase_vendor_has_x_sensitive_criterion": "This vendor has %{x} <strong>sensitive scenarios</strong>: ",
    "phrase_there_are_x_out_of_y_projects_in_z_that_are_sensitive": "There are %{x} out of %{y} projects in %{z} that are <strong>sensitive</strong>.",
    "keyword_sensitivity": "Sensitivity",
    "phrase_lack_of_consensus": "Lack of Consensus",
    "phrase_evaluator_scores_are_sensitive": "Bonfire has determined that evaluator scores are sensitive and can affect the proposal ranking.",
    "phrase_how_likely_are_you_to_recommend_the_x_vendor_to_a_friend_or_colleague": "How likely are you to recommend '%{vendor}' to a friend or colleague?",
    "keyword_frequency": "Frequency",
    "label_performance_surveys": "Performance Surveys",
    "phrase_please_update_this_document_before_they_expire": "Please update this document before it expires.",
    "phrase_please_update_these_documents_before_they_expire": "Please update these documents before they expire.",
    "phrase_you_have_x_expiring_documents": "You have %{x} documents expiring soon.",
    "phrase_you_have_x_expiring_document": "You have %{x} document expiring soon.",
    "phrase_you_have_x_expired_documents": "You have %{x} expired documents.",
    "phrase_you_have_x_expired_document": "You have %{x} expired document.",
    "phrase_please_update_these_documents": "Please update these documents.",
    "phrase_please_update_this_document": "Please update this document.",
    "phrase_x_is_expiring_on_y": "%{x} is expiring on %{y}",
    "phrase_x_expired_on_y": "%{x} expired on %{y}",
    "label_date_registered": "Date Registered",
    "label_document_expires": "Document Expires",
    "label_you_are_editing": "You are editing the",
    "keyword_section": "section",
    "label_hidden_purchaser_column": "Purchaser Hidden Column",
    "label_hidden_calculated_column": "Calculated Hidden Column",
    "label_hidden_vendor_column": "Vendor Value Column",
    "keyword_all_unmapped": "All Unmapped",
    "label_visible_to_vendors": "Visible to Vendors",
    "label_hidden_from_vendors": "Hidden from Vendors",
    "error_this_document_is_sealed": "This %{document} is sealed, please contact the Project Owner to have it unsealed.",
    "label_assign_to_a_contract": "Assign to a contract",
    "label_vpm_question_instruction": "This is an instruction. Respondents will not be able to answer this.",
    "label_default_question": "This is a default question that is part of every survey. You cannot modify it except for the Comment options",
    "label_cannot_be_modified": "Cannot be modified",
    "notice_are_you_sure_you_want_to_mark_this_survey_as_inactive": "Are you sure you want to mark this survey as inactive? This will immediately end the survey and no further responses will be accepted. You can always reactivate a survey from the Inactive Surveys list.",
    "label_action_inactive_surveys": "%{action} Inactive Surveys",
    "label_this_is_a_test_survey": "This is a test survey! No survey data will be recorded!",
    "label_response_date": "Response Date",
    "label_question_type": "Question Type",
    "label_question_text": "Question Text",
    "label_comment_option": "Comment Option",
    "keyword_labels": "Labels",
    "label_period": "Period",
    "label_survey_period": "Survey Period",
    "label_survey_periods": "Survey Periods",
    "label_name_survey_created": "%{name} survey created",
    "label_paragraph": "Paragraph",
    "label_please_select": "Please select...",
    "label_test_survey": "Test Survey",
    "keyword_bianual": "Bi-annual",
    "keyword_bianually": "Bi-annually",
    "label_text_area": "Text Area",
    "phrase_no_vendors_cannot_see_each_others_names_or_prices": "No, vendors cannot see each other's names or prices",
    "label_verify": "Verify",
    "label_verify_vendor_document": "Verify Vendor Document",
    "label_this_document_has_been_verified_by": "This document has been verified by me, %{name}",
    "keyword_verified": "Verified",
    "phrase_document_verified_by": "Verified by %{name} on %{date}.",
    "phrase_document_verified_on": "Verified on %{date}.",
    "label_commodity_code_no_edit": "These are the commodity codes that '%{vendor}' has selected. You cannot edit these.",
    "label_vendor_require_commodity_code": "Require Commodity Code when a Vendor registers",
    "label_preferences": "Preferences",
    "notice_commodity_code_set": "You are using the %{set} code set",
    "phrase_commodity_codes_updated_successfully": "Commodity Codes preference saved.",
    "label_commodity_code_set": "%{set} Code",
    "phrase_commodity_codes_are_required": "Commodity Codes help match you to future bid opportunities in your Service Region, with %{organization} and other organizations. You can always go back and change them later. <b>You must select at least one Commodity Code.</b>",
    "phrase_all_values_are_in": "All values are in %{currency}",
    "label_vendor_information": "Vendor Information",
    "phrase_all_values_numeric": "All values are numeric",
    "phrase_survey_comment_type_explanation": "Comments are only available if the Answer Type is Rating. They can be required, optional or disabled.",
    "phrase_vendor_performance_survey_start_date_tooltip": "Survey will be sent to respondents at 9:00AM on this date",
    "phrase_organization_has_requested_you_complete_the_survey": "%{organization} has requested you to complete the survey: %{survey}.",
    "phrase_uploaded_on": "Uploaded on %{date}",
    "phrase_uploaded_on_and_by": "Uploaded on %{date} by %{name}",
    "nav_link_learn": "Learn",
    "label_receive_auto_reminders": "Receive Auto-Reminders",
    "phrase_contract_term_has_expired": "The contract term is now expired. Please note that only the term has expired, and you can change your active term to update this contract.",
    "db_string_key_term_expired": "Term Expired",
    "label_term_expired": "Term Expired",
    "phrase_only_the_term_is_expiring": "Please note that only the term is expiring, and not the full contract.",
    "phrase_an_email_will_be_sent_to_all_people_listed_on_this_contract_letting_them_know_the_x_is_expiring_soon": "An email will be sent to all People listed on this contract letting them know the %{x} is expiring soon.",
    "js_label_term_expired": "Term Expired",
    "copy_documents_tooltip": "Documents selected will be copied from the project into the contract",
    "label_upload_template_document": "Upload Document Template",
    "phrase_submission_cannot_be_processed_because_you_are_not_invited": "This project is only open to invited vendors. Your submission cannot be processed because you are not an invited vendor.",
    "phrase_hide_public_documents_after_project_closed": "Hide public files after project is closed",
    "phrase_public_document_copied_from_x": "Public document copied from %{x}",
    "phrase_proposal_document_copied_from_x": "Proposal document copied from %{x}",
    "error_permission_check_failed": "You don't have permission to complete that action",
    "error_cant_set_question_order_to_1": "You can't set the question order to 1, as that is reserved for the default question",
    "phrase_auto_change_term_expiring_soon": "Because the option to automatically change the active term is enabled, the active term will be updated for you.",
    "phrase_auto_change_term_expired": "Because the option to automatically change the active term is enabled, the active term has been updated for you.",
    "auto_change_term_tooltip": "Indicates if this contract will automatically renew, based on the next available term. You cannot manually change the active term when this is selected.",
    "phrase_auto_change_term": "Automatically change to next term",
    "phrase_you_have_x_vendors_and_y_contracts_needing_attention": "You have %{x} Vendor(s) and %{y} Contract(s) with Vendor Documents requiring attention.",
    "phrase_vendors_with_upcoming_expiring_or_expired_documents": "Vendor(s) with upcoming expiring or expired documents:",
    "phrase_upcoming_expiry": "Upcoming Expiry",
    "label_view_vendors": "View Vendors",
    "phrase_contracts_with_expiring_or_expired_vendor_documents": "Contract(s) with upcoming expiring or expired Vendor documents:",
    "phrase_you_have_vendors_with_expiring_or_expired_documents": "You have Vendors with expiring or expired documents",
    "phrase_watch_our_questionnaire_video_link": "You have also been assigned a Questionnaire to score. <br> Checkout our video and article on <a href='https://support.gobonfire.com/hc/en-us/articles/205342527-Scoring-Questionnaires-VIDEO-' target='_blank'>‘How to score Questionnaires in Bonfire’</a> for more details.",
    "error_x_is_required": "%{x} is required.",
    "tooltip_change_active_term_auto_change_term": "This action is currently disabled. To manually change the active term, edit the contract and turn off the option to automatically change the active term.",
    "label_add_notes": "Add Notes",
    "label_hide_notes": "Hide Notes",
    "label_contract_attributes": "Contract Attributes",
    "label_expired_vendor_documents": "Expired Vendor Document(s)",
    "phrase_vendor_documents": "Indicates if there are expired documents for a Vendor.",
    "keyword_schedule_and_respondents": "Schedule and Respondents",
    "tooltip_all_vendors": "All vendors, including document takers",
    "tooltip_all_active_vendors": "All vendors with a completed proposal that have not been eliminated",
    "tooltip_all_work_in_progress_vendors": "All vendors with a started but incomplete proposal",
    "tooltip_all_eliminated_vendors": "All vendors who have been eliminated",
    "label_all_document_takers": "All Document Takers",
    "tooltip_all_document_takers": "All vendors who have taken documents and not submitted a proposal",
    "label_all_submitted_vendors": "All Submitted Vendors",
    "tooltip_all_submitted_vendors": "All Vendors who have submitted a proposal",
    "tooltip_asterisk_unread": "* indicates at least 1 new message",
    "label_vendors_expired": "Expired",
    "label_vendors_near_expiry": "Near Expiry",
    "label_attach_file": "Attach File",
    "label_attached_by": "Attached by",
    "label_vendor_attachments": "Vendor Attachments",
    "tooltip_vendor_attachments": "Toggle on to allow vendors to send attachments",
    "phrase_organization_has_granted_vendor_access_for_file_upload": "%{organization} has granted Vendor access to send attachments in this conversation",
    "phrase_organization_has_revoked_vendor_access_for_file_upload": "%{organization} has revoked Vendor access to send attachments in this conversation",
    "phrase_confirm_disable_vendor_attachments": "Are you sure you want to revoke Vendor access to send attachments in this conversation? Vendors will be notified of the update.",
    "phrase_confirm_enable_vendor_attachments": "Are you sure you want to grant Vendor access to send attachments in this conversation? Vendors will be notified of the update.",
    "label_recipients": "Recipients",
    "label_to_view_the_full_message": "(to view the full message, click View the Opportunity, below)",
    "label_to_view_the_full_message_project": "(to view the full message, click View the Project, below)",
    "label_files_attached": "%{x} file(s) attached",
    "keyword_attachments": "Attachments",
    "warning_attached_file_names_must_have_unique_names": "Attached files must have unique names",
    "label_download_all_responses": "Download All Responses",
    "tooltip_download_all_responses": "Click here to download all responses for all surveys",
    "label_auto_change_term": "Auto-change Term",
    "label_auto_renew": "Auto-renew",
    "label_please_select_or_enter_email": "Please select or enter email",
    "label_no_files": "No Files",
    "phrase_you_have_unsubscribed_from_bonfire_opportunity_notifications": "You've successfully unsubscribed from <b>Bonfire Opportunity Notification emails</b>. If you want to resubscribe to these emails, please login.",
    "phrase_you_will_receive_notifications_if": "Please note that you will still receive Bonfire emails if:",
    "phrase_direct_invite_to_opportunity": "A Buying Organization directly invites you to an Opportunity",
    "phrase_opportunity_has_updates": "You have interacted with an Opportunity that has any updates",
    "phrase_submission_has_updates": "Your submission(s) has any updates",
    "phrase_you_have_been_unsubscribed": "You have been Unsubscribed",
    "phrase_unsubscribe_successful": "Unsubscribe Successful",
    "phrase_click_here_to_unsubscribe": "Click here to unsubscribe from these emails",
    "phrase_project_is_open_and_still_accepting_submissions_eval_off": "This project is in the Open stage and is still accepting submissions.",
    "phrase_project_is_pending_your_review_before_users_access_submission": "%s - %s project is pending your review before users can access submissions.",
    "phrase_users_will_be_notified_for_viewing_once_you_release": "Users will be notified once you release the project for viewing.",
    "phrase_project_is_closed": "%s - %s project is now closed.",
    "phrase_reviewers_will_have_access_for_viewing_scoring": "Reviewers will now have access to submissions for viewing/scoring.",
    "phrase_users_will_have_access_for_viewing": "Users will now have access to submissions for viewing.",
    "phrase_none_available": "None Available.",
    "label_remove_people": "Remove People",
    "label_edit_contracts": "Edit Contracts",
    "label_manage_people": "Add or Update People",
    "keyword_needs_verification": "Requires Verification",
    "tooltip_needs_verification_requested_vendor_document": "Buyers can verify this document.",
    "label_verification_status": "Verification Status",
    "label_needs_verification": "Needs Verification",
    "label_not_uploaded": "Not Uploaded",
    "label_verified": "Verified",
    "label_vendor_document_verification_col": "Status of Registration documents that require verification.",
    "label_group": "Group",
    "label_ungrouped": "Ungrouped",
    "label_provide_submission_information": "Provide Submission Information",
    "label_data_slot_submission_instruction": "Click Edit to enter a data or text answer.",
    "header_bulk_remove_people": "Bulk Remove People",
    "header_bulk_manage_people": "Bulk Add or Update People",
    "warning_bulk_manage": "You are bulk editing people for %{x} Contracts",
    "header_bulk_edit_contracts": "Bulk Edit Contracts",
    "warning_bulk_edit": "You are bulk editing the following %{x} Contracts",
    "warning_bulk_remove": "The following people are found on %{x} Contracts",
    "bulk_remove_continue": "Select the people you want to remove and click Continue.",
    "warning_any_changes": "Any changes you make will affect these.",
    "warning_only_checked_fields": "Only the checked off fields will be updated.",
    "warning_you_are_about_to_remove_people": "You are about to remove the following from %{x} Contracts:",
    "phrase_change_field": "Change %{x}",
    "phrase_change_field_type": "Change %{x} (%{y})",
    "phrase_change_if": "Change if %{x}",
    "phrase_custom_fields": "The following fields are custom for Contract Types: %{x}. Change Contract Type to override these selections",
    "label_expiry_status": "Expiration Status",
    "label_not_expired": "Not Expired",
    "label_vendor_document_expiration_col": "Status of Registration documents that expire.",
    "tooltip_kpi_vendor_document_require_verification": "Vendor Registration Documents that require verification but has not been verified",
    "tooltip_kpi_vendor_document_near_expiration": "Vendor Registration Documents that are within 30 days of expiration.",
    "tooltip_kpi_vendor_document_expired": "Vendor Registration Documents that have expired",
    "tooltip_contract_survey_score": "The contract score is the average of all survey scores associated with this contract",
    "tooltip_vendor_survey_score": "The vendor score is the average of all survey scores associated with this vendor",
    "tooltip_survey_question_score": "The survey score is the average score of the first question",
    "tooltip_survey_score": "Survey rating is the weighted average of all questions with points values.",
    "keyword_surveys": "Surveys",
    "phrase_not_yet_responded": "Not yet responded",
    "keyword_unanswered": "Unanswered",
    "phrase_comment_disabled": "Comment disabled",
    "phrase_no_comment_submitted": "No comment submitted",
    "action_confirm_removal": "Confirm Removal",
    "orderlist_export_message": "Your request has been queued. Your download will begin shortly.",
    "phrase_watch_our_bidtable_video_link": "You have also been assigned a BidTable to score. <br> Checkout our video and article on <a href='https://support.gobonfire.com/hc/en-us/articles/218565948-How-to-Evaluate-a-Project-with-a-BidTable-using-an-Order-List-VIDEO-' target='_blank'>‘How to score BidTables in Bonfire’</a> for more details.",
    "question_public": "Public?",
    "label_contract(s)": "Contract(s)",
    "keyword_contracts": "Contracts",
    "label_not_applicable": "Not Applicable",
    "label_clear_filters": "Clear Filters",
    "phrase_submission_instructions_guide_vendors": "Download the Submission Instructions document to guide vendors on how to structure a submission into the right number of (and types of) files. It also highlights the key dates for submitting to the project. We advise this document to be included as part of RFx.",
    "keyword_trending": "Trending",
    "keyword_standing": "Standing",
    "phrase_trending_explanation": "Trending shows the percent change of performance rating of the current performance vs. 30 days ago.",
    "label_contracts_at_risk": "Contracts At Risk",
    "tooltip_contracts_at_risk": "A Contract is considered 'At Risk' if it's performance rating is less than or equal to %{x}%",
    "label_good": "Good",
    "label_at_risk": "At Risk",
    "label_trending_up": "Trending Up",
    "label_trending_down": "Trending Down",
    "label_survey_name": "Survey Name",
    "notice_resend_email_success": "A new invitation email was sent successfully!",
    "label_vendor_performance": "Vendor Performance",
    "label_performance_trending": "Performance Trending",
    "label_performance_standing": "Performance Standing",
    "pdf_conversion_failed": "Conversion to PDF failed",
    "label_survey_performance": "Survey Performance",
    "label_response_expired": "Response Expired",
    "tooltip_response_expired": "Response was submitted over %{days} days ago. It is no longer included in the average",
    "label_subscribe": "Subscribe",
    "tooltip_trending_explanation": "Trending shows the percent change of performance rating of the performance vs. 30 days ago.",
    "phrase_clear_options": "Remove Options",
    "keyword_all_types": "All Vendors",
    "warning_if_you_change_owner_of_this_project_to_current_selection_you_will_no_longer_be_able_to_manage_this_project": "If you change the owner of this project to the current selection, then you will no longer be able to manage this project.",
    "confirm_send_now": "Are you sure you want to send this survey to %{x} respondent(s)? They will receive this survey immediately.",
    "empty_ri_group_placeholder": "Drag Items Here to Assign to Group",
    "label_important_events": "Important Events",
    "keyword_custom_events": "Events",
    "label_score_insights": "Score Insights",
    "label_incomplete_scoring": "Incomplete Scoring",
    "phrase_proposalName_is_ranked_number_n": "%{proposalName} is ranked #%{n}",
    "phrase_clear_winner": "Clear winner",
    "keyword_challenger": "Challenger",
    "phrase_this_proposal_is_rank_n": "This proposal is Rank %{n}",
    "phrase_showHide_data_in_tabular_form": "%{showHide} data in tabular form",
    "label_x_score_for_the_category": "%{x} Score for the Category",
    "label_dataset": "Dataset",
    "action_manage_custom_events": "Manage Events",
    "clear_options_tooltip": "This will remove all options that were previously selected for this field",
    "label_new": "New",
    "error_message_with_file_attachment_could_not_be_sent": "Error: Message with file attachment could not be sent",
    "phrase_document_converting": "This document is currently being converted, %s in the next few seconds.",
    "keyword_retry": "Retry",
    "confirm_bulk_update_contracts": "Are you sure you want to update these %{x} Contracts?",
    "confirm_bulk_edit_empty_text_field": "Leaving this field empty will remove all %{x} text that was previously filled in for the selected Contracts.",
    "error_confirm_account_link_invalid": "The link used to confirm your account is not valid. Please generate a new link and try again. If the problem persists, please contact Support@GoBonfire.com.",
    "phrase_send_invite_link_again": "The invite link is not valid. Please contact the Organization Administrator for a new link and try again. If the problem persists, please contact Support@GoBonfire.com.",
    "hide_instructions": "Hide instructions",
    "show_instructions": "Show instructions",
    "vendor_performance_survey_description_tooltip": "The survey description will be included in the outgoing survey email to respondents as well as in the survey itself.",
    "heading_were_here_to_help": "We're here to help",
    "heading_knowledge_base_doesnt_answer_your_question": "If our Knowledge base doesn't answer your question, please fill out the form below",
    "heading_what_can_we_help_you_with": "What can we help you with",
    "label_type_search_query": "Type here to begin your search!",
    "phrase_date_to_evaluate": "Deadline to evaluate Project Submissions",
    "notice_please_provide_a_title_for_each_slot_group": "Please provide a Title for each Requested Information Group",
    "event_location_online_portal": "Online Portal",
    "phrase_opportunity_open_for_submissions": "Posting date for the Opportunity",
    "phrase_opportunity_closed_for_submissions": "Deadline for Submissions",
    "phrase_intent_to_bid_required": "Deadline to indicate your intent to Bid",
    "phrase_opportunity_q_and_a_ends": "Deadline to submit Questions",
    "phrase_opportunity_q_and_a_ongoing": "The Q and A Period for this Opportunity is Ongoing",
    "label_event_name": "Event Name",
    "keyword_location": "Location",
    "db_string_key_created": "created",
    "db_string_key_updated": "updated",
    "db_string_key_removed": "removed",
    "label_project_events_have_changed": "Project Events Have Changed",
    "phrase_you_may_want_to_notify_suppliers_events": "You may want to 'Notify Suppliers via Public Notices' to review the Event details listed on the portal.",
    "keyword_ongoing": "Ongoing",
    "keyword_upcoming": "Upcoming",
    "keyword_passed": "Passed",
    "download_warning_infected": "Warning: This file has been scanned for viruses and tagged as dangerous. Are you sure you want to download this file?",
    "download_warning_skipped": "Warning: This file has not been scanned for viruses. Are you sure you want to download this file?",
    "proposal_warning_at_least_one_infected": "Warning: At least one proposal document has been scanned for viruses and tagged as dangerous. Are you sure you want to download these files?",
    "proposal_warning_at_least_one_skipped": "Warning: At least one proposal document has not been scanned for viruses. Are you sure you want to download these files?",
    "contract_warning_at_least_one_infected": "Warning: At least one contract document has been scanned for viruses and tagged as dangerous. Are you sure you want to download these files?",
    "contract_warning_at_least_one_skipped": "Warning: At least one contract document has not been scanned for viruses. Are you sure you want to download these files?",
    "opportunity_warning_at_least_one_infected": "Warning: At least one opportunity document has been scanned for viruses and tagged as dangerous. Are you sure you want to download these files?",
    "opportunity_warning_at_least_one_skipped": "Warning: At least one opportunity document has not been scanned for viruses. Are you sure you want to download these files?",
    "label_scoring_analysis": "Scoring Analysis",
    "label_show_sensitivity": "Show Sensitivity",
    "tooltip_total_value_for_this_group_has_been_overriden": "The total value for this group has been overridden from %{weight} pts to %{scaledWeight} pts",
    "message_support_You’re_not_alone_Reach_out_to_our_friendly_support": "You’re not alone! Reach out to our friendly support team today!",
    "message_support_Looking_to_submit_into_Bonfire_and": "Looking to submit into Bonfire and not sure where to go? Contact our support team and we will guide you in the right direction!",
    "message_support_Need_help_quickly": "Need help quickly? Check out our extensive knowledge base full of Help Articles! If you need further support reach out to us using the form below!",
    "message_support_We_provide_multiple_ways_to_support_you": "We provide multiple ways to support you including help articles, video tutorials and email! Need instant answers? Check out our extensive knowledge base!",
    "phrase_the_released_evaluation_group": "The released Evaluation Group",
    "phrase_please_note_that_bidtables_may_take_a_significant_amount": "Please note that BidTables may take a significant amount of time to prepare.",
    "label_submission_type": "Submission Type",
    "label_excel_submission": "Excel Submission",
    "label_browser_submission": "Browser Submission",
    "toast_edit_browser_submission_type_bidtable": "To ensure the best submission experience for the Vendors, the recommended maximum number of line items for each BidTable is %{x}.",
    "label_datatable": "Datatable",
    "phrase_you_are_receiving_this_email_because_you_agreed_to_receive_bid": "You are receiving this email because you agreed to receive bid opportunity notifications based on commodity codes matches. You may unsubscribe at any time.",
    "label_full_screen": "Full Screen",
    "label_confirm_bidtable": "Confirm BidTable",
    "phrase_fill_out_the_form_below_all_changes_are_saved_automatically": "Fill out the form below. All changes are saved automatically. Click '%{x}' when you are complete.",
    "label_rank_of_average_score": "Rank of Average Score",
    "label_rank_of_consensus_score": "Rank of Consensus Score",
    "label_calculation_final_rank": "Calculation Final Rank",
    "label_this_evaluation_group": "This Evaluation Group",
    "phrase_proposal_has_x_sensitive_criteria": "This proposal has %{x} sensitive criteria",
    "label_add_additional_response": "Add Additional Response",
    "instruction_to_submit_additional_bids_for_an_item_you": "To submit additional bids for an item, you must go to the Additional Responses tab.",
    "label_add_vendor_via_recommendation_engine": "Add via Recommendation Engine",
    "keyword_scenario": "Scenario",
    "error_file_is_zero_bytes": "Could not upload the file. The file is empty. (0 bytes)",
    "label_yes_slash_no": "Yes/No",
    "keyword_other": "Other",
    "label_invalid_previous_selection": "Invalid Previous Selection",
    "phrase_scroll_for_more_columns": "Scroll to the right to see more columns",
    "phrase_fill_in_required_fields_before_confirming_bid_table": "Please fill in all required fields before confirming the BidTable",
    "label_edit_bidtable": "Edit BidTable",
    "phrase_bidtable_is_confirmed": "BidTable is confirmed.",
    "phrase_click_x_to_make_changes": "Click '%{x}' to make changes.",
    "label_rank_analysis": "Rank Analysis",
    "label_continue_to_registration_complete": "Continue to registration complete",
    "action_french_version": "En français",
    "phrase_document_viewer_as_reference": "The document viewer should only be used as a reference and documents should be downloaded to ensure 100% accuracy of the content.",
    "phrase_multi_category_decisions_not_supported_with_new_submission_type": "The 'Browser' submission type does not support the use of 'Multi-Category Decisions'. Please select the 'Excel' submission type if you wish to use 'Multi-Category Decisions'.",
    "phrase_multi_category_decisions_not_supported_with_new_submission_type_edit": "Projects using the 'Browser' submission type do not support 'Multi-Category Decisions'.",
    "label_bidtable_data_confirmed": "BidTable data confirmed",
    "phrase_bidtable_instructions_condition_no_bid_allowed": "Select '%{labelBid}' for each item you are bidding on and fill out the required information. Select '%{labelNoBid}' if you are not bidding on that item.",
    "phrase_bidtable_instructions_condition_no_bid_not_allowed": "Please fill out the required information for each item. You are required to bid on every item.",
    "phrase_bidtable_instructions_condition_additional_responses_allowed": "If you want to add an additional response for a specific item, first select '%{labelBid}' for that item and then click the '+' button.",
    "phrase_confirm_bidtable_requirements_not_met": "Item %{itemNumber} requires %{requirements}",
    "label_a_bid_decision": "a bid decision",
    "tooltip_no_bid_button_disabled": "You must delete all additional responses for this item if you want to select '%{labelNoBid}'.",
    "tooltip_add_additional_button_disabled": "You must select '%{labelBid}' on the primary response to add additional responses.",
    "input_placeholder_generic_text": "Enter text",
    "input_placeholder_generic_number": "Enter a number",
    "input_placeholder_generic_currency": "$ ___",
    "phrase_please_fill_out_incomplete_fields_before_confirm_bidtable": "Please fill out all incomplete fields provided below before clicking 'Confirm BidTable'.",
    "phrase_confirm_bidtable_incomplete_fields_warning": "This BidTable has %{numIncompleteFields} incomplete fields.",
    "action_cancel_file_upload": "Cancel file upload",
    "phrase_you_can_continue_to_send_messages_to_vendors": "You can continue to send messages to vendors, but vendors are no longer permitted to respond.",
    "heading_field_setup": "Field Setup",
    "heading_select_field_type": "Select Field Type",
    "label_for_vendors": "For Vendors",
    "label_for_internal": "For Internal",
    "label_vendor_fields": "Vendor Fields",
    "label_internal_fields": "Internal Fields",
    "label_additional_information": "Additional Information",
    "label_internal_only": "Internal Only",
    "phrase_can_be_seen_by_vendors": "These fields are presented to the vendor during registration. They can be updated at any time by the vendor or an internal user.",
    "phrase_cannot_be_seen_by_vendors": "These fields are only available to internal users, and can be updated at any time. Vendors <strong>cannot</strong> view or edit this information.",
    "label_edit_vendor": "Edit Vendor",
    "label_edit_internal_only_fields": "Edit Internal Only Fields",
    "label_field_type": "Field Type",
    "phrase_currently_logged_in_create_vendor_org": "You are currently logged in with an account that cannot submit proposals. To submit, you must create a vendor organization.",
    "label_create_vendor_organization": "Create Vendor Organization",
    "label_timezone_region": "Timezone Region",
    "phrase_find_more_public_sector_business_opportunities": "Find more public sector business opportunities!",
    "phrase_premium_vendor_subscribers_get_exclusive_access": "Premium Vendor subscribers get exclusive access to hand-curated opportunities from over 300+ public sector organizations.",
    "label_become_a_premium_vendor": "Become a Premium Vendor",
    "label_download_submission_receipt_summary": "Download Submission Receipt Summary",
    "phrase_want_to_find_new_ways_to_grow_your_business": "Here's how to easily find more bid opportunities",
    "phrase_search_and_manage_all_opportunities_from_all_bonfire_customers": "Search and manage all opportunities from all Bonfire customers in one central portal with",
    "phrase_bonfire_premium_subscriptions": "Bonfire Premium Subscriptions",
    "phrase_use_coupon_code_x_for_y_off_your_first_year": "Use coupon code %{couponCode} for %{amountOff} your first year!",
    "phrase_20%_off": "20% off",
    "phrase_click_here_to_sign_up_now": "Click here to sign up now",
    "phrase_we_found_new_bid_opportunities_that_match_your_business": "We’ve found new bid opportunities that match your business!",
    "keyword_denominator": "Denominator",
    "vps_response_required_tooltip": "Questions may only be optional if they are not worth any points.",
    "registration_preferences_saved": "Registration preferences saved.",
    "require_vendor_type_during_registration": "Require a Vendor Type when a Vendor registers",
    "label_simple_bid": "Simple Bid",
    "label_download_grid_export": "Download grid export",
    "title_contracts_dashboard": "Contracts Dashboard",
    "title_contracts_list": "Contracts List",
    "label_date_joined_x": "Date Joined %{x}",
    "support_avatar_alt": "Technical support avatar",
    "heading_pricing_sheet": "Pricing Sheet",
    "keyword_intake": "Intake",
    "keyword_requests": "Requests",
    "label_show_menu": "Show Menu",
    "phrase_if_you_did_not_sign_up_contact_support": "If you did not sign up for this account or feel you have received this email in error, please send an email to Support@GoBonfire.com.",
    "phrase_confirmation_email_has_been_sent_click_below_to_send_again": "An email has been sent to <strong>%{email}</strong>. Please click the link in the email to <strong>Confirm your Account</strong>. The link will expire in <strong>12 hours</strong>. If you have trouble finding the email or the link has expired, click the button below to send a new email.",
    "phrase_confirm_your_account_for_full_access": "If you are an organization member at %{organizationName}, then you need to confirm your account to get full access to your portal.",
    "tooltip_pricing_sheet": "The Bid Table is replaced by a Pricing Sheet that allows buyers to set up line items quickly and easily. Pricing Sheets are designed to work best when a bid has fewer than 20 line items.",
    "error_no_pricing_sheet_multicategory": "Projects with a Pricing Sheet do not support Multi-Category Decisions",
    "label_pricing_sheet": "Pricing Sheet",
    "label_date_format": "All dates are in the format: <strong>2020-01-30 04:30 PM</strong>",
    "label_date_format_yyyy_mm_dd": "All dates are in the format: <strong>2020-01-30</strong>",
    "label_view_bid_tabulation": "View Bid Tabulation",
    "phrase_youve_been_invited_to_use_bonfire_at_x": "You've been invited to use Bonfire at %{x}",
    "heading_looking_to_submit_request": "Looking to submit a request?",
    "label_create_requester_account": "Create a Requester account",
    "heading_create_your_requester_account": "Create your Requester Account",
    "keyword_dismiss": "Dismiss",
    "label_date_invited": "Date Invited",
    "vendor_invites": "Vendor Invites",
    "phrase_go_to_project": "Go to Project",
    "phrase_add_via_vendor_list": "Add via Vendor List",
    "phrase_toggle_invite_single_reviewer": "Toggle invite single reviewer",
    "phrase_toggle_invite_multiple_reviewers": "Toggle invite multiple reviewers by email",
    "phrase_toggle_invite_single_advisor": "Toggle invite single advisor",
    "phrase_toggle_invite_multiple_advisors": "Toggle invite multiple advisors by email",
    "phrase_toggle_invite_single_editor": "Toggle invite single editor",
    "phrase_toggle_invite_multiple_editors": "Toggle invite multiple editors by email",
    "phrase_toggle_invite_single_observer": "Toggle invite single observer",
    "phrase_toggle_invite_multiple_observers": "Toggle invite multiple observers by email",
    "label_message_file_description": "Attached Files from Messages",
    "phrase_you_have_some_new_bonfire_matches": " You have some new Bonfire matches!",
    "phrase_for_these_opportunities_its_100_percent_free": "For these opportunities, it’s <strong>100% free</strong> to view all bid details and documents, then submit your response through Bonfire.",
    "phrase_happy_bidding_from_the_bonfire_team": "Happy bidding from the Bonfire Team!",
    "phrase_you_are_currently_registered_with_a_bonfire_standard_account_explanation": "You are currently registered with a Bonfire Standard Vendor account, which means you can view and bid on opportunities for free on our platform. Upgrade to a <a href='https://vendor.gobonfire.com/?utm_source=bonfire&utm_medium=email&utm_campaign=bid-notification-email'>Bonfire Premium Vendor</a> account to easily discover opportunities from hundreds of public agencies across the United States and Canada, all in one place. Over 200,000 bid opportunities are added each year.",
    "phrase_we_will_get_you_started_with_10_percent_off_code": "We’ll even get you started with 10% off your first year! Just use coupon code <strong>%{code}</strong>.",
    "phrase_upgrade_now": "Upgrade now!",
    "phrase_you_did_not_submit_anything_for_this_bidtable": "You did not submit anything for this Bid Table. Since this Bid Table is locked, you cannot edit or update this part of your submission.",
    "heading_bid_table_summary": "BidTable Summary",
    "phrase_contact_information_after_questions_due_date": "<strong>If any urgent questions arise, you can contact the organization directly.<br>Contact Information:</strong> %{contactInfo}",
    "phrase_contact_information_never": "<strong>The Project Owner has indicated they will not respond to questions sent outside of Bonfire.</strong>",
    "label_contact_info_options": "Contact Information Options",
    "phrase_contact_info_options": "Choose if you would like to display contact information to Vendors on this Opportunity. If you select \"Do not display\", you are indicating that you do not want to be contacted outside of Bonfire for this Opportunity.",
    "label_contact_info_always": "Always display",
    "label_contact_info_after_due_date": "Only display after the Questions Due Date",
    "label_contact_info_never": "Do not display",
    "label_contact_info_email_phone": "Contact Email or Phone Number",
    "label_contact_info_name": "Contact Name",
    "error_please_provide_email_or_phone": "Please provide a contact email or phone number.",
    "label_download_excel_grid_export": "Download Excel grid export",
    "label_download_word_grid_export": "Download Word grid export",
    "phrase_public_notice_email_reason": "You are receiving this email because you have been invited to this project, or expressed interest in this project by either downloading a document, communicating with the buyer directly or starting a submission.",
    "phrase_public_notice_opt_out": "If you no longer wish to receive public notice emails for this project, you may unsubscribe at any time <a href='%s'>here</a>.",
    "label_go_to_project_portal": "Go to Project on Portal",
    "phrase_public_notice_unsubscribe_success": "You've successfully unsubscribed from <strong>%s</strong> public notice email notifications.",
    "phrase_public_notice_resubscribe": "If you want to resubscribe to these emails, you can do so from the opportunity page.  If you wish to delete your account, please email support@gobonfire.com. Please note that you will still receive emails from other projects you've been invited to, or expressed interest in.",
    "phrase_you_have_unread_messages": "You have unread messages",
    "phrase_skip_end_activity_feed": "Skip to end of activity feed",
    "phrase_skip_start_activity_feed": "Skip to start of activity feed",
    "label_x_required": "%s (required)",
    "label_cancel_project": "Cancel Project",
    "error_this_project_cannot_be_cancelled_at_this_time": "ERROR: This project cannot be cancelled at this time",
    "keyword_cancelled": "Cancelled",
    "buyer_and_evaluator_knowledge_base": "Buyer & Evaluator Knowledge Base",
    "vendor_knowledge_base": "Vendor Knowledge Base",
    "label_cancellation_reason": "Cancellation Reason",
    "phrase_you_are_about_to_cancel_this_project": "You are about to cancel this project.",
    "phrase_project_cancellation_vendors_no_longer_be_able_start_continue_submission": "Vendors will no longer be able to start or continue a work in progress submission. Buyers will still be able to access this project and make modifications.",
    "phrase_project_cancellation_vendors_and_buyers_will_only_notified": "Vendors and Buyers will only be notified of this change if you send a Public Notice or Internal Message, respectively.",
    "phrase_project_cancellation_you_can_resume_this_project": "You can resume this project if the cancellation reason is resolved or if this change was made in error.",
    "phrase_project_cancellation_are_you_sure_want_to_cancel_this_project": "Are you sure you want to cancel this project?",
    "phrase_confirm_remove_pricing_sheet": "Are you sure you want to remove the Pricing Sheet feature from this project?",
    "phrase_cannot_add_pricing_sheet_mcd_bidTables": "You must disable Multi-Category Decisions and delete the existing BidTable(s) before enabling Pricing Sheet.",
    "phrase_cannot_add_pricing_sheet_mcd": "You must disable Multi-Category Decisions before enabling Pricing Sheet.",
    "phrase_cannot_add_pricing_sheet_bidTables": "You must delete the existing BidTable(s) before enabling Pricing Sheet.",
    "notice_success_project_cancelled": "Success! Project cancelled.",
    "heading_projects_dashboard": "Projects Dashboard",
    "phrase_cancelled_project_not_accepting_submissions": "This project is cancelled and is no longer accepting any proposal submissions at this time.",
    "phrase_this_project_has_been_cancelled_you_may_want_to_notify_suppliers": "This project has been cancelled. You may want to 'Notify Suppliers via Public Notices' to provide any details regrading this cancellation.",
    "db_string_key_project_cancelled": "Project Cancelled",
    "action_view_proposal_analysis": "View Proposal Analysis",
    "phrase_skip_top_nav": "Skip Top Navigation",
    "label_allowed_file_types": "Allowed File Types: %s",
    "phrase_project_has_been_cancelled_cannot_submit": "<strong>This project has been cancelled.</strong> You can no longer submit to this project. Please see any buyer communications for more information.",
    "phrase_project_has_been_cancelled": "<strong>This project has been cancelled.</strong> Please see any buyer communications for more information.",
    "label_resume_project": "Resume Project",
    "phrase_you_are_about_to_resume_this_project": "You are about to resume this project.",
    "phrase_this_project_will_resume_according_to_current_schedule": "This project will resume according to the current schedule.",
    "phrase_project_cancel_export_message": "Project was cancelled on %s by %s. Reason: %s",
    "label_cancelled_information": "Cancelled Information",
    "project_timeline_cancelled_state_past": "This project was cancelled on %{date}",
    "project_timeline_resumed_state_past": "This project was resumed on %{date}",
    "phrase_this_project_has_resumed_you_may_want_to_notify": "This project has resumed. You may want to 'Notify Suppliers via Public Notices' to provide any details.",
    "db_string_key_project_resumed": "Project Resumed",
    "label_project_cancelled": "Project Cancelled",
    "phrase_project_cancelled_as_of": "Project cancelled as of",
    "action_cancelled_the_project": "%s cancelled the project %s",
    "action_resumed_the_project": "%s resumed the project %s",
    "label_no_decision": "No Decision",
    "phrase_notify_suppliers_via_public_notices_or_dismiss_permanently": "Notify Suppliers via Public Notices or click the dismiss to permanently dismiss this message.",
    "phrase_you_have_been_invited_to_submit_opportunities_at_x": "You've been invited to submit opportunites at %s",
    "phrase_you_will_receive_an_email_with_further_instructions_on_how_to_set_up_your_account": "You'll receive an email with further instructions on how to set up your account.",
    "phrase_we_received_a_request_to_set_up_a_user_account_with_this_email": "We received a request to set up a user account with this email",
    "phrase_however_you_already_have_account_with_x": "However, you already have an account with %s.",
    "phrase_if_you_need_help_resetting_your_password_go_to_reset_your_password": "If you need help resetting your password go to reset your password.",
    "error_there_was_an_error_creating_vendor_account_please_contact_support": "There was an error creating vendor account. Please contact support@GoBonfire.com.",
    "label_price_only": "Price Only",
    "label_strategic_sourcing": "Strategic Sourcing",
    "label_upgrade_simple_project": "Convert to Strategic Sourcing",
    "phrase_confirm_upgrade_simple_project": "Converting this Price Only Project to a Strategic Sourcing Project cannot be undone. Are you sure you want to convert this project?",
    "phrase_too_many_requests": "Too many requests",
    "error_sending_messages": "Error sending messages. Please try again. If this problem persists contact Support@GoBonfire.com",
    "label_approvals": "Approvals",
    "label_approvals_for_this_project": "Approvals for this project.",
    "heading_approver_name": "Name of Approver",
    "heading_dept": "Dept.",
    "phrase_out_for_approval": "Out For Approval",
    "keyword_approved": "Approved",
    "keyword_declined": "Declined",
    "keyword_sent": "Sent",
    "keyword_responded": "Responded",
    "phrase_required_by": "Required By",
    "phrase_no_approval_requests": "No approval requests",
    "phrase_youve_signed_up_as_a_vendor": "You've signed up to be a Bonfire Vendor and need to finish registering",
    "phrase_click_below_to_complete_your_registration": "To complete your registration, click below and create your account.",
    "phrase_complete_your_registration": "Complete your registration",
    "please_remove_unsupported_characters_and_try_again": "Please remove the following unsupported characters and try again: %{unsupportedCharacters}.",
    "instructions_please_do_not_use_special_characters_like_emojis": "You can only submit text based responses, please do not use special characters like emojis.",
    "label_submissions_and_subcontracting": "Submissions and Prime/Subcontractor Interest",
    "label_interest_in_subcontracting": "Interest in Subcontracting",
    "label_interested_subcontractors": "Interested Contractors",
    "label_contracting": "Prime/Subcontractor Form",
    "label_prime_contractors": "Prime/General Contractors",
    "label_sub_contractors": "Subcontractors",
    "label_contact": "Contact",
    "label_phone": "Phone",
    "label_subcontract_services": "Subcontract Services",
    "phrase_go_to_vendor_details": "Go to Vendor Details",
    "phrase_invalid_account_setup_link": "Invalid account setup link",
    "phrase_you_can_use_the_regenerate_link_button_below_to_have_new_account_setup_link_sent_to_your_email": "You can use the Regenerate Link Button below to have a new account setup link sent to your email.",
    "label_regenerate_link": "Regenerate Link",
    "label_needs_clarification": "Needs Clarification",
    "label_subcontractor_listing": "Prime/Subcontractor Listing",
    "phrase_you_have_updated_your_email_to_s": "Your email has been updated to %{s}",
    "error_update_email_link_invalid": "The link used to update your email is not valid. Please generate a new link and try again. If the problem persists, please contact Support@GoBonfire.com.",
    "title_bonfire_email_change": "Bonfire Email Change",
    "phrase_your_bonfire_email_was_changed": "Your Bonfire email was changed from %{x} to %{y}",
    "error_cannot_change_sealed_state": "You cannot change the sealed setting for this item of Requested Information as there are submissions associated with it.",
    "error_cannot_change_sealed_states": "You cannot change the sealed setting for these items of Requested Information as there are submissions associated with them.",
    "phrase_you_requested_email_change": "You've requested an update to the email address you use to login to your Bonfire account from %{old} to %{new}",
    "phrase_click_link_to_confirm": "Click the link to confirm this change",
    "title_update_email": "Update Email",
    "phrase_confirm_account_to_update_email": "You must confirm your account to change your email address",
    "error_please_enter_a_new_email_address": "Please enter a new email address",
    "lock_slot": "Lock Slot",
    "unlock_slot": "Unlock Slot",
    "error_please_refresh_and_try_again_or_contact_support": "There was an error. Please refresh the page and try again or contact Support@GoBonfire.com.",
    "phrase_allow_multiple_files": "Allow multiple files?",
    "heading_archived_contracts": "Archived Contracts",
    "keyword_no": "No",
    "keyword_yes": "Yes",
    "label_commodity_code_drill_up": "Go up a level",
    "label_commodity_code_drill_down": "Go down a level",
    "phrase_skip_table_start": "Skip to beginning of table",
    "phrase_skip_table_end": "Skip to end of table",
    "error_use_stronger_password_support_article": "You cannot use this password. <a target='_blank' href='https://vendorsupport.gobonfire.com/hc/en-us/articles/6831723396247'>Why am I seeing this error?</a>",
    "error_unlink_all_associated_vpm_surveys": "Please note that updating the vendor details will unlink all associated active and inactive surveys. Update vendor details anyway?",
    "error_cannot_connect_to_aws": "Unable to connect to AWS. Please contact your IT Team to ensure the amazonaws.com domain is not blocked on your network.",
    "error_please_remove_additional_info_outside_of_given_structure": "Please remove additional information outside of the given structure of the worksheet and try again.",
    "phrase_you_can_enter_multiple_emails": "You can enter multiple emails separated by spaces, commas, or new lines!",
    "heading_file_library": "Library",
    "allow_vendor_responses": "Allow Vendor Responses",
    "tooltip_vendor_responses": "Toggle on to allow vendors to reply to this thread",
    "phrase_confirm_disable_vendor_responses": "Are you sure you want to revoke Vendor access to send responses in this conversation? Vendors will be notified of the update.",
    "phrase_confirm_enable_vendor_responses": "Are you sure you want to grant Vendor access to send responses in this conversation? Vendors will be notified of the update.",
    "phrase_organization_has_granted_vendor_access_for_responses": "%{organization} has granted Vendor access to send responses in this conversation",
    "phrase_organization_has_revoked_vendor_access_for_responses": "%{organization} has revoked Vendor access to send responses in this conversation",
    "phrase_confirm_delete_message_thread_content": "This will delete all previous messages in this thread.",
    "phrase_vendors_will_not_receive_invites_until_this_draft_is_published_and_open": "Vendors will not receive invites until this draft is published and open.",
    "phrase_vendors_will_not_receive_invites_until_this_project_is_opened": "Vendors will not receive invites until this project is opened.",
    "action_back_to_file_library_file": "Back to Library File",
    "advisors_not_invited_till_publish": "Advisors will not be invited to this project until this draft is published.",
    "reviewers_not_invited_till_publish": "Reviewers will not be invited to this project until this draft is published.",
    "phrase_click_an_opportunity_q_and_a_to_see_messages_here": "Click New Vendor Discussion or click a conversation on the left to see messages here.",
    "phrase_start_a_new_opportunity_q_and_a": "Start a new Vendor Discussion",
    "title_opportunity_q_and_a": "Vendor Discussions",
    "tooltip_opportunity_q_and_a": "Vendor Discussion messages are visible to only the Vendors you select",
    "phrase_message_files_explanation": "Message files are only visible here and in the Public Notice or Vendor Discussion that it was attached to. Only people who can see this page or messages can see these files.",
    "message_support_The_Opportunity_QA_feature_is_a_great_way": "The Vendor Discussions feature is a great way to get answers to bid specific questions!",
    "phrase_the_q_and_a_period_ended_date": "The Question period ended %{date}. Messages can no longer be sent, unless a Project Owner allows vendor responses.",
    "phrase_the_q_and_a_period_will_begin_date": "The Question period will begin %{date}.",
    "submission_instructions_the_q_and_a_period_for_this_opportunity_starts": "The Question period for this opportunity starts %{startDate}.",
    "submission_instructions_the_q_and_a_period_for_this_opportunity_ends": "The Question period for this opportunity ends %{endDate}. You will not be able to send messages after this time.",
    "label_new_q_and_a_messages_for_project": "New Vendor Discussion Messages for %{project}",
    "phrase_the_q_and_a_period_does_not_end": "The Question period does not end.",
    "phrase_confirm_delete_message_thread_subject": "Delete this Vendor Discussion thread - '%{projectMessageThreadSubject}.'?",
    "phrase_this_is_where_your_questions_and_answers_will_appear": "This is where your Vendor Discussions will appear.",
    "label_questions_and_answers": "Vendor Discussions",
    "label_all_regions": "All Regions",
    "phrase_hide_documents_after_project_in_evaluation_stage": "Hide public facing documents from portal once the project closes",
    "phrase_project_submission_excel": "Vendors submit BidTables or a Pricing Sheet as Excel templates.",
    "phrase_project_submission_browser": "Vendors submit BidTables or a Pricing Sheet in the browser, no Excel templates required. Recommended maximum number of line items is %{x} for each BidTable or Pricing Sheet.",
    "label_specific_country": "Specific Country",
    "label_best_value_description": "A <strong>Best Value</strong> project is anonymized and can only have one evaluation group open at a time. Additionally any attributes that can identify the vendor are hidden and the obscured vendor names are regenerated each time you release an evaluation group.",
    "tooltip_alternate_options": "<strong>Alternate Options</strong>: Allows you to configure the project such that vendors can submit alternate options in addition to the primary proposal. This number limits the maximum alternatives that are allowed per vendor. You will need to mark certain Requested Information slots as 'Unique Per Alternate' in addition to setting this attribute.",
    "tooltip_advanced_algorithms": "<strong>Advanced Algorithms</strong> give you more options for how scores are determined. When enabled, you will get a dropdown to select alternative algorithms when defining Criteria.",
    "label_rank_analysis_description": "Enables <strong>Rank Analysis</strong> for the scores tables for this project (Scoring Summary, Reviewer Scores, and Proposal Scoring Summary).",
    "label_max_number_alternate_options": "Max # Alternate Options",
    "tooltip_project_anonymity": "<strong>Anonymous Submissions</strong> will hide the Vendor information from view until you are ready to reveal it. When running anonymous projects you'll need to inform vendors not to provide any identifying details in their submissions beyond registering for their account.",
    "tooltip_subcontractor_listing": "<strong>Contractor Listing</strong> allows vendors to submit their interest in being a subcontractor for a project on the project listing page. The list of interested subcontractors is displayed for all vendors to see on the project listing page.",
    "tooltip_multi_category_decision": "<strong>Multi-Category Decision</strong> projects are a special type of project that allow you to define, receive, and evaluate submissions for multiple decisions.",
    "tooltip_consensus_scoring": "<strong>Consensus Scoring</strong>: Allows you to enter a single score that will override the average and all other scores entered for that particular criteria.",
    "tooltip_intent_to_bid": "<strong>Intent to Bid</strong>: Allows you to get a better sense of which of your vendors intend to submit proposals. When enabled, vendors must assert their intent to bid before they may create a submission, or, if they do not intend to bid, they can give you a reason as to why not",
    "label_porfp_purchase_order_request_for_proposal": "PORFP (Purchase Order Request for Proposal)",
    "label_torfp_task_order_request_for_proposal": "TORFP (Task Order Request for Proposal)",
    "label_forum_terms": "I agree to the <a href='https://eunasolutions.com/wp-content/uploads/2023/06/220908___Forum_Terms_of_Use.pdf' target='_blank' rel='noopener noreferrer'>Client Forum Terms of Service <span class='fa-solid fa-external-link'></a> and confirm that I am not from the EU or the European Economic Area.",
    "label_accepted_forum_terms": "You accepted the <a href='https://eunasolutions.com/wp-content/uploads/2023/06/220908___Forum_Terms_of_Use.pdf' target='_blank' rel='noopener noreferrer'>Client Forum Terms of Service <span class='fa-solid fa-external-link'></a> on %{date}",
    "label_support_portal": "Support Portal",
    "label_integrations_tab": "Integrations",
    "title_proposal_analysis": "Proposal Analysis",
    "label_rli_request_for_letters_of_interest": "RLI (Request for Letters of Interest)",
    "label_cb_competitive_bidding": "CB (Competitive Bidding)",
    "label_lb_limited_bidding": "LB (Limited Bidding)",
    "label_ib_international_bidding": "IB (International Bidding)",
    "label_sb_selective_bidding": "SB (Selective Bidding)",
    "label_rb_restricted_bidding": "RB (Restricted Bidding)",
    "label_da_direct_award": "DA (Direct Award)",
    "label_fa_framework_agreement": "FA (Framework Agreement)",
    "label_sp_small_purchases": "SP (Small Purchases)",
    "label_eup_extreme_urgency_procurement": "EUP (Extreme Urgency Procurement)",
    "tooltip_decision_sensitivity_analysis": "<strong>Decision Sensitivity Analysis</strong>: See when changing a single score for a lower ranked proposal could displace the top ranked vendor",
    "label_enter_project_reference_number_below": "Enter the Project Reference Number (%{projectReferenceNumber}) below",
    "phrase_you_are_about_to_delete_project_this_cannot_be_undone": "You are about to delete the project %{projectName}. This action cannot be undone.",
    "error_reference_numbers_do_not_match": "Reference Numbers do not match",
    "label_esignature": "eSignature",
    "notice_report_will_be_emailed": "This report is too large to be downloaded. It will be sent as an email to your email address on file.",
    "phrase_region_explanation": "Specifying Service Regions lets Bonfire know where you deliver services. Bonfire uses this information, in conjunction with Commodity Codes, to match you to Bid Opportunities. Selecting a region will enable you to select the subregions (e.g. Choosing U.S. will enable selection of specific states).",
    "phrase_all_subregions": "All Subregions in selected regions",
    "phrase_subregion_explanation": "If you don't select a subregion for a region, the entire region will be selected (i.e. choosing Canada alone chooses all provinces)",
    "phrase_to_view_or_download_report_click_below": "To view/download the report, click the button below. This link to the report will expire in 1 week.",
    "error_generating_report_please_try_again": "There was an issue generating your report, please try again. If the problem persists, contact Support@GoBonfire.com.",
    "error_generating_report": "Error generating report",
    "title_sheet": "Sheet",
    "phrase_in_order_to_prepare_a_submission_please_log_in": "In order to prepare a submission, please log in.",
    "phrase_in_order_to_prepare_a_submission_please_log_in_and_sign_the_non_disclosure_agreement": "In order to prepare a submission, please log in and sign the non-disclosure agreement.",
    "phrase_in_order_to_view_project_details_please_log_in_and_sign_the_non_disclosure_agreement": "In order to view the Supporting Documentation, Requested Information, and other Project details, please log in and sign the non-disclosure agreement.",
    "phrase_in_order_to_prepare_or_subcontract": "In order to prepare a submission or identify as a Subcontractor, please log in.",
    "phrase_if_you_do_not_have_a_bonfire_account_you_can_create_one": "If you do not have a Bonfire account, you can create an account by clicking the %{label} button at the top of the page.",
    "keyword_register": "Register",
    "label_observer": "Observer",
    "label_hide_file_list": "Hide file list for %{fileSlotName}",
    "label_show_file_list": "Shown file list for %{fileSlotName}",
    "label_invalid_file": "Invalid file",
    "label_valid_file": "Valid file",
    "notice_send_to_adobesign": "Send to Adobe Acrobat Sign",
    "notice_remove_adobesign_link": "Remove Adobe Acrobat Sign Link",
    "notice_document_file_size_subtitle_adobesign": "This document is larger than 10MB. If you would like to send this document for signature, please break it down into smaller documents.",
    "notice_document_type_not_supported_subtitle_adobesign": "The document type you are trying to send is not supported by Adobe Acrobat Sign. Here are all the file types you can send:",
    "notice_send_to_docusign": "Send to DocuSign",
    "notice_remove_docusign_link": "Remove DocuSign Link",
    "notice_document_too_large_title": "This document is too large",
    "notice_document_file_size_subtitle": "This document is larger than 25MB. If you would like to send this document for signature, please break it down into smaller documents.",
    "notice_document_type_not_supported": "This document type is not supported",
    "notice_document_type_not_supported_subtitle": "The document type you are trying to send is not supported by DocuSign. Here are all the file types you can send:",
    "notice_okay": "Okay",
    "notice_document": "Document",
    "notice_image": "Image",
    "notice_presentation": "Presentation",
    "notice_spreadsheet": "Spreadsheet",
    "label_signature_status": "Signature Status",
    "notice_view_in_fragment": "View in",
    "STATUS_DELIVERED": "Delivered",
    "STATUS_SIGNATURE_COMPLETE": "Signature Complete",
    "STATUS_SIGNATURE_DECLINED": "Signature Declined",
    "DOCUSIGN_ENVELOPE_ISSUE_ERROR": "There is an issue with your document in DocuSign. Please navigate to DocuSign to learn more.",
    "STATUS_SENT_TO_PROVIDER": "Sent to",
    "DOCUSIGN_ENVELOPE_DOWNLOAD_ERROR": "There was an error downloading your document. Please navigate to DocuSign to download.",
    "STATUS_UPLOAD_ERROR": "There was an error sending your document for signature. Please re-send.",
    "STATUS_VOIDED": "Voided",
    "label_event_privacy": "Event Privacy",
    "press_next_button": "Press the 'Next' button to see this tab",
    "STATUS_PENDING": "Pending",
    "phrase_important_information": "Important Information",
    "phrase_global_vendor_moved": "Global vendor settings have moved. You can now manage all your org settings by clicking below.",
    "label_manage_my_organization": "Manage my organization",
    "phrase_settings_to_vendor_hub": "Your settings are on Vendor Hub",
    "phrase_service_regions_moved": "To set your service regions, navigate to your Vendor Hub (opens in a new tab)",
    "label_take_me_to_vendor_hub": "Take me to Vendor Hub",
    "tooltip_docusign_document_owner": "This is the user that initially created the DocuSign signature package and whose account the document resides within. If you would like to change the owner you will need to remove the DocuSign link under Actions and re-send the document.",
    "tooltip_adobesign_document_owner": "This is the user that initially created the Adobe Acrobat Sign signature package and whose account the document resides within. If you would like to change the owner you will need to remove the Adobe Acrobat Sign link under Actions and re-send the document.",
    "label_document_owner": "Owner",
    "label_add_project": "Add Project",
    "label_add_project_draft": "Add Project Draft",
    "label_manage_vendor_lists": "Manage Vendor Lists",
    "label_add_contract": "Add Contract",
    "phrase_x_updated": "%{x} updated!",
    "notice_disconnected_esignature_fragment_1": "You have been disconnected from",
    "notice_disconnected_esignature_fragment_2": ". To re-connect, navigate to the Settings > Integrations page.",
    "adobe_acrobat_sign": "Adobe Acrobat Sign",
    "phrase_go_to_x": "Go to %{x}",
    "phrase_your_account_has_not_been_confirmed_v2": "Your account has not been confirmed.",
    "label_basket_total": "Basket Total",
    "label_grand_total": "Grand Total",
    "ADOBESIGN_AGREEMENT_ISSUE_ERROR": "There is an issue with your document in Adobe Acrobat Sign. Please navigate to Adobe Acrobat Sign to learn more.",
    "ADOBESIGN_ENVELOPE_DOWNLOAD_ERROR": "There was an error downloading your document. Please navigate to Adobe Acrobat Sign to download.",
    "ADOBESIGN_AGREEMENT_EMAIL_VIEWED": "Email Viewed",
    "ADOBESIGN_AGREEMENT_EXPIRED": "Expired",
    "ADOBESIGN_AGREEMENT_DOCUMENTS_VIEWED": "Agreement Document Viewed",
    "ADOBESIGN_AGREEMENT_PENDING_CREATION": "Pending Creation",
    "ADOBESIGN_AGREEMENT_CREATION_INCOMPLETE": "Creation Incomplete",
    "notice_remove_esignature_link_subtitle": "Removing the %{eSignatureProvider} link will remove your ability to navigate from Bonfire to %{eSignatureProvider}. If you would like to link this document to %{eSignatureProvider} later you will need to re-send it for signature.",
    "docusign": "DocuSign",
    "adobe_sign": "Adobe Sign",
    "label_vendor_invite_inactive": "Opted out",
    "label_premium_vendor": "Optional: Premium Vendor",
    "label_learn_more": "Learn more",
    "label_upgrade_now": "Upgrade now",
    "label_get_premium": "Get premium edition",
    "label_continue_to_premium_vendors": "Continue to premium vendors",
    "description_premium_vendors": "Get more by upgrading to premium. Upgrade to our Premium edition and expand your reach by tapping into our full network with over 200,000 new bid opportunities each year. Get instant access to thousands of new bid opportunites in the USA and Canada across all levels of government. Easily discover contracts that are perfect for your organization by filtering opportunities by geography, commodity code, and project type. Receive daily alert by email to ensure you'll never miss a bid opportunity. Unlock the public sector - upgrade to premium today.",
    "phrase_suggesting_external_vendors_merge": "You're suggesting these two external vendors merge",
    "phrase_learn_more_vendor_merging_link": "Learn more about vendor merging",
    "label_vendor_org_name": "Vendor Org: %{organizationName}",
    "label_vendor_contact_first_name": "Contact first name: %{firstName}",
    "label_vendor_contact_last_name": "Contact last name: %{lastName}",
    "label_vendor_email_address": "Email address: %{emailAddress}",
    "label_vendor_phone_number": "Phone number: %{phoneNumber}",
    "notice_continue_in_fragment": "Continue in",
    "notice_resend_to_fragment": "Resend to",
    "label_members": "Members",
    "label_show_grand_total": "Show grand total for this column",
    "label_show_sub_total": "Show basket total(s) for this column",
    "keyword_more_info": "More Info",
    "label_update_totals": "Update totals",
    "phrase_column_totals_up_to_date": "Column totals are up to date",
    "phrase_column_totals_not_up_to_date": "Column totals are not up to date",
    "phrase_column_totals_error": "There is an error in the column",
    "error_undefined_value": "Error: undefined value",
    "aria_label_column_outdated_icon": "Outdated column data icon",
    "aria_label_column_up_to_date_icon": "Column up to date icon",
    "aria_label_column_has_error_icon": "Column contains errors icon",
    "label_public_notice_resubscribe": "Re-subscribe to Public Notices",
    "label_bulk_invite_users": "Bulk Invite Users",
    "phrase_we_will_send_email_to_finish_registration": "If %{email} is associated with a Bonfire account, we will send an email with a link to finish the registration process.",
    "title_finish_registration": "Finish Registration",
    "title_public_q_and_a": "Public Q&A",
    "phrase_public_questions_and_answers_description": "Public Questions & Answers for this project.",
    "label_public_questions_and_answers_vendor_names": "Vendor Names Included",
    "phrase_public_questions_and_answers_vendor_names_description": "Include the vendor names for each public question.",
    "label_check_for_missed_steps": "Check for Missed Steps",
    "title_publish_confirmation_modal_draft": "Are you sure you want to Publish?",
    "title_missed_steps_modal": "Missed Steps in your project",
    "title_missed_steps_modal_no_missed_steps": "No Missed Steps",
    "phrase_publish_confirmation_modal_before_publishing": "Before publishing, we automatically check for the most common errors reported to our Support Team. (This check does not validate all potential errors)",
    "phrase_publish_confirmation_modal_here_are_the_results": "Here are the results of that automated check. You can proceed with publishing if you choose to, or use the resources below to help you make changes.",
    "phrase_missed_steps_modal_no_missed_steps": "We automatically check for the most common errors reported to our Support Team (This check does not validate all potential errors). You currently have no missed steps within your project.",
    "phrase_missed_steps_modal_draft_missed_steps_explanation": "Not completing these will not restrict you from publishing your project.",
    "phrase_missed_steps_modal_missed_steps_explanation": "Here are all the missed steps found within your project.",
    "phrase_missed_steps_modal_missed_steps_explanation_2": "As you fix these steps they will disappear from this list.",
    "label_skip_and_publish_project": "Skip and Publish Project",
    "label_review_first_missed_step": "Review first missed step",
    "keyword_dashboard": "Dashboard",
    "phrase_project_message_thread_public_pending": "Public - Pending",
    "phrase_project_message_thread_not_public_pending": "Not Public - Pending",
    "phrase_project_message_thread_review": "Public - In Review",
    "phrase_project_message_thread_answered": "Public - Answered",
    "phrase_project_message_thread_archived": "Not Public - Archived",
    "phrase_no_message": "No Message",
    "label_new_public_q_and_a_messages_for_project": "New Public Q&A messages for %{project}",
    "label_review": "Review",
    "action_learn_more": "Learn More",
    "missed_step_BEST_VALUE_SINGLE_EVALUATION_GROUP": "Your <strong>Best Value</strong> project only has one <strong>Evaluation Group</strong>.",
    "missed_step_INVITE_ONLY_NO_INVITED_VENDORS": "Your <strong>Invite Only</strong> projects has no <strong>Vendor Invitations</strong>.",
    "missed_step_NO_ADVANCED_ALGORITHM_CRITERIA": "Your project has <strong>Advanced Algorithms</strong> enabled, but they are not being utilized for scoring.",
    "missed_step_MCD_CATEGORY_ITEMS_NOT_RELATED_TO_DECISIONS": "There are <strong>Category Items</strong> not linked to <strong>Decisions</strong> in your <strong>MCD</strong> project, which may limit your responses from vendors.",
    "missed_step_MCD_CATEGORY_WITHOUT_ITEMS": "There are <strong>Categories</strong> without linked <strong>Items</strong> in your <strong>MCD</strong> project, which may limit your responses from vendors.",
    "missed_step_MCD_NO_DECISIONS": "There are no <strong>Decisions</strong> created in your <strong>MCD</strong> project, which may limit your responses from vendors.",
    "missed_step_MCD_CATEGORY_CREATED_AFTER_DECISIONS": "You've added another <strong>Category</strong> after creating <strong>Decisions</strong>, and your initial <strong>Decisions</strong> won't include the <strong>Category</strong>.",
    "missed_step_NO_CRITERIA": "Your project does not have <strong>Criteria</strong>, so reviewers will be unable to provide their scores.",
    "missed_step_NO_REQUESTED_INFORMATION_MAPPED_TO_EVALUATION_GROUP": "There are <strong>Evaluation Groups</strong> that have no linked <strong>Requested Information</strong>.",
    "missed_step_NO_REVIEWERS_MAPPED_TO_EVALUATION_GROUP": "There are no <strong>Reviewers</strong> linked to your <strong>Evaluation Groups</strong>, so they may not see the right information to review.",
    "missed_step_NO_UNIQUE_PER_ALTERNATE_TAG": "Vendors will not be able to upload unique information for <strong>Alternate Submissions</strong>.",
    "missed_step_NO_UPLOAD_SLOTS": "There are no file upload slots for vendors to attach files to their <strong>Submissions</strong>.",
    "missed_step_BID_TABLE_NOT_MAPPED_AS_REQUESTED_INFORMATION": "Your <strong>BidTable</strong> is not linked with <strong>Requested Information</strong>, so vendors will be unable to respond.",
    "missed_step_QUESTIONNAIRE_NOT_MAPPED_AS_REQUESTED_INFORMATION": "Your <strong>Questionnaire</strong> is not linked with <strong>Requested Information</strong>, so vendors will be unable to respond.",
    "missed_step_PRICING_SHEET_NOT_MAPPED_AS_REQUESTED_INFORMATION": "Your <strong>Pricing Sheet</strong> is not linked with <strong>Requested Information</strong>, so vendors will be unable to respond.",
    "missed_step_PRICING_SHEET_WITH_EMPTY_ROWS": "Your <strong>Pricing Sheet</strong> has empty rows which may result in <strong>Submission</strong> errors.",
    "phrase_public_qa_disabled": "Public Q&A is currently inactive for this project. New questions cannot be submitted, however the Project Owner may continue to provide responses to previously asked questions.",
    "phrase_reason_for_archving": "Reason for Archiving",
    "phrase_deanonymize_anonymous_proposals_to_include_in_submission_report": "Anonymous proposals must be de-anonymized to be included in the Vendor Submission Report",
    "phrase_no_eligible_proposals_to_include_in_submission_report": "There are no eligible proposals to include in the submission report.",
    "label_vendor_submission_report": "Vendor Submission Report",
    "label_download_x": "Download %{x}",
    "keyword_report": "Report",
    "phrase_vendor_submission_report_description": "Download a report detailing requested information and vendor submission events. The report <strong>will not contain data</strong> for anonymous submissions.",
    "phrase_deactivated_user": "Deactivated User",
    "label_word_project_report": "Project Overview (Word)",
    "label_excel_project_report": "Scoring Overview (Excel)",
    "my_supplier_hub": "My Supplier Hub",
    "lock_status": "Lock Status",
    "label_update_organization_vendor_record": "Update Organization Vendor Record",
    "label_update_global_vendor_record": "Update Global Vendor Record",
    "label_benchmark_button": "Display Benchmarks",
    "action_view_on_portal": "View on Portal",
    "label_uploading_vendor_task_docs": "Upload Vendor Task Docs",
    "keyword_contract_owner": "Contract Owner",
    "label_contract_fieldName": "Contract %{fieldName}",
    "label_current_term_fieldName": "Current Term %{fieldName}",
    "label_total_contract_fieldName": "Total Contract %{fieldName}",
    "label_total_contract_duration": "Total Contract Duration",
    "label_current_term_duration": "Current Term Duration",
    "label_total_terms": "Total Terms",
    "label_download_bid_documents": "Download Bid Documents",
    "label_vendor_bid_document": "Vendor Bid Document",
    "tooltip_contract_owner_field": "Contract owners will have full edit access to these contracts and receive any notifications relating to these contracts. Select and change the Department field first to assign a Contract Owner.",
    "label_bid_document": "Bid Document",
    "label_term_progress": "Term Progress",
    "label_term_number": "Term#"
}
